import React from 'react'
import { hydrate, render } from 'react-dom'
import { LibraryConfigProvider } from '@artnetworldwide/ui-library'
import clientConfig from '~/config/config.client'
import type { Config } from '~/config/config.server'
import type { SyndicatedComponentParamDefinition } from './types'
import { SyndicationContext } from './SyndicationContext'

const isServer =
    typeof document === 'undefined' || process.env.NODE_ENV === 'test'

// TODO
// see if we can get this working without a `require` statement,
// or maybe just use ~/config/isomorphic-config.ts for syndicated components
export const config: Config = isServer
    ? require('../config/config.server').default
    : clientConfig

// Returns an array with [propName, settings].
// We return {} for settings if no settings were provided.
// This function is needed because serverTemplateParams supports two formats:
/*
    export const serverTemplateParams = [
        // Format 1: just the prop name; this will use default settings
        'foo',

        // Format 2: an array with the prop name followed by a settings object
        ['bar', { defaultValForDemoPage: 'Fred' }]
    ] as const
*/
export function getSyndicatedComponentParamSettings(
    propDef: SyndicatedComponentParamDefinition<any>
) {
    return Array.isArray(propDef) ? propDef : [propDef, {}]
}

export function initComponentInBrowser(
    DeliveryPackage: React.FunctionComponent<any>,
    componentId: string
) {
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(
            [
                ...window.document.querySelectorAll(
                    '[data-syndicated-component-id]'
                ),
            ] as HTMLElement[]
        )
            ?.filter(
                ({ dataset: { syndicatedComponentId } }) =>
                    syndicatedComponentId === componentId
            )
            .forEach((container) => {
                const syndicationState = (window as any).__SYNDICATION_STATE__
                const componentState = syndicationState?.[componentId]
                if (componentState) {
                    // Check if already rendered. This is needed when we have both SSR and CSR
                    // components on the same page.
                    if (componentState.wasRendered) {
                        // already rendered
                        return
                    }
                    componentState.wasRendered = true
                }

                const reactElement = (
                    <SyndicationContext.Provider value={true}>
                        <LibraryConfigProvider config={{}}>
                            <DeliveryPackage
                                loaderData={componentState?.loaderData}
                                {...componentState?.realReactProps}
                            />
                        </LibraryConfigProvider>
                    </SyndicationContext.Provider>
                )

                if (container.hasChildNodes()) {
                    hydrate(reactElement, container)
                } else {
                    render(reactElement, container)
                }
            })
    }
}

export const isNonProductionEnv =
    process.env.NODE_ENV !== 'production' ||
    /\.artnet-dev\.com$/.test(config.URL_SCHEME_AND_AUTHORITY) ||
    config.URL_SCHEME_AND_AUTHORITY.startsWith('http://localhost')
