import { useRemixLoader } from '~/syndicated-components/hooks/remix-loader'
import { useRemixAction } from '~/syndicated-components/hooks/remix-action'
import {
    LenderFormActionParams,
    LenderFormLoaderData,
    LenderInquiryResult,
    ROUTE_PATH,
} from '~/components/lender-form/shared'

export function useLenderFormLoader(readyToFetch?: boolean) {
    return useRemixLoader<LenderFormLoaderData>(ROUTE_PATH, { readyToFetch })
}

export function useLenderFormAction() {
    return useRemixAction<LenderInquiryResult, LenderFormActionParams>(
        ROUTE_PATH
    )
}
