/* eslint-disable jsx-a11y/anchor-has-content */
import { Fragment, SyntheticEvent } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    CloseIcon,
    ChevronLeftIcon,
    ExitIcon,
    ProfileIcon,
    useViewportType,
} from '@artnetworldwide/ui-library'
import { buildClassName, lit } from '@artnetworldwide/ui-library/utils'
import { getHeaderData } from '~/data/global-nav/global-header'
import { NavLink } from '../../../NavLink'
import { DialogWrapper } from '~/components/syndication-compatibility/DialogWrapper'

export interface ProfileMenuProps {
    headerData: ReturnType<typeof getHeaderData>
    handleNavLinkClick: (e: SyntheticEvent, link: any) => void
    profileOpen: boolean
    toggleProfileOpen: () => void
    theme: string
}

export function GlobalHeaderProfileMenu(props: ProfileMenuProps) {
    const {
        headerData,
        handleNavLinkClick,
        profileOpen,
        toggleProfileOpen,
        theme,
    } = props
    const viewPortType = useViewportType()

    return (
        <Transition.Root show={profileOpen} as={Fragment}>
            <Dialog
                as={DialogWrapper}
                className={lit`${buildClassName(
                    'GlobalHeaderProfile'
                )} relative z-[1500] transition duration-700 ease-in-out ${theme}`}
                onClose={() => {
                    if (profileOpen) {
                        toggleProfileOpen()
                    }
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-4">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md lg:w-80">
                                <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl dark:bg-black dark:text-white">
                                    <div className="flex justify-between border-b-[1px] border-neutral-200 pb-4 pt-4">
                                        <button
                                            className="flex cursor-pointer"
                                            onClick={toggleProfileOpen}
                                        >
                                            <div className="ml-3 flex h-7 items-center">
                                                <span className="rounded-md bg-white text-neutral-400 dark:bg-black dark:text-white">
                                                    <ChevronLeftIcon
                                                        size={
                                                            viewPortType ===
                                                            'desktop'
                                                                ? 'small'
                                                                : 'medium'
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <h1 className="text-brand-black ml-2 text-base dark:text-white">
                                                Back
                                            </h1>
                                        </button>
                                        <div className="ml-3 mr-4 flex h-7 items-center justify-between">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:bg-black dark:text-white"
                                                onClick={toggleProfileOpen}
                                            >
                                                <span className="sr-only">
                                                    Close panel
                                                </span>
                                                <CloseIcon size="medium" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="relative mt-3 flex-1 px-4 sm:px-6">
                                        <ul className="leading-[3rem]">
                                            <a
                                                className="flex items-center"
                                                href={`/my-account`}
                                            >
                                                <ProfileIcon className="dark:fill-white" />
                                                &nbsp;
                                                <span className="truncate dark:text-white">
                                                    {
                                                        headerData.account
                                                            .displayName
                                                    }
                                                </span>
                                            </a>
                                            {Object.values(
                                                headerData.account.links
                                            ).map((item) => (
                                                <li
                                                    key={`myAccount_${item.id}`}
                                                    className={lit`flex items-center justify-between ${item?.uiSpec?.mobile}`}
                                                >
                                                    {item.id === 'logout' ? (
                                                        <a
                                                            className="flex items-center focus:text-[#737373]"
                                                            href={item.url}
                                                        >
                                                            <ExitIcon
                                                                size="medium"
                                                                className="!dark:stroke-white !stroke-black"
                                                            />
                                                            &nbsp;&nbsp;
                                                            {item.label}
                                                        </a>
                                                    ) : (
                                                        <NavLink
                                                            link={item}
                                                            onClick={(e) =>
                                                                handleNavLinkClick(
                                                                    e,
                                                                    item
                                                                )
                                                            }
                                                            className="focus:text-neutral-500 dark:text-white"
                                                        />
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
