import React from 'react'
import { useViewportType } from '@artnetworldwide/ui-library'
import { useRafState, useMount } from 'react-use'
import throttle from 'lodash/throttle'

// These values should match what we have for the tailwind:
//  classes below: h-[56px] lg:h-[123px]
const DESKTOP_HEIGHT = 123
const MOBILE_HEIGHT = 56

export function useScrollListener() {
    const [data, setData] = useRafState({
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0,
    })

    useMount(() => {
        const handleScroll = throttle(
            () => {
                setData((last) => {
                    return {
                        x: window.scrollX,
                        y: window.scrollY,
                        lastX: last.x,
                        lastY: last.y,
                    }
                })
            },
            750,
            { trailing: true, leading: false }
        )

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    return data
}

export function useScrollDirection() {
    const scroll = useScrollListener()
    const viewPortType = useViewportType()
    const hideMenuThreshold =
        viewPortType === 'desktop' ? DESKTOP_HEIGHT : MOBILE_HEIGHT

    const [currentY, setCurrentY] = React.useState(0)

    const scrollingDown = React.useRef(false)

    React.useEffect(() => {
        scrollingDown.current =
            scroll.y > hideMenuThreshold && scroll.y - scroll.lastY > 0
        setCurrentY(scroll.y)
    }, [hideMenuThreshold, scroll.lastY, scroll.y])

    return { direction: scrollingDown.current ? 'down' : 'up', currentY }
}
