import { ArtnetLogoIcon } from '@artnetworldwide/ui-library'
import { lit } from '@artnetworldwide/ui-library/utils'
import { format } from 'date-fns'

export function PrintHeader({ title }: { title: string }) {
    const currentDate = format(new Date(), 'dd MMMM yyyy')

    return (
        <header
            className={lit`hidden w-full flex-col bg-white dark:bg-black print:flex print:md:h-auto`}
        >
            <div
                className={lit`flex w-full justify-between border-b border-neutral-200 p-4`}
            >
                <ArtnetLogoIcon
                    className="fill-black dark:fill-white"
                    fill="black"
                    size="medium"
                />
                <h1 className="-mt-2 text-3xl">{title}</h1>
            </div>
            <div className="hidden pl-4 print:flex print:h-7 print:flex-initial print:items-center print:justify-between print:text-xs print:text-neutral-400">
                <div>{currentDate}</div>
            </div>
        </header>
    )
}
