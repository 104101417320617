import React from 'react'
import { useLocation } from '@remix-run/react'

type BuildLinkUrlFunc = (linkUrl: string) => string

/**
 * Build a react router link URL, keeping the query string in the current URL
 */
export function useLinkUrlWithQueryString(): BuildLinkUrlFunc {
    const location = useLocation()
    return React.useCallback(
        (linkUrl: string) => linkUrl + location.search,
        [location.search]
    )
}
