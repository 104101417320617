import React from 'react'
import { useCookie } from 'react-use'
import clientConfig from '~/config/config.client'
import type { DeviceSpecificPrefs } from './types'
import { COOKIE_OPTIONS, defaultPrefs } from './defaults'
import type { CookieAttributes } from 'js-cookie'
import { RootContext } from '~/root-context'

/**
 * Use this hook *only* for user preferences that should be stored on in user's browser
 * rather than stored in the database for their account. This is useful in the case of
 * shared accounts used by multiple users--in this case, there are certain settings that
 * should only apply to the current user so they need to be stored in the browser.
 *
 * The current implementation uses a cookie as the storage mechanism.
 * 
 * Basic Usage (see return statement for full list of properties and methods):
 
    const { getPref, updatePref } = useDeviceSpecificPrefs()
    
    // get preference
    const iceCreamPref = getPref('iceCreamFlavor')
    
    // later, in an event handler...
    // update preference:
    updatePref('iceCreamFlavor', 'caramel')
 */
export function useDeviceSpecificPrefs() {
    const COOKIE_NAME = clientConfig.COOKIE_NAMESPACE + '-prefs'
    const rootContext = React.useContext(RootContext)
    const userId = rootContext.currentUserResult?.user?.id

    const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME)
    const allPrefs = React.useMemo(() => {
        if (!userId) {
            return null
        }
        return value ? JSON.parse(value) : {}
    }, [value, userId])
    const currentPrefs = userId ? { ...defaultPrefs, ...allPrefs[userId] } : {}

    // get a single preference value
    function getPref(key: keyof DeviceSpecificPrefs) {
        return currentPrefs[key]
    }

    // update a single preference value
    function updatePref(key: keyof DeviceSpecificPrefs, val: any) {
        updatePrefs({
            [key]: val,
        })
    }

    // update multiple preference values
    function updatePrefs(updatedPrefs: Partial<DeviceSpecificPrefs>) {
        const newPrefs = { ...currentPrefs, ...updatedPrefs }

        // there's no reason to store nulls or empty strings and make the cookie
        // unnecessarily long, so we filter these out
        const nonEmptyNewPrefs = Object.fromEntries(
            Object.entries(newPrefs).filter(
                ([, val]) => val !== null && val !== ''
            )
        )

        const { maxAge, ...updateCookieOpts } = COOKIE_OPTIONS
        if (maxAge) {
            // the js-cookie library used by useCookie() doesn't support the maxAge
            // option, only `expires`
            // disable erroneous no-extra-semi rule
            // eslint-disable-next-line
            ;(updateCookieOpts as CookieAttributes).expires = new Date(
                Date.now() + maxAge * 1000
            )
        }

        if (!userId) {
            console.warn(
                'userId is missing. User must be logged in to use this hook'
            )
            return
        }

        // prefs are keyed by user ID, so that the user can log into and out of multiple accounts
        // if they want, and still have their preferences stored.
        // So we want to keep the preferences for the other user IDs intact while updating.
        const updatedAllPrefs = {
            ...allPrefs,
            [userId]: nonEmptyNewPrefs,
        }
        updateCookie(JSON.stringify(updatedAllPrefs), updateCookieOpts)
    }

    return {
        prefs: currentPrefs,
        getPref,
        updatePref,
        updatePrefs,
        resetPrefs: deleteCookie,
    }
}
