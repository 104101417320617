import { convertToDateObjIfPossible } from '~/services/transformations/other-helpers'
import { CustomDate } from '~/services/graphql/generated'
import { add, format } from 'date-fns'

export function isFutureDate(date: CustomDate) {
    let result = false
    const now = new Date()

    if (!(date instanceof Date)) {
        const saleStartDate = convertToDateObjIfPossible(date as CustomDate)
        if (saleStartDate instanceof Date) {
            result = saleStartDate > now
        } else if (saleStartDate.year) {
            // partial date
            const currentYear = now.getFullYear()
            const currentMonth = now.getMonth() + 1 // months are zero-indexed in JS
            result =
                saleStartDate.year > currentYear ||
                (saleStartDate.year >= currentYear &&
                    saleStartDate.month !== null &&
                    saleStartDate.month > currentMonth)
        }
    }
    return result
}

export function isPastDate(date: CustomDate) {
    return !isFutureDate(date)
}

export function convertToDateFromUnixTime(unixTime: number) {
    return new Date(unixTime * 1000)
}

export function getMonthName(
    monthNum: number,
    monthFormat: 'short' | 'long' = 'long'
) {
    // note: month name from graphql is 1-indexed, whereas months in JS Dates are zero-indexed
    const tmpDate = new Date(1970, monthNum - 1, 1)
    return format(tmpDate, monthFormat === 'short' ? 'LLL' : 'LLLL')
}

export function convertToUtc(time: Date) {
    return add(time, { minutes: new Date().getTimezoneOffset() * -1 })
}
