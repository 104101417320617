// adapted from https://stackoverflow.com/a/57888548/560114
export async function fetchWithTimeout(
    resource: RequestInfo | URL,
    init: RequestInit | undefined,
    timeoutMilliseconds: number = 60000 // default to 60 seconds
) {
    const controller = new AbortController()
    const promise = fetch(resource as any, {
        ...init,
        signal: controller.signal,
    })
    const timeout = setTimeout(() => {
        console.error('Fetch timeout')
        controller.abort()
    }, timeoutMilliseconds)
    return promise.finally(() => clearTimeout(timeout))
}

export async function fetchJson(
    url: string,
    options: RequestInit = {},
    timeoutMilliseconds?: number
) {
    const defaultHeaders: any = { Accept: 'application/json' }
    if (options.body) defaultHeaders['Content-type'] = 'application/json'

    const body =
        typeof options.body === 'object'
            ? JSON.stringify(options.body)
            : options.body

    const fetchOptions = {
        ...options,
        body,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    }

    const response = await fetchWithTimeout(
        url,
        fetchOptions,
        timeoutMilliseconds
    )

    if (response.status !== 200) {
        const serverMessage = await response.text()
        throw Error(
            `Error fetching JSON (HTTP Response Code ${response.status}): ${serverMessage}`
        )
    }

    try {
        return await response.json()
    } catch (e) {
        throw Error(`Error parsing JSON from server for URL ${url}`)
    }
}
