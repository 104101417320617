import React from 'react'
import { GlobalHeaderDesktop } from './views/GlobalHeaderDesktop'
import { GlobalHeaderMobile } from './views/GlobalHeaderMobile'
import { useHydrated } from 'remix-utils'
import { buildClassName, lit } from '@artnetworldwide/ui-library/utils'
import { getHeaderData } from '~/data/global-nav/global-header'
import { RootContext } from '~/root-context'
import { GlobalHeaderSearchSyndicationContainer } from './GlobalHeaderSearchSyndicationContainer'
import { GlobalHeaderSearchRemixContainer } from './GlobalHeaderSearchRemixContainer'
import { useGlobalHeaderSearch } from './use-global-header-search'
import { useServerTemplateParamGetter } from '~/syndicated-components/hooks'
import { useScrollDirection } from '~/components/hooks'
import { PrintHeader } from '../PrintHeader'
import { InlineStyles } from './views/InlineStyles'
import { GLOBAL_HEADER_HEIGHT_DESKTOP_PX } from '~/config/constants/styling'

const ANALYTICS_CLASS_NAME = buildClassName('GlobalHeader')

export interface GlobalHeaderProps {
    siteBaseUrl: string
    syndicatedComponentId?: string
    maxWidthInRems?: number
    enableStickyScroll?: boolean
    loginUrl?: string
    registerUrl?: string
    className?: string
    theme: string
    renderSubheaderDesktop?: () => React.ReactNode
    renderSubheaderMobile?: () => React.ReactNode
    // Specifies which viewports should show subheader.
    // Normaly you should leave this as the default value, so that the subheader
    // renders on all mobile devices. Disable it only if you will be implementing
    // your own subheader below the unified header for either phone or tablet.
    subheaderMobileViewports?: Array<'phone' | 'tablet'>
}

function useJustNavigatedToAnchor() {
    const justNavigated = React.useRef(false)

    React.useEffect(() => {
        function handleHashChange() {
            justNavigated.current = true
            setTimeout(() => {
                justNavigated.current = false
            }, 2000)
        }

        window.addEventListener('hashchange', handleHashChange)
        return () => {
            window.removeEventListener('hashchange', handleHashChange)
        }
    }, [])

    return justNavigated
}

export function GlobalHeader(props: GlobalHeaderProps) {
    const {
        siteBaseUrl,
        syndicatedComponentId,
        className,
        enableStickyScroll = true,
        theme,
        renderSubheaderDesktop,
        renderSubheaderMobile,
        subheaderMobileViewports = ['phone', 'tablet'],
    } = props

    const rootContext = React.useContext(RootContext)
    const headerData = React.useMemo(
        () =>
            getHeaderData(
                siteBaseUrl,
                rootContext.authUrls,
                rootContext.currentUserResult?.isLoggedIn,
                rootContext.currentUserResult?.user?.displayName
            ),
        [rootContext, siteBaseUrl]
    )

    const [stickyHeaderVisibility, setStickyHeaderVisibility] =
        React.useState('')

    const { isSearchOpen, toggleSearchOpen } = useGlobalHeaderSearch()
    // menuVisible: used by the menu to decide whether to render a drop shadow
    const menuVisible = React.useRef(false)

    const [showLenderForm, setShowLenderForm] = React.useState(false)
    const [currentY, setCurrentY] = React.useState(0)

    const { direction, currentY: scrollY } = useScrollDirection()

    const justNavigatedToAnchor = useJustNavigatedToAnchor()

    React.useEffect(() => {
        const scrollingDown = direction === 'down'
        const scrollingUp = direction === 'up'
        setCurrentY(scrollY)
        if (scrollY === 0) {
            window.requestAnimationFrame(() => {
                setStickyHeaderVisibility('translate-y-0')
            })
            menuVisible.current = true
        } else if (
            rootContext.shouldKeepStickyHeaderHidden ||
            scrollingDown ||
            (scrollingUp && justNavigatedToAnchor.current)
        ) {
            setStickyHeaderVisibility('-translate-y-full')
            menuVisible.current = false
        } else if (
            rootContext.shouldKeepStickyHeaderHidden ||
            rootContext.selectionModeOn
        ) {
            // leave the header alone
            return
        } else {
            // scrolling up ⬆️
            window.requestAnimationFrame(() => {
                setStickyHeaderVisibility('translate-y-0')
            })
            menuVisible.current = true
        }

        rootContext.setGlobalHeaderVisible(menuVisible.current)
    }, [direction, justNavigatedToAnchor, rootContext, scrollY])

    const stickyScrollClassList =
        'fixed top-0 left-0 right-0 transition ease-in-out z-[1500]'

    const currentUrl = useHydrated() ? window.location.href : undefined

    const getServerTemplateParam = useServerTemplateParamGetter(
        syndicatedComponentId ?? '',
        props
    )

    const headerProps = {
        isVisible: menuVisible.current,
        currentUrl,
        headerData,
        onToggleSearchOpen: toggleSearchOpen,
        currentY,
        showLenderForm,
        setShowLenderForm,
        theme,
        getServerTemplateParam,
    }

    const maxWidthInRemsVal = getServerTemplateParam('maxWidthInRems')

    const desktopHeight = renderSubheaderDesktop
        ? 'lg:h-[175px]'
        : 'lg:h-[123px]'
    const phoneHeight =
        renderSubheaderMobile && subheaderMobileViewports.includes('phone')
            ? 'h-[106px]'
            : 'h-[56px]'
    const tabletHeight =
        renderSubheaderMobile && subheaderMobileViewports.includes('tablet')
            ? 'md:h-[106px]'
            : ''

    const mobileSubheaderWrapperClass = subheaderMobileViewports.includes(
        'tablet'
    )
        ? `${
              subheaderMobileViewports.includes('phone')
                  ? ''
                  : 'hidden md:block'
          } lg:hidden`
        : 'md:hidden'

    return (
        <>
            {syndicatedComponentId && <InlineStyles />}
            <PrintHeader title="Price Database" />
            <header
                className={lit`${phoneHeight} ${tabletHeight} ${desktopHeight} print:hidden`}
            >
                <div
                    className={lit`hidden ${ANALYTICS_CLASS_NAME} bg-white duration-700 lg:block dark:bg-black ${className} border-b-[1px] border-neutral-200
        ${enableStickyScroll && stickyScrollClassList}
        ${enableStickyScroll && stickyHeaderVisibility}`}
                >
                    <div>
                        <GlobalHeaderDesktop {...headerProps} />
                    </div>
                    {renderSubheaderDesktop && (
                        <div className="border-b-[1px] border-t-[1px] border-neutral-200">
                            <div
                                className="mx-auto"
                                style={{ maxWidth: `${maxWidthInRemsVal}rem` }}
                            >
                                {renderSubheaderDesktop()}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={lit`${ANALYTICS_CLASS_NAME} w-full bg-white duration-500 lg:hidden dark:bg-black ${className}
                ${enableStickyScroll && stickyScrollClassList}
                ${enableStickyScroll && stickyHeaderVisibility}`}
                >
                    <GlobalHeaderMobile {...headerProps} />
                    {renderSubheaderMobile && (
                        <div className={mobileSubheaderWrapperClass}>
                            {renderSubheaderMobile()}
                        </div>
                    )}
                </div>
                {syndicatedComponentId ? (
                    <GlobalHeaderSearchSyndicationContainer
                        siteBaseUrl={siteBaseUrl}
                        onToggleSearchOpen={toggleSearchOpen}
                        isSearchOpen={isSearchOpen}
                        desktopHeight={GLOBAL_HEADER_HEIGHT_DESKTOP_PX}
                        theme={theme}
                        getServerTemplateParam={getServerTemplateParam}
                    />
                ) : (
                    <GlobalHeaderSearchRemixContainer
                        siteBaseUrl={siteBaseUrl}
                        onToggleSearchOpen={toggleSearchOpen}
                        isSearchOpen={isSearchOpen}
                        desktopHeight={GLOBAL_HEADER_HEIGHT_DESKTOP_PX}
                        theme={theme}
                        getServerTemplateParam={getServerTemplateParam}
                    />
                )}
            </header>
        </>
    )
}
