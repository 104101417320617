export function getAppBaseUrl(req: Request, trailingSlash = true) {
    const forwardedProtocol = req.headers.get('x-forwarded-proto')
    const protocol = forwardedProtocol
        ? forwardedProtocol + ':'
        : new URL(req.url).protocol

    return `${protocol}//${req.headers.get('host')}${trailingSlash ? '/' : ''}`
}

export function getRootDomain(url: URL) {
    const hostnameParts = url.hostname.split('.')
    return hostnameParts.length > 1
        ? hostnameParts.splice(1).join('.')
        : hostnameParts[0]
}

export function getPathnameWithoutTrailingSlash(url: string | URL) {
    if (typeof url === 'string') {
        url = new URL(url)
    }
    return stripTrailingSlash(url.pathname)
}

export function stripTrailingSlash(url: string) {
    return url.endsWith('/') ? url.substring(0, url.length - 1) : url
}

export function ensureTrailingSlash(str: string) {
    return str.endsWith('/') ? str : str + '/'
}

/**
 * Encode a GET-style param value to use in a URL.
 * This is needed for the search page on news, which expects spaces to be encoded as '+'
 * rather than '%20', but it's not a true query string in `?key=value` format so we can't
 * just use URLSearchParams directly.
 */
export function encodeUriSearchParam(paramValue: string) {
    const tmpParams = new URLSearchParams({ s: paramValue })
    return tmpParams.toString().substring(2 /* length of 's=' */)
}
