import type { NavLinkFragment, NavLinkUiSpec } from '~/types/layout'
import { NavLinkType } from '~/components/headers/global/types'
import { ensureTrailingSlash } from './urls'

// use this function to ensure that links marked as `isExternal` open in a new browser tab
export function getLinkTargetProps(link: NavLinkFragment) {
    return (link.uiSpec as NavLinkUiSpec)?.isExternal
        ? { target: '_blank', rel: 'noreferrer' }
        : {}
}

// used for redirect to legacy PDB from Beta
export function handleReturnToClassic() {
    window.close()
    setTimeout(() => {
        window.location.href = '/my-account/'
    }, 500)
}

export function getParentSectionId(
    navLinks: Record<string, NavLinkType>,
    currentUrl: URL
): string | null {
    const currentUrlWithoutQueryString = ensureTrailingSlash(
        `${currentUrl.protocol}//${currentUrl.host}${currentUrl.pathname}`
    )

    // special case
    if (currentUrlWithoutQueryString.startsWith('/gallery-network/')) {
        return 'galleries'
    }

    for (const [parentId, parentLink] of Object.entries(navLinks)) {
        // check if any of the parent links match
        if (
            parentLink.url &&
            currentUrlWithoutQueryString.startsWith(parentLink.url)
        ) {
            return parentId
        }

        // If we're on news.artnet.com, parentId should be 'news'.
        // The regex also accounts for QA environments, e.g. qa2-news.artnet-dev.com
        if (new RegExp('^(.+-)?' + parentId).test(currentUrl.hostname)) {
            return parentId
        }
    }

    // We need to check for child links in a second loop in case we find
    // a child link match before finding a match for a parent link,
    // e.g. /price-database/ should highlight the Price Database parent link,
    // not Auctions (Auctions > Price Database also links to /price-database/)
    for (const [parentId, parentLink] of Object.entries(navLinks)) {
        const childLinks = parentLink.links

        if (!childLinks || !Object.keys(childLinks).length) {
            continue
        }
        // check if any of the child links match
        for (const childLink of Object.values(childLinks)) {
            if (
                childLink.url &&
                currentUrlWithoutQueryString.startsWith(childLink.url)
            ) {
                return parentId
            }
        }
    }

    return null
}
