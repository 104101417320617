import React from 'react'
import { Form, FormProps, useSubmit } from '@remix-run/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    useForm,
    FormProvider,
    ValidationMode,
    DefaultValues,
} from 'react-hook-form'
import type { z } from 'zod'

type FormSchema<T extends z.ZodTypeAny = z.SomeZodObject | z.ZodEffects<any>> =
    | z.ZodEffects<T>
    | z.SomeZodObject

export interface ReactHookFormWrapperProps<Schema extends FormSchema> {
    className?: string
    action?: string
    method?: FormProps['method']
    schema: Schema
    mode?: keyof ValidationMode
    // TODO more specific type for values and defaultValues based on schema
    values?: Record<string, any>
    defaultValues?: DefaultValues<Record<string, any>>
    children: (args: { submit: () => void }) => NonNullable<React.ReactNode>
}

export function ReactHookFormWrapper<Schema extends FormSchema>({
    schema,
    mode,
    defaultValues,
    values,
    className,
    action,
    method = 'post',
    children: childrenFn,
}: ReactHookFormWrapperProps<Schema>) {
    const resolver = zodResolver(schema)
    const form = useForm({ resolver, mode, defaultValues, values })
    const submit = useSubmit()
    const formRef = React.useRef<HTMLFormElement>(null)

    function handleSubmit(e: React.FormEvent) {
        form.handleSubmit(() => {
            submit(e.target as HTMLFormElement, {
                method,
            })
        })(e)
    }

    function doSubmit() {
        // doing the submit the same way as remix-forms (see https://github.com/seasonedcc/remix-forms/blob/4f8d9245e5a27c415b5b20cc8c1f2f7cb0132295/packages/remix-forms/src/createForm.tsx#L321-L325)
        formRef.current?.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
        )
    }

    return (
        <FormProvider {...form}>
            <Form
                ref={formRef}
                action={action}
                method={method}
                onSubmit={handleSubmit}
                className={className}
            >
                {childrenFn({ submit: doSubmit })}
            </Form>
        </FormProvider>
    )
}
