/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import {
    BurgerIcon,
    ChevronRightIcon,
    Modal,
} from '@artnetworldwide/ui-library'
import { NavLinkType } from '~/components/headers/global/types'
import { buildClassName, lit } from '@artnetworldwide/ui-library/utils'
import { NavLink } from '~/components/NavLink'
import { NavbarMobileSubmenu } from './NavbarMobileSubmenu'
import clientConfig from '~/config/config.client'

export interface NavbarMobileProps {
    links: Record<string, NavLinkType>
    menuTitle: string
    theme?: string
    analyticsClassName?: string
}

export function NavbarMobile({
    menuTitle,
    links,
    theme = '',
    analyticsClassName = '',
}: NavbarMobileProps) {
    const [open, setOpen] = React.useState(false)

    const [submenuStatus, setSubmenuStatus] = React.useState<
        Record<string, boolean>
    >({})

    function toggleSubmenuStatus(menu: string) {
        setSubmenuStatus({
            ...submenuStatus,
            [menu]: !submenuStatus[menu],
        })
    }

    function resetSubmenuStatus() {
        setSubmenuStatus({})
    }

    const navbarMobileSubmenuProps = {
        links,
        menuTitle,
        resetSubmenuStatus,
        setOpen,
    }

    const prefixedAnalyticsClassName = buildClassName(analyticsClassName)
    const openButtonAnalyticsClassName =
        prefixedAnalyticsClassName + 'OpenButton'

    return (
        <>
            <div className="mt-2 flex h-12 w-full items-center justify-between border-b-[1px] border-neutral-200 pl-5">
                <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className={`${openButtonAnalyticsClassName} flex items-center text-sm leading-6 text-neutral-500 dark:text-white`}
                >
                    {menuTitle} &nbsp;
                    <BurgerIcon size="small" />
                </button>
            </div>

            <Modal
                show={open}
                onClose={() => {
                    const noSubMenusOpen = !Object.values(submenuStatus).length
                    if (noSubMenusOpen) {
                        setOpen(false)
                    }
                }}
                wrapperClassName={
                    clientConfig.SYNDICATED_COMPONENT_CONTAINER_CLASS
                }
                innerWrapperClassName={theme}
                desktopModeBreakpoint="lg"
                className={lit`w-screen px-0 dark:bg-black`}
            >
                <Modal.Header className="!py-0" showCloseButton={false} />
                <Modal.Body
                    className={`${prefixedAnalyticsClassName} pb-0 dark:bg-black`}
                >
                    <div className="flex flex-col overflow-y-auto bg-white shadow-xl dark:bg-black dark:text-white">
                        <nav className="relative mt-2 flex-1 px-4 sm:px-6">
                            <ul className="leading-[3rem]">
                                {Object.values(links).map((item) => (
                                    <li
                                        key={item.id}
                                        className={lit`flex cursor-pointer items-center justify-between
                                                            ${item?.uiSpec?.mobile}`}
                                        onClick={() => {
                                            item?.links &&
                                                toggleSubmenuStatus(item.id)
                                        }}
                                    >
                                        {item?.links &&
                                        item.links.length > 0 ? (
                                            <button
                                                type="button"
                                                className="flex w-full items-center justify-between active:text-neutral-500"
                                                onClick={() => setOpen(false)}
                                            >
                                                {item.label}
                                                {item.links.length > 0 && (
                                                    <ChevronRightIcon />
                                                )}
                                            </button>
                                        ) : item?.url ? (
                                            <NavLink
                                                link={item}
                                                className="active:text-neutral-500 dark:text-white"
                                                onClick={(e) => {
                                                    if (
                                                        item?.uiSpec
                                                            ?.preventDefault
                                                    ) {
                                                        e.preventDefault()
                                                    }
                                                    if (item.onClick) {
                                                        item?.onClick()
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    if (
                                                        item?.uiSpec
                                                            ?.preventDefault
                                                    ) {
                                                        e.preventDefault()
                                                    }
                                                    if (item.onClick) {
                                                        item?.onClick()
                                                    }
                                                }}
                                            >
                                                {item.label}
                                            </button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </Modal.Body>
            </Modal>

            <div>
                {Object.values(links).map(
                    (item) =>
                        item.links &&
                        item.links.length > 0 && (
                            <NavbarMobileSubmenu
                                {...navbarMobileSubmenuProps}
                                menu={item.id}
                                isOpen={submenuStatus[item.id] ?? false}
                                key={item.id}
                                theme={theme}
                            />
                        )
                )}
            </div>
        </>
    )
}
