import { useRemixLoader } from '~/syndicated-components/hooks/remix-loader'
import type { SearchHint, SearchType } from '~/services/graphql/generated'

export interface GlobalSearchLoaderData {
    results: SearchHint[]
}

const ROUTE_PATH = '/api/frontend/search'

export function useGlobalSearchLoader(
    searchTerm?: string,
    searchType?: SearchType,
    readyToFetch?: boolean
) {
    return useRemixLoader<GlobalSearchLoaderData>(
        searchTerm
            ? ROUTE_PATH + `?query=${searchTerm}&type=${searchType}`
            : ROUTE_PATH,
        { readyToFetch }
    )
}
