import React from 'react'
import { Modal } from '@artnetworldwide/ui-library'
import { buildClassName } from '@artnetworldwide/ui-library/utils'
import { LenderFormForm } from './LenderFormForm'
import clientConfig from '~/config/config.client'
import { HandleLenderInquiryFunction, LenderInquiryResult } from './shared'

interface LenderFormModalProps {
    lenderInquiryResult?: LenderInquiryResult
    show: boolean
    onClose: () => void
    onLenderInquiry: HandleLenderInquiryFunction
}

export function LenderFormModal({
    lenderInquiryResult,
    show,
    onClose,
    onLenderInquiry,
}: LenderFormModalProps) {
    return (
        <Modal
            className={`${buildClassName('LenderFormModal')} !w-[26rem]`}
            wrapperClassName={
                clientConfig.SYNDICATED_COMPONENT_CONTAINER_CLASS +
                ' lender-form-container'
            }
            fullHeightOnPhone={true}
            onClose={() => onClose()}
            show={show}
        >
            <Modal.Header
                title="Finance Inquiry"
                className="bg-white uppercase"
            ></Modal.Header>
            <Modal.Body>
                {!lenderInquiryResult?.ok ? (
                    <LenderFormForm
                        onFormSubmit={onLenderInquiry}
                        errors={lenderInquiryResult?.errors ?? []}
                    />
                ) : (
                    <div className="pt-4 text-left">
                        <p className="pb-4 font-bold">
                            Thank you for your inquiry.
                        </p>
                        <p className="pb-2 text-xs">
                            {lenderInquiryResult?.message}
                        </p>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}
