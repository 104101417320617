/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment, SyntheticEvent } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    BurgerIcon,
    ChevronRightIcon,
    CloseIcon,
    SearchIcon,
    ChevronLeftIcon,
    ArtnetLogoIcon,
    ProfileIcon,
    useToggle,
} from '@artnetworldwide/ui-library'
import { NavLink } from '../../../NavLink'
import { DialogWrapper } from '~/components/syndication-compatibility/DialogWrapper'
import { buildClassName, lit } from '@artnetworldwide/ui-library/utils'
import { getHeaderData } from '../../../../data/global-nav/global-header'
import { LenderFormSyndicationContainer as LenderForm } from '~/components/lender-form/LenderFormSyndicationContainer'
import { GlobalHeaderProfileMenu } from './GlobalHeaderProfile'
import { ClientOnly } from 'remix-utils'

interface GlobalHeaderMobileProps {
    headerData: ReturnType<typeof getHeaderData>
    isVisible: boolean
    onToggleSearchOpen: () => void
    currentY?: number
    theme: string
}

export function GlobalHeaderMobile(props: GlobalHeaderMobileProps) {
    const {
        isVisible = false,
        headerData,
        onToggleSearchOpen,
        currentY = 0,
        theme,
    } = props
    const [open, setOpen] = React.useState(false)
    const [profileOpen, toggleProfileOpen] = useToggle(false)

    const [submenuStatus, setSubmenuStatus] = React.useState<
        Record<string, boolean>
    >({})

    const [showLenderForm, toggleShowLenderForm] = useToggle(false)

    const parentLinks = headerData.nav.links

    function toggleSubmenuStatus(menu: string) {
        setSubmenuStatus({
            ...submenuStatus,
            [menu]: !submenuStatus[menu],
        })
    }

    function handleProfileIconClick() {
        if (headerData.account.isLoggedIn) {
            toggleProfileOpen()
        } else if (headerData.logIn.url) {
            window.location.href = headerData.logIn.url
        }
    }

    const handleLenderFormClose = () => {
        toggleShowLenderForm()
    }

    const handleNavLinkClick = (e: SyntheticEvent, link: any) => {
        if (link.id === 'financing') {
            // first, close the nav menu dialogs
            setOpen(false)
            setSubmenuStatus({})

            toggleShowLenderForm()
            e.preventDefault()
            e.stopPropagation()
        }
    }

    const profileMenuProps = {
        headerData,
        handleNavLinkClick,
        profileOpen,
        toggleProfileOpen,
        theme,
    }

    function renderSubmenu(menu: string) {
        const nestedLinks = headerData.nav.links[menu].links ?? {}
        const isOpen = submenuStatus[menu] ?? false
        return (
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog
                    static
                    open={isOpen}
                    as={DialogWrapper}
                    // note: z-index of 1500 needed to ensure high enough z-index on legacy auctions site
                    className={lit`${theme} relative z-[1500]`}
                    onClose={() => {
                        setSubmenuStatus({})
                        setOpen(false)
                    }}
                >
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden dark:bg-black">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="h-screen-svh pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-auto bg-white pt-4 shadow-xl dark:bg-black dark:text-white">
                                        <div className="flex justify-between">
                                            <div className="flex">
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-neutral-400 dark:bg-black dark:text-white"
                                                        onClick={() => {
                                                            toggleSubmenuStatus(
                                                                menu
                                                            )
                                                            setOpen(true)
                                                        }}
                                                    >
                                                        <ChevronLeftIcon />
                                                    </button>
                                                </div>
                                                <h1 className="ml-4 text-2xl">
                                                    {parentLinks[menu].label}
                                                </h1>
                                            </div>
                                            <div className="ml-3 mr-4 flex h-7 items-center justify-between">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 dark:bg-black dark:text-white"
                                                    onClick={() => {
                                                        toggleSubmenuStatus(
                                                            menu
                                                        )
                                                        setOpen(false)
                                                    }}
                                                >
                                                    <span className="sr-only">
                                                        Close panel
                                                    </span>
                                                    <CloseIcon size="medium" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <ul className="leading-[2rem]">
                                                {Object.values(nestedLinks).map(
                                                    (item) => (
                                                        <li
                                                            key={item.id}
                                                            className="flex items-center justify-between"
                                                        >
                                                            {
                                                                <NavLink
                                                                    link={item}
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleNavLinkClick(
                                                                            e,
                                                                            item
                                                                        )
                                                                    }
                                                                    className="active:text-neutral-500 dark:text-white"
                                                                />
                                                            }
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

    return (
        <>
            <div
                className={lit`mt-2 flex h-12 w-full items-center justify-between border-b-[1px] border-neutral-200 px-5
            ${
                isVisible &&
                currentY > 0 &&
                'before:absolute before:left-0 before:bottom-0 before:h-1 before:w-screen before:shadow-[0px_2px_3px_rgba(153,153,153,0.4)]'
            }
            `}
            >
                <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className={`${buildClassName(
                        'GlobalHeaderShowMobileMenuButton'
                    )} w-[3.5rem] dark:text-white`}
                >
                    <BurgerIcon />
                </button>
                <a
                    href="https://www.artnet.com"
                    className={buildClassName('ArtnetLogo')}
                >
                    <ArtnetLogoIcon
                        className="flex dark:fill-white"
                        size="small"
                        fill="black"
                    />
                </a>
                <div className="flex w-[3.5rem] justify-end">
                    <div
                        className={`${buildClassName(
                            'GlobalHeaderSearchButton'
                        )} mr-2 flex justify-around gap-6`}
                        onClick={onToggleSearchOpen}
                    >
                        <SearchIcon className="dark:stroke-white" />
                    </div>
                    <ClientOnly>
                        {() =>
                            headerData.account.isLoggedIn !== undefined && (
                                <button
                                    className={`${buildClassName(
                                        'GlobalHeaderProfileButton'
                                    )}`}
                                    type="button"
                                    onClick={handleProfileIconClick}
                                >
                                    <ProfileIcon className="dark:fill-white" />
                                </button>
                            )
                        }
                    </ClientOnly>
                </div>
            </div>
            <GlobalHeaderProfileMenu {...profileMenuProps} />
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as={DialogWrapper}
                    // note: z-index of 1500 needed to ensure high enough z-index on legacy auctions site
                    className={lit`${buildClassName(
                        'GlobalHeaderMobileMenu'
                    )} relative z-[1500]`}
                    onClose={() => {
                        setOpen(false)
                    }}
                >
                    {/* Background Overlay */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className={`fixed inset-0 overflow-hidden ${theme}`}>
                        <div className="absolute inset-0 overflow-hidden">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="h-screen-svh pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl dark:bg-black dark:text-white">
                                        <div className="px-4 pt-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6" />
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white hover:text-neutral-500 dark:bg-black"
                                                        onClick={() =>
                                                            setOpen(!open)
                                                        }
                                                    >
                                                        <span className="sr-only">
                                                            Close panel
                                                        </span>
                                                        <CloseIcon size="medium" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <nav className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <ul className="leading-[3rem]">
                                                {Object.values(parentLinks).map(
                                                    (item) => (
                                                        <li
                                                            key={item.id}
                                                            className={lit`flex cursor-pointer items-center justify-between
                                                            ${item?.uiSpec?.mobile}`}
                                                            onClick={() => {
                                                                item?.links &&
                                                                    toggleSubmenuStatus(
                                                                        item.id
                                                                    )
                                                            }}
                                                        >
                                                            {item.id ===
                                                            'search' ? null : item?.links ? (
                                                                <button
                                                                    type="button"
                                                                    className="flex w-full items-center justify-between active:text-neutral-500"
                                                                >
                                                                    {item.label}
                                                                    <ChevronRightIcon />
                                                                </button>
                                                            ) : (
                                                                <NavLink
                                                                    link={item}
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleNavLinkClick(
                                                                            e,
                                                                            item
                                                                        )
                                                                    }
                                                                    className="active:text-neutral-500 dark:text-white"
                                                                />
                                                            )}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <LenderForm show={showLenderForm} onClose={handleLenderFormClose} />

            <div className={buildClassName('GlobalHeaderMobile')}>
                {Object.values(parentLinks).map(
                    (item) =>
                        item.links && (
                            <React.Fragment key={item.id}>
                                {renderSubmenu(item.id)}
                            </React.Fragment>
                        )
                )}
            </div>
        </>
    )
}
