const contactMessage = `
    <p>For more information, please send us an email at<br>
    <a href="mailto:support@artnet.com">support@artnet.com</a> or call us<br>
    at +1-800-427-8638.
    </p>
`

export const getSystemErrorMessage =
    () => `<p>Internal system error (${new Date()}). <p>Please try again shortly. We apologize for any inconvenience.</p>
${contactMessage}`
