import type { DeviceSpecificPrefs } from './types'

// Note: the useCookie() hook we're using on the frontend
export const COOKIE_OPTIONS = {
    path: '/',
    maxAge: 60 * 60 * 24 * 30, // 30 days
}

export const defaultPrefs: Partial<DeviceSpecificPrefs> = {
    pdbShowPaginationWarning: true,
}
