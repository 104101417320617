import { LoadingIcon } from '@artnetworldwide/ui-library'

interface LoaderProps {
    loading: boolean
}

export function Loader({ loading }: LoaderProps) {
    if (loading) {
        return (
            <div
                className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform "
                data-testid="loader"
            >
                <LoadingIcon />
            </div>
        )
    } else {
        return null
    }
}
