// the useHydrated() hook doesn't have any dependency on remix, so it's safe to use here
import { useHydrated } from 'remix-utils'
import { getSyndicatedComponentParamSettings } from '../utils'
import { OutsideParamDefinitionArray, ScriptOnlyParamOptions } from '../types'

const isServer = typeof document === 'undefined'

// Script-only params aren't real React props but rather values that are set by legacy apps
// that we can read on the client-side inside our components as needed.
export function useScriptOnlyParams(
    syndicatedComponentId: string
): [boolean, Record<string, any>] {
    // By checking the `loading` status, we can avoid react hydration errors due to populating
    // script-only params in JSX code too soon. We only want to render script-only params on the second
    // render on the client at the earliest so that the server and initial client-rendered HTML matches.
    if (!useHydrated()) {
        const loading = true
        return [loading, {}]
    }

    // outsideParamValues are the combination of serverTemplateParams and scriptOnlyParams
    const { outsideParamValues, metadata } =
        (window as any).__SYNDICATION_STATE__[syndicatedComponentId] ?? {}

    const scriptOnlyParamValues = Object.fromEntries(
        (
            (metadata.scriptOnlyParams ??
                []) as OutsideParamDefinitionArray<ScriptOnlyParamOptions>
        ).map((propDef) => {
            const [propName, propSettings] =
                getSyndicatedComponentParamSettings(propDef)
            // was the prop value provided by the backend?
            if (
                !isServer &&
                process.env.NODE_ENV === 'development' &&
                !outsideParamValues.hasOwnProperty(propName)
            ) {
                // log an error unless the param is optional
                if (!propSettings.optional) {
                    console.error(
                        `Missing value for required script-only syndicated prop '${propName}'`
                    )
                }
            }
            return [propName, outsideParamValues[propName]]
        })
    )

    const loading = false
    return [loading, scriptOnlyParamValues]
}
