import React from 'react'
import type { AuthUrls } from './utils/remix-only/auth'
import type { CurrentUserBasicInfoQueryResponse } from '~/services/graphql/generated'

export type RootContextType = {
    currentUserResult?: CurrentUserBasicInfoQueryResponse['getCurrentUser']
    authUrls: AuthUrls
    globalHeaderVisible?: boolean
    setGlobalHeaderVisible: any
    shouldKeepStickyHeaderHidden: boolean
    setshouldKeepStickyHeaderHidden: any //upwardscrollinitiated or upwardscrollhappening?
    selectionModeOn: boolean //disableestickyheader
    setSelectionModeOn: any
}

export const RootContext = React.createContext<RootContextType>(
    {} as RootContextType
)
