import featureFlags from '~/config/feature-flags'
import set from 'lodash.set'

// TODO
// We need to to make the feature flag remain enabled (at least on the current browser tab)
// on both the client and server...possibly via a cookie.
/**
 * Allows feature flags to be set in the URL, which updates the values in
 * app/config/feature-flags.ts, overriding the default values there.
 *
 * To set nested keys ~/config/feature-flags, use dot notation, e.g. `pdb.lists`
 * to enable the `pdb: { lists }` feature flag
 * https://www.artnet-dev.com?featureFlags=pdb.lists
 */
export function handleFeatureFlagsFromUrl(urlString: string) {
    const url = new URL(urlString)
    const featureFlagsParam = url.searchParams.get('featureFlags')
    if (!featureFlagsParam) {
        return
    }

    for (const flagPath of featureFlagsParam.split(',')) {
        set(featureFlags, flagPath.trim(), true)
    }
}
