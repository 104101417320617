/* eslint-disable jsx-a11y/anchor-has-content */
import { ChevronDownIcon } from '@artnetworldwide/ui-library'
import { lit } from '@artnetworldwide/ui-library/utils'
import { Popover, Transition } from '@headlessui/react'
import React, { SyntheticEvent } from 'react'
import { NavLink } from '~/components/NavLink'
import { NavLinkType } from '~/components/headers/global/types'
import { getParentSectionId } from '~/utils'
import { useHydrated } from 'remix-utils'

export type IconSizeType = 'small' | 'medium' | 'large'

interface RenderSearchProps {
    item: NavLinkType
    setCurrOpenMenu: (menu: string) => void
    currOpenMenu: string
    handleMouseAction: (menu: string, action: string) => void
}

export interface NavbarDesktopProps {
    links: Record<string, NavLinkType>
    handleNavLinkClick?: (e: SyntheticEvent, link: any) => void
    shouldIndicateActiveSection?: boolean
    maxWidthInRems?: number
    filterBy?: (item: any) => any
    wrapperDivClassList?: string
    linkClassList?: string
    nestedlinkClassList?: string
    iconSize?: IconSizeType
    renderSearch?: ({
        item,
        setCurrOpenMenu,
        currOpenMenu,
        handleMouseAction,
    }: RenderSearchProps) => React.ReactNode
}

export function NavbarDesktop({
    links,
    handleNavLinkClick,
    shouldIndicateActiveSection,
    filterBy = (item) => item,
    wrapperDivClassList,
    linkClassList,
    nestedlinkClassList,
    iconSize = 'medium',
    renderSearch,
}: NavbarDesktopProps) {
    const nestedLinks = (menuId: string) => links[menuId]?.links ?? []
    const [currOpenMenu, setCurrOpenMenu] = React.useState('')

    function toggleMenu(menu: string) {
        const menuHasNestedLinks = links[menu].links
        if (!menuHasNestedLinks) {
            return
        }

        if (currOpenMenu === menu) {
            setCurrOpenMenu('')
        } else {
            setCurrOpenMenu(menu)
        }
    }

    function handleMouseAction(menu: string, action: string) {
        const leavingCurrentlyOpenMenu =
            currOpenMenu === menu && action === 'onMouseLeave'
        const mousingOverDifferentMenu =
            currOpenMenu !== menu && action === 'onPointerEnter'

        if (leavingCurrentlyOpenMenu || mousingOverDifferentMenu) {
            toggleMenu(menu)
        }
    }

    const closeMenu = () => setCurrOpenMenu('')

    function handlePointerEnter(item: NavLinkType) {
        setCurrOpenMenu(item.id)

        if (currOpenMenu !== item.id) {
            // this is needed to prevent an empty submenu from
            // opening on a menu w/o submenu items
            setCurrOpenMenu('')
        }

        handleMouseAction(item.id, 'onPointerEnter')
    }

    // When rendering the syndicated component, we don't know what the URL of the current page is
    // until we're in the browser, so use window.location when useHydrated() returns true.
    //
    // Note: this means that the active section won't be underlined until after React finishes
    // loading and this component renders for the second time.
    const isHydrated = useHydrated()
    const parentSectionId = isHydrated
        ? shouldIndicateActiveSection &&
          getParentSectionId(links, new URL(window.location.href))
        : ''

    function itemHasValidLinks(item: NavLinkType) {
        return (
            (Array.isArray(item.links) && item.links.length) ||
            (item.links && Array.isArray(item.links) === false)
        )
    }

    return (
        <div
            className={lit`flex items-baseline pb-4 ${wrapperDivClassList}`}
            // onMouseLeave needs to be on this div. When on the popover button
            // instead, the submenus open but disappear handleMouseAction
            onMouseLeave={() => {
                currOpenMenu && handleMouseAction(currOpenMenu, 'onMouseLeave')
            }}
        >
            <Popover className="relative">
                {() => (
                    <Popover.Group as="nav" className="flex">
                        <button // needed to close price database dropdown when mousing
                            // out from it to the space on the left
                            type="button"
                            className="w-[1px] cursor-pointer opacity-0"
                            tabIndex={-1}
                            onPointerEnter={closeMenu}
                            aria-hidden="true"
                        />
                        {Object.values(links)
                            .filter(filterBy)
                            .map((navItem) => {
                                const item = { ...navItem }

                                const renderedItem =
                                    item.id === 'search' && renderSearch ? (
                                        renderSearch({
                                            item,
                                            setCurrOpenMenu,
                                            currOpenMenu,
                                            handleMouseAction,
                                        })
                                    ) : itemHasValidLinks(item) ? (
                                        <button
                                            type="button"
                                            className={lit`${linkClassList}
                                                ${item?.uiSpec?.desktop}
                                                ${
                                                    shouldIndicateActiveSection &&
                                                    item?.id ===
                                                        parentSectionId &&
                                                    'underline decoration-1 underline-offset-2'
                                                }
                                            `}
                                            onClick={() => toggleMenu(item.id)}
                                            onPointerEnter={() =>
                                                handlePointerEnter(item)
                                            }
                                        >
                                            {item.label}
                                            <ChevronDownIcon
                                                className="inline"
                                                aria-hidden="true"
                                                size={iconSize}
                                            />
                                        </button>
                                    ) : item.url ? (
                                        // disable erroneous lint warning
                                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                                        <NavLink
                                            link={item} // py-[0.297rem, aka 24px] to get tab outline to be
                                            // same size as other buttons. h-6 not working here.
                                            className={lit`${linkClassList}
                                                    ${item?.uiSpec?.desktop}
                                                    ${
                                                        shouldIndicateActiveSection &&
                                                        item?.id ===
                                                            parentSectionId &&
                                                        'underline decoration-1 underline-offset-2'
                                                    }
                                                `}
                                            onClick={(e) => {
                                                if (
                                                    item?.uiSpec?.preventDefault
                                                ) {
                                                    e.preventDefault()
                                                }
                                                if (item.onClick) {
                                                    item?.onClick()
                                                }
                                            }}
                                            onPointerEnter={() => {
                                                handlePointerEnter(item)
                                            }}
                                        />
                                    ) : (
                                        // items w/o subitems
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                if (
                                                    item?.uiSpec?.preventDefault
                                                ) {
                                                    e.preventDefault()
                                                }
                                                if (item.onClick) {
                                                    item?.onClick()
                                                }
                                            }}
                                            className="pr-4"
                                        >
                                            {item.label}
                                        </button>
                                    )

                                function renderItemWithSubItems(
                                    item: NavLinkType
                                ) {
                                    return (
                                        <ul className="relative flex w-fit flex-col">
                                            <li
                                                className={`${item.uiSpec?.desktop} text-[.9375rem]`}
                                                key={item.id}
                                            >
                                                {item.url ? (
                                                    <NavLink
                                                        link={item}
                                                        onClick={(e) =>
                                                            handleNavLinkClick &&
                                                            handleNavLinkClick(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                        className={lit`dark:text-white
                                            ${nestedlinkClassList}
                                            ${item?.uiSpec?.desktop}
                                            `}
                                                    />
                                                ) : (
                                                    <span>{item.label}</span>
                                                )}
                                            </li>
                                            {item.links &&
                                                Object.values(item.links).map(
                                                    (subitem) => (
                                                        <li
                                                            key={subitem.label}
                                                            className="pt-4 pl-4"
                                                        >
                                                            <NavLink
                                                                link={subitem}
                                                                onClick={(e) =>
                                                                    handleNavLinkClick &&
                                                                    handleNavLinkClick(
                                                                        e,
                                                                        item
                                                                    )
                                                                }
                                                                className={lit`dark:text-white
                                        ${nestedlinkClassList}
                                        ${subitem?.uiSpec?.desktop}
                                        `}
                                                            />
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    )
                                }

                                return (
                                    <Popover
                                        className={lit`relative dark:text-white ${item?.uiSpec?.desktop}`}
                                        key={item.id}
                                    >
                                        {renderedItem}
                                        <Transition
                                            show={currOpenMenu === item.id}
                                            enter="transition-none"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-none"
                                            // this is important for SEO - we want all main nav links to be in
                                            // the server-rendered HTML
                                            unmount={false}
                                        >
                                            <Popover.Panel
                                                static
                                                className="absolute top-full z-[1500] mt-4 -ml-4 border-[1px] border-neutral-200 bg-white shadow-lg dark:bg-black"
                                            >
                                                <ul className="relative flex w-fit flex-col">
                                                    {Object.values(
                                                        nestedLinks(item.id)
                                                    ).map((item) => (
                                                        <li
                                                            className={lit`light:hover:bg-neutral-100 mx-3 block w-fit whitespace-nowrap py-2 text-[.9375rem] font-normal
                                                    `}
                                                            key={item.id}
                                                        >
                                                            {/* disable erroneous lint warning */}
                                                            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                                            {item.links ? (
                                                                renderItemWithSubItems(
                                                                    item
                                                                )
                                                            ) : (
                                                                <NavLink
                                                                    link={item}
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleNavLinkClick &&
                                                                        handleNavLinkClick(
                                                                            e,
                                                                            item
                                                                        )
                                                                    }
                                                                    className={lit`dark:text-white
                                                                ${nestedlinkClassList}
                                                                ${item?.uiSpec?.desktop}
                                                                `}
                                                                />
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Popover.Panel>
                                        </Transition>
                                    </Popover>
                                )
                            })}
                        <button // needed to close price database dropdown when mousing
                            // out from it to the space on the right
                            type="button"
                            className="w-[1px] cursor-pointer opacity-0"
                            tabIndex={-1}
                            onPointerEnter={closeMenu}
                            aria-hidden="true"
                        >
                            Hidden
                        </button>
                    </Popover.Group>
                )}
            </Popover>
        </div>
    )
}
