import React from 'react'
import invariant from 'tiny-invariant'
import { RootContext } from '~/root-context'

/**
 * This file contains hooks to manages the state of selected items in a list, and provide
 * functions to change the selection.
 *
 * The hook functions are useSelection() and useSelectionWithContext().
 * useSelection() can be used on its own but the state will belong only to the component
 * where you use it (unless you manually pass it), whereas useSelectionWithContext() can
 * be used to share selection state among multiple components underneath the same
 * <SelectionProvider>.
 */

export type SelectionContextType = ReturnType<typeof useSelection>

const SelectionContext = React.createContext<SelectionContextType | null>(null)

interface UseSelectionParams {
    data: any[]
    allowSelection?: boolean
    defaultSelection?: string[]
    selectedLotIds?: string[]
    getItemId?: (item: any) => string
    clearSelectionOnDataChange?: boolean
}

interface SelectionProviderProps extends UseSelectionParams {
    children: React.ReactNode
}

function defaultGetItemIdCallback(item: { id: string }) {
    return item.id
}

export function SelectionProvider({
    children,
    ...useSelectionParams
}: SelectionProviderProps) {
    const ctx = useSelection(useSelectionParams)
    return (
        <SelectionContext.Provider value={ctx}>
            {children}
        </SelectionContext.Provider>
    )
}

/**
 * This hook can be used on its own but the state will belong only to the component
 * where you use it (unless you manually pass it).
 *
 * Do *not* mix and match useSelectionWithContext() and useSelection() - when using
 * <SelectionProvider> to share state, always use useSelectionWithContext().
 */
export function useSelection({
    data,
    allowSelection: initialAllowSelection = false,
    defaultSelection = [],
    selectedLotIds = [],
    getItemId = defaultGetItemIdCallback,
    clearSelectionOnDataChange = false,
}: UseSelectionParams) {
    const rootContext = React.useContext(RootContext)

    const { setSelectionModeOn } = rootContext

    // indicates whether selection mode is currently active
    const [allowSelection, setAllowSelection] = React.useState(
        initialAllowSelection
    )

    const [isActionOccurring, setIsActionOccurring] = React.useState(false)

    const [selectedIds, setSelectedIds] = React.useState<Set<string>>(
        new Set(defaultSelection)
    )

    const toggleAllowSelection = () => {
        setSelectionModeOn(true)
        setAllowSelection(!allowSelection)
    }

    const selectedResults = data.filter((item) =>
        selectedLotIds.includes(item.artListingDetailId)
    )

    const isSelected = (item: any) => selectedIds.has(getItemId(item))

    const toggleSelection = React.useCallback(
        (item: any) => {
            const id = getItemId(item)
            const newSelected = new Set(selectedIds)
            if (selectedIds.has(id)) {
                newSelected.delete(id)
            } else {
                newSelected.add(id)
            }
            setSelectedIds(newSelected)
        },
        [selectedIds, setSelectedIds, getItemId]
    )

    const allAreSelected = React.useMemo(() => {
        return selectedIds.size !== 0 && selectedIds.size === data.length
    }, [data, selectedIds])

    const clearSelection = React.useCallback(() => {
        setSelectedIds(new Set())
    }, [])

    const toggleSelectAll = React.useCallback(() => {
        if (allAreSelected) {
            clearSelection()
        } else {
            setSelectedIds(new Set(data.map(getItemId)))
        }
    }, [data, allAreSelected, clearSelection, getItemId])

    React.useEffect(() => {
        if (!allowSelection) {
            setSelectionModeOn(false)
        }
    }, [allowSelection, setSelectionModeOn])

    React.useEffect(() => {
        if (clearSelectionOnDataChange) {
            clearSelection()
        }
    }, [clearSelectionOnDataChange, clearSelection, data])

    return {
        allowSelection,
        setAllowSelection,
        toggleAllowSelection,
        selectedIds,
        selectedResults,
        isSelected,
        toggleSelection,
        allAreSelected,
        toggleSelectAll,
        clearSelection,
        isActionOccurring,
        setIsActionOccurring,
    }
}

/**
 * Manages the state of selected items in a list, and provides functions to change the
 * selection. This hook is intended to be used with a SelectionProvider context, and it
 * makes it possible to easily share the selection state among multiple components with
 * a common parent/ancestor component.
 *
 * Usage:

    function ParentComponent() {
        return (
            <SelectionProvider data={resultsFromLoader} allowSelection={true}>
                <Foo data={resultsFromLoader} />
                <Bar />
            </SelectionProvider>
        )
    }

    function Foo({ data }) {
        const { allowSelection, isSelected, toggleSelection } = useSelectionWithContext()

        function handleSelectRow(entityId: string) {
            toggleSelection(entityId)
        }

        return (
            <ul>
                {data.map(entity => (
                    <li>
                        <Checkbox onChange={() => handleSelectRow(entity.id)} />
                        {entity.name}
                    </li>
                ))}
            </ul>
        )
    }

    function Bar() {
        const { selectedIds } = useSelectionWithContext()
        console.log('selectedIds', selectedIds)
        return null
    }
 */
export function useSelectionWithContext(): SelectionContextType {
    const ctx = React.useContext(SelectionContext)
    if (ctx === null) {
        if (process.env.NODE_ENV === 'development') {
            invariant(
                false,
                "useSelection hook was used in a component that wasn't wrapped with <SelectionProvider>"
            )
        } else {
            invariant(false)
        }
    }
    return ctx
}
