import { memoizeFirst } from '@artnetworldwide/ui-library/utils'

export const getNewsHost = memoizeFirst((siteBaseUrl: string) => {
    const url = new URL(siteBaseUrl)
    const hostnameParts = url.hostname.split('.')

    let newsSubdomain = 'news'
    const [firstPart] = hostnameParts
    if (/\bnews$/.test(firstPart)) {
        newsSubdomain = firstPart
    } else if (firstPart !== 'www' && hostnameParts.length > 2) {
        // check for subdomain - www, qa1, qa2, beta, etc
        const matches = firstPart.match(/^(qa\d|beta)(-|$)/)
        newsSubdomain =
            matches && matches.length > 0 ? `${matches[1]}-news` : 'news'
    }

    const rootDomain =
        hostnameParts.length > 2
            ? hostnameParts.splice(1).join('.')
            : url.hostname
    const rootDomainAndPort = url.port
        ? `${rootDomain}:${url.port}`
        : rootDomain

    return `${newsSubdomain}.${rootDomainAndPort}`
})
