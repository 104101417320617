/**
 * Constants related to the core-data part of our system
 * (i.e. the data available from https://graphql.artnet-dev.com/coredata/graphql)
 */

export const artListingFilterNamesFineArt = [
    'creator',
    'creatorNationality',
    'birthYear',
    'deathYear',
    'auctionHouse',
    'objectType',
    'medium',
    'lotTitle',
    'freeText',
    'creationYear',
    'dimension',
    'saleDate',
    'sale',
    'price',
    // 'movement',
    'period',
    'simplifiedTransactionStatus',
    'currency',
    'region',
    'category',
    'subjectMatter',
    'saleLocation',
    'saleNumber',
    'lotNumber',
    'raisonne',
    'hasPhoto',
]

// we use a separate array for dec art because the order of the filters is different
// in some cases
export const artListingFilterNamesDecArt = [
    // 'creator' filter commented out on 3/9/23:
    // Creator filter must be suppressed for dec art due to unresolved Dexi data import
    // and PDB CMS issues
    // 'creator',

    'movement',
    'objectType',
    'freeText',
    'auctionHouse',
    'lotTitle',
    'creationYear',
    'dimension',
    'saleDate',
    'sale',
    'price',
    'material',
    'period',
    'simplifiedTransactionStatus',
    'currency',
    'region',
    'category',
    'subjectMatter',
    'saleLocation',
    'saleNumber',
    'lotNumber',
    'raisonne',
    'hasPhoto',
]

// used for "coming soon" filters
export const filterNamesNotYetImplemented = [
    'category',
    'subjectMatter',
    'saleLocation',
    'saleNumber',
    'lotNumber',
    'raisonne',
    'hasPhoto',
]

// specifies the type of filter, which tells us which kind of input
// the filter expects
export const filterTypes: Record<string, string> = {
    creator: 'Entity',
    auctionHouse: 'Entity',
    lotTitle: 'Entity',
    dimension: 'DimensionRange',
    medium: 'Entity',
    movement: 'Entity',
    price: 'PriceRange',
    creationYear: 'YearRange',
    birthYear: 'YearRange',
    deathYear: 'YearRange',
    sale: 'Entity',
    saleDate: 'DateRange',
    objectType: 'Entity',
    material: 'Entity',
    category: 'Entity',
    period: 'Entity',
    region: 'Entity',
    subjectMatter: 'Entity',
    creatorNationality: 'Entity',
    simplifiedTransactionStatus: 'Entity',
    currency: 'Entity',
    classification: 'Entity',
    freeText: 'FreeText',
}

export function getFilterType(filterName: string): string {
    return filterTypes[filterName]
}

export const dimensionFilterBuckets = [
    {
        bucketName: 'small',
        title: 'Small',
        label: 'Under 40cm',
        lowerBoundMm: 0,
        upperBoundMm: 400,
    },
    {
        bucketName: 'med',
        title: 'Medium',
        label: '40cm - 100cm',
        lowerBoundMm: 400,
        upperBoundMm: 1000,
    },
    {
        bucketName: 'large',
        title: 'Large',
        label: '100cm - 160cm',
        lowerBoundMm: 1000,
        upperBoundMm: 1600,
    },

    {
        bucketName: 'xLarge',
        title: 'Extra Large',
        label: 'Over 160cm',
        lowerBoundMm: 1600,
        upperBoundMm: 100000,
    },
    // {
    //     bucketName: 'unknownSize',
    //     title: 'Unknown Size',
    //     label: null,
    // },
]
