import React from 'react'
import { getSyndicatedComponentParamSettings } from '../utils'
import { useIsSyndicatedComponent } from './is-syndicated-component'
import type { InitialComponentStateData } from '../types'

const isServer = typeof document === 'undefined'

export type ServerTemplateParamGetterFunc = (propName: string) => any

// Returns a function that can be used wherever we want to render the value of a
// Server Template param in the HTML.
export function useServerTemplateParamGetter(
    syndicatedComponentId: string,
    realReactProps: Record<string, any>
): ServerTemplateParamGetterFunc {
    const isSyndicated = useIsSyndicatedComponent()

    let componentData: InitialComponentStateData | undefined
    if (isSyndicated && !isServer) {
        componentData =
            (window as any).__SYNDICATION_STATE__[syndicatedComponentId] ?? {}
    }

    const serverTemplateParamNames = React.useMemo(() => {
        if (!componentData?.metadata?.serverTemplateParams) {
            return []
        }
        return componentData.metadata.serverTemplateParams.map((propDef) => {
            const [propName] = getSyndicatedComponentParamSettings(propDef)
            return propName
        })
    }, [componentData])

    const getServerTemplateParam = React.useCallback(
        (propName: string) => {
            if (!isSyndicated) {
                if (
                    process.env.NODE_ENV === 'development' &&
                    !realReactProps.hasOwnProperty(propName)
                ) {
                    console.error(
                        `Problem rendering '${syndicatedComponentId}' component in non-syndicated mode: ` +
                            `missing prop '${propName}'`
                    )
                }
                return realReactProps[propName]
            }

            if (isServer) {
                return `{{${propName}}}`
            }

            if (!serverTemplateParamNames.includes(propName)) {
                throw Error(
                    `Unrecognized template prop '${propName}'. Did you forget to add it to ` +
                        '`export const serverTemplateParams = ...`?'
                )
            }

            const { outsideParamValues } = componentData!
            if (!outsideParamValues.hasOwnProperty(propName)) {
                // For all template variables, the external app should replace both the template variable
                // itself in the HTML (e.g. {{loginUrl}}) and also include that variable when replacing the
                // {{componentJson}} placeholder in the <script> tag included in the template (with the
                // same value of course). This is needed to make the server-rendered HTML match the
                // client-rendered HTML so that React hydration works correctly.
                throw Error(
                    `Error rendering '${syndicatedComponentId}' component: ` +
                        `prop '${propName}' not found in client-side props. All server template props must ` +
                        'also be included in {{componentJson}}. Note: this error does not detect whether ' +
                        'required template replacements were done on the server, so check that too.'
                )
            }

            return outsideParamValues[propName]
        },
        [
            syndicatedComponentId,
            isSyndicated,
            realReactProps,
            componentData,
            serverTemplateParamNames,
        ]
    )

    return getServerTemplateParam
}
