import { SYNDICATED_COMPONENT_ID } from './constants'
import { checkLoginAndRedirectIfNeeded } from './login-redirect'
import GlobalHeaderSyndicationContainer from '~/components/headers/global/GlobalHeaderSyndicationContainer'

const isServer = typeof document === 'undefined'

interface DeliveryPackageProps {
    syndicatedComponentId: string
    renderSubheaderDesktop?: () => React.ReactNode
    renderSubheaderMobile?: () => React.ReactNode
}

if (!isServer) {
    checkLoginAndRedirectIfNeeded()
}

export default function DeliveryPackage({
    syndicatedComponentId = SYNDICATED_COMPONENT_ID,
    renderSubheaderDesktop,
    renderSubheaderMobile,
}: DeliveryPackageProps) {
    return (
        <GlobalHeaderSyndicationContainer
            syndicatedComponentId={syndicatedComponentId}
            renderSubheaderDesktop={renderSubheaderDesktop}
            renderSubheaderMobile={renderSubheaderMobile}
        />
    )
}
