import { initComponentInBrowser } from '~/syndicated-components/utils'
import DeliveryPackage from './delivery-package'

// Syndicated component test URL:
// http://localhost:3000/syndication/single-component-demo/global-header/ssr?scriptOnlyParams=%7B%22authUrls%22%3A%7B%22login%22%3A%22https%3A%2F%2Fgateway.artnet-dev.com%2Flogin%3FreturnUrl%3Dhttp%253A%252F%252Flocalhost%253A3000%252F%26backoutUrl%3Dhttp%253A%252F%252Flocalhost%253A3000%252F%22%2C%22register%22%3A%22https%3A%2F%2Fgateway.artnet-dev.com%2Fregister%3FreturnUrl%3Dhttp%253A%252F%252Flocalhost%253A3000%252F%26backoutUrl%3Dhttp%253A%252F%252Flocalhost%253A3000%252F%22%2C%22logout%22%3A%22https%3A%2F%2Fgateway.artnet-dev.com%2Flogout%3FreturnUrl%3Dhttp%253A%252F%252Flocalhost%253A3000%252F%22%7D%7D

export default DeliveryPackage

export const serverTemplateParams = [
    ['maxWidthInRems', { defaultValForDemoPage: 82 }],
    ['theme', { defaultValForDemoPage: 'light' }],
] as const

export const scriptOnlyParams = [
    'authUrls',
    ['enableStickyScroll', { defaultValForDemoPage: true }],
] as const

initComponentInBrowser(DeliveryPackage, 'global-header')
