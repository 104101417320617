/* eslint-disable jsx-a11y/anchor-has-content */
import type { SyntheticEvent } from 'react'
import { Popover } from '@headlessui/react'
import {
    ArtnetLogoIcon,
    UserCircleIcon,
    SearchIcon,
    useToggle,
} from '@artnetworldwide/ui-library'
import { buildClassName, lit } from '@artnetworldwide/ui-library/utils'
import { LenderFormSyndicationContainer as LenderForm } from '~/components/lender-form/LenderFormSyndicationContainer'
import { GlobalHeaderProfileMenu } from './GlobalHeaderProfile'
import { NavbarDesktop } from '~/components/Navbar'
import { getHeaderData } from '../../../../data/global-nav/global-header'
import { ClientOnly } from 'remix-utils'
import type { ServerTemplateParamGetterFunc } from '~/syndicated-components/hooks/server-template-param-getter'

interface GlobalHeaderDesktopProps {
    headerData: ReturnType<typeof getHeaderData>
    isVisible: boolean
    currentY?: number
    onToggleSearchOpen: () => void
    showLenderForm: boolean
    setShowLenderForm: (show: boolean) => void
    shouldIndicateActiveSection?: boolean
    theme: string
    getServerTemplateParam: ServerTemplateParamGetterFunc
}

export function GlobalHeaderDesktop({
    headerData,
    isVisible = false,
    onToggleSearchOpen,
    currentY = 0,
    showLenderForm,
    setShowLenderForm,
    theme,
    getServerTemplateParam,
}: GlobalHeaderDesktopProps) {
    const parentLinks = headerData.nav.links

    const [profileOpen, toggleProfileOpen] = useToggle(false)

    const handleLenderFormClose = () => {
        setShowLenderForm(false)
    }

    const handleNavLinkClick = (e: SyntheticEvent, link: any) => {
        if (link.id === 'financing') {
            setShowLenderForm(true)
            e.preventDefault()
        }
    }

    const profileMenuProps = {
        headerData,
        handleNavLinkClick,
        profileOpen,
        toggleProfileOpen,
        theme,
    }

    const navbarAProps = {
        links: parentLinks,
        handleNavLinkClick,
        shouldIndicateActiveSection: true,
        filterBy: (item: any) => item.group === 'a',
        wrapperDivClassList: 'flex grow',
        linkClassList:
            'items-center pr-7 text-base hover:text-[#737373] dark:hover:text-neutral-400 focus:text-[#737373]',
    }
    const navbarBProps = {
        links: parentLinks,
        handleNavLinkClick,
        filterBy: (item: any) => item.group === 'b',
        wrapperDivClassList: 'flex grow-0',
        linkClassList:
            'items-center text-base hover:text-[#737373] dark:hover:text-neutral-400 focus:text-[#737373]',
    }

    const maxWidthInRemsVal = getServerTemplateParam('maxWidthInRems')

    return (
        <div
            className={lit`${buildClassName(
                'GlobalHeaderDesktop'
            )}  mx-auto bg-white px-8 pt-8 dark:bg-black
            ${
                // note:adding a pseudo border via before:: seems to stall hovering of global header
                isVisible &&
                currentY > 0 &&
                'before:absolute before:bottom-0 before:left-0 before:h-1 before:w-screen before:shadow-[0px_2px_3px_rgba(153,153,153,0.4)]'
            }
            `}
            style={{ maxWidth: `${maxWidthInRemsVal}rem` }}
        >
            <Popover className="relative">
                {() => (
                    <div>
                        <div className="flex items-center justify-center pb-6">
                            <a
                                href={headerData.logo.url}
                                className={buildClassName('ArtnetLogo')}
                            >
                                {/* TODO accessible description (use headerData.logo.altText) */}
                                <ArtnetLogoIcon
                                    className="-ml-[0.6rem] fill-black dark:fill-white"
                                    fill="black"
                                    size="medium"
                                />
                            </a>
                            <ClientOnly>
                                {() =>
                                    headerData.account.isLoggedIn !==
                                        undefined && (
                                        <div className="absolute right-3 text-base leading-4">
                                            {headerData.account.isLoggedIn ? (
                                                <button
                                                    className={`${buildClassName(
                                                        'GlobalHeaderProfileButton'
                                                    )} flex`}
                                                    type="button"
                                                    onClick={() => {
                                                        toggleProfileOpen()
                                                    }}
                                                >
                                                    <div className="max-w-[16rem] truncate pb-2 text-neutral-500 dark:text-neutral-400">
                                                        Welcome,&nbsp;
                                                        {
                                                            headerData.account
                                                                .displayName
                                                        }
                                                        &nbsp;
                                                    </div>
                                                    <UserCircleIcon className="dark:stroke-white" />
                                                </button>
                                            ) : (
                                                <div className="flex items-baseline">
                                                    <a
                                                        href={
                                                            headerData.logIn.url
                                                        }
                                                        className={`${buildClassName(
                                                            'NavLink'
                                                        )}text-neutral-500 dark:text-neutral-400`}
                                                    >
                                                        {headerData.logIn.label}
                                                        &nbsp;
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            </ClientOnly>
                        </div>
                        <div className="flex">
                            <NavbarDesktop {...navbarAProps} />
                            <NavbarDesktop
                                {...navbarBProps}
                                renderSearch={({
                                    item,
                                    setCurrOpenMenu,
                                    currOpenMenu,
                                    handleMouseAction,
                                }) => (
                                    <button
                                        className={lit`
                                            ${buildClassName(
                                                'GlobalHeaderSearchButton'
                                            )} flex
                                            h-6 items-center
                                            text-base leading-4
                                            text-neutral-500 dark:text-neutral-400`}
                                        type="button"
                                        data-testid="search-button"
                                        onClick={onToggleSearchOpen}
                                        onPointerEnter={() => {
                                            setCurrOpenMenu(item.id)

                                            if (currOpenMenu !== item.id) {
                                                // this is needed to prevent an empty submenu from
                                                // opening on a menu w/o submenu items
                                                setCurrOpenMenu('')
                                            }

                                            handleMouseAction(
                                                item.id,
                                                'onPointerEnter'
                                            )
                                        }}
                                    >
                                        Search
                                        <SearchIcon
                                            size="small"
                                            className="inline pl-4 pr-3"
                                        />
                                    </button>
                                )}
                            />
                        </div>
                    </div>
                )}
            </Popover>
            {headerData.account.isLoggedIn && (
                <GlobalHeaderProfileMenu {...profileMenuProps} />
            )}
            <LenderForm onClose={handleLenderFormClose} show={showLenderForm} />
        </div>
    )
}
