import React from 'react'

export type StateOfIncludeUnknown = {
    countUnknown?: number
    includeUnknown: boolean
    toggleIncludeUnknown: () => void
}

// Manages state for the "include unknown" checkbox for filters
export function useIncludeUnknown(
    countUnknown?: number,
    initialIncludeUnknownState?: boolean
) {
    const [includeUnknown, setIncludeUnknown] = React.useState(
        initialIncludeUnknownState ?? false
    )

    function toggleIncludeUnknown() {
        setIncludeUnknown(!includeUnknown)
    }

    return {
        countUnknown,
        includeUnknown,
        toggleIncludeUnknown,
    }
}
