import {
    Country,
    InquireAboutFinancingRequest_Input,
} from '~/services/graphql/generated'
import { LenderFormValues } from './LenderFormForm'

export const ROUTE_PATH = '/api/frontend/lender-form'

export interface LenderFormContainerProps {
    show: boolean
    onClose: (wasSuccessful: boolean) => void
}

export interface LenderInquiryResult {
    ok?: boolean
    errors?: string[]
    message?: string
}

export type HandleLenderInquiryFunction = (
    LenderFormValues: LenderFormValues
) => void

export interface LenderFormLoaderData {
    countries: Country[]
}

export interface LenderFormActionData {
    ok: boolean
    errors: {
        message: string
    }[]
    message: string
}

export interface LenderFormActionParams
    extends InquireAboutFinancingRequest_Input {}
