import React from 'react'
import { config, isNonProductionEnv } from '../utils'
import useSWRMutation from 'swr/mutation'

const schemeAndAuthority = config.URL_SCHEME_AND_AUTHORITY

const defaultFetchOptions: RequestInit = isNonProductionEnv
    ? { credentials: 'include' }
    : {}

export function useSendRequest(fetchOptions: RequestInit) {
    const sendRequest = React.useCallback(
        async (url: RequestInfo | URL, { arg }: { arg: any }) => {
            const options = {
                ...fetchOptions,
                method: 'POST',
                body: JSON.stringify(arg),
            }

            const data = await fetch(url, options)

            if (data.status !== 200) {
                const baseMessage = `Failed to send request to ${url}`
                let message
                try {
                    message = baseMessage + ': ' + (await data.text())
                } catch (e) {
                    message = baseMessage
                }
                throw Error(message)
            }

            return data.json()
        },
        [fetchOptions]
    )

    return sendRequest
}

export function useRemixAction<Data, TriggerParams>(routeUrl: string) {
    const urlToFetch = React.useMemo(() => {
        const url = new URL(schemeAndAuthority + routeUrl)
        url.searchParams.append('_data', 'routes' + routeUrl)
        return url.toString()
    }, [routeUrl])

    const sendRequest = useSendRequest(defaultFetchOptions)
    const { data, error, isMutating, reset, trigger } = useSWRMutation<
        Data,
        any,
        string,
        TriggerParams
    >(urlToFetch, sendRequest)

    return {
        data: data as Data,
        error,
        isMutating,
        reset,
        trigger,
    }
}
