import { useCallback } from 'react'
import { useToggle } from 'react-use'

// ['Global Search', 'Artnet Auction', 'Artnet News']

export function useGlobalHeaderSearch() {
    const [isSearchOpen, setSearchOpen] = useToggle(false)

    const toggleSearchOpen = useCallback(() => {
        setSearchOpen()
    }, [setSearchOpen])

    return {
        isSearchOpen,
        toggleSearchOpen,
    }
}
