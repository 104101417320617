import { buildClassName } from '@artnetworldwide/ui-library/utils'
import { Link } from '@remix-run/react'
import { SyntheticEvent } from 'react'
import { NavLinkType } from '~/components/headers/global/types'
import { useIsSyndicatedComponent } from '~/syndicated-components/hooks'

const externalLinkRegex = /^https?:/

export interface NavLinkProps {
    link: NavLinkType
    onClick?: (event: SyntheticEvent, link: NavLinkType) => void
    onPointerEnter?: (event: SyntheticEvent) => void
    className?: string
}

export function NavLink({
    link,
    onClick,
    onPointerEnter,
    className = '',
}: NavLinkProps) {
    const isSyndicated = useIsSyndicatedComponent()

    if (!link.url) {
        if (process.env.NODE_ENV === 'development') {
            console.warn('Missing URL for nav link: ' + JSON.stringify(link))
        }
        return null
    }

    function handleClick(e: SyntheticEvent) {
        onClick?.(e, link)
        return true
    }

    function handlePointerEnter(e: SyntheticEvent) {
        onPointerEnter?.(e)
        return true
    }

    const finalClassName = buildClassName('NavLink') + ' ' + className

    if (
        isSyndicated ||
        link.uiSpec?.useServerSideRouting ||
        externalLinkRegex.test(link.url)
    ) {
        return (
            <a
                href={link.url}
                onClick={handleClick}
                onPointerEnter={handlePointerEnter}
                className={finalClassName}
                data-testid={link.id}
            >
                {link.label || link.icon?.altText}
            </a>
        )
    } else {
        return (
            <Link
                to={link.url}
                onClick={handleClick}
                onPointerEnter={handlePointerEnter}
                className={finalClassName}
                data-testid={link.id}
            >
                {link.label || link.icon?.altText}
            </Link>
        )
    }
}
