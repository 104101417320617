import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    AbsoluteUrl: any
    BigInt: any
    Date: any
    DateTime: any
    EchoUserInfoRequest_Input: any
    GetCountriesWithFinancingRequest_Input: any
    RelativeUrl: any
    Time: any
    Url: any
}

export type AddArtListingCuratedListItemsRequest_Input = {
    itemIds?: InputMaybe<Array<Scalars['ID']>>
    listIds?: InputMaybe<Array<Scalars['ID']>>
}

export type AddArtListingCuratedListItemsResponse = {
    __typename: 'AddArtListingCuratedListItemsResponse'
    results: Array<AddCuratedListItemsResult>
}

export type AddCuratedListItemsResult = {
    __typename: 'AddCuratedListItemsResult'
    list: ArtListingCuratedListSummary
    numAdded: Scalars['Int']
}

export type AggregationCriteria_Input = {
    attribute?: InputMaybe<GroupByAggregation_Input>
    auctionHouse?: InputMaybe<GroupByAggregation_Input>
    birthYear?: InputMaybe<VariableWidthAggregation_Input>
    category?: InputMaybe<GroupByAggregation_Input>
    classification?: InputMaybe<GroupByAggregation_Input>
    creationYear?: InputMaybe<VariableWidthAggregation_Input>
    creator?: InputMaybe<GroupByAggregation_Input>
    creatorNationality?: InputMaybe<GroupByAggregation_Input>
    currency?: InputMaybe<GroupByAggregation_Input>
    deathYear?: InputMaybe<VariableWidthAggregation_Input>
    dimension?: InputMaybe<Array<DimensionBucketAggregation_Input>>
    hasPhoto?: InputMaybe<GroupByAggregation_Input>
    isEdition?: InputMaybe<GroupByAggregation_Input>
    isInscribed?: InputMaybe<GroupByAggregation_Input>
    isSigned?: InputMaybe<GroupByAggregation_Input>
    isStamped?: InputMaybe<GroupByAggregation_Input>
    lotTitle?: InputMaybe<GroupByAggregation_Input>
    material?: InputMaybe<GroupByAggregation_Input>
    media?: InputMaybe<GroupByAggregation_Input>
    medium?: InputMaybe<GroupByAggregation_Input>
    mediumRaw?: InputMaybe<GroupByAggregation_Input>
    movement?: InputMaybe<GroupByAggregation_Input>
    objectType?: InputMaybe<GroupByAggregation_Input>
    period?: InputMaybe<GroupByAggregation_Input>
    price?: InputMaybe<PriceAggregation_Input>
    region?: InputMaybe<GroupByAggregation_Input>
    sale?: InputMaybe<GroupByAggregation_Input>
    saleDate?: InputMaybe<VariableWidthAggregation_Input>
    searchSuggestion?: InputMaybe<GroupByAggregation_Input>
    simplifiedTransactionStatus?: InputMaybe<GroupByAggregation_Input>
    subjectMatter?: InputMaybe<GroupByAggregation_Input>
    substrate?: InputMaybe<GroupByAggregation_Input>
    transactionStatus?: InputMaybe<GroupByAggregation_Input>
}

export type AggregationRequest_Input = {
    aggregationCriteria: AggregationCriteria_Input
    filters?: InputMaybe<ArtListingFilters_Input>
    freeformTextQuery?: InputMaybe<Scalars['String']>
}

export type AggregationResult = {
    __typename: 'AggregationResult'
    count: Maybe<Scalars['BigInt']>
    description: Maybe<Scalars['String']>
    displayName: Maybe<Scalars['String']>
    entityType: Maybe<EntityType>
    id: Scalars['ID']
    subAggregations: Array<AggregationResult>
    subEntityType: Maybe<SubEntityType>
}

export type AggregationResults = {
    __typename: 'AggregationResults'
    attribute: Maybe<EntityAggregationResults>
    auctionHouse: Maybe<EntityAggregationResults>
    birthYear: Maybe<VariableWidthAggregationResults>
    category: Maybe<EntityAggregationResults>
    classification: Maybe<EntityAggregationResults>
    creationYear: Maybe<VariableWidthAggregationResults>
    creator: Maybe<EntityAggregationResults>
    creatorNationality: Maybe<EntityAggregationResults>
    currency: Maybe<EntityAggregationResults>
    deathYear: Maybe<VariableWidthAggregationResults>
    dimensions: Maybe<DimensionAggregationResults>
    hasPhoto: Maybe<EntityAggregationResults>
    isEdition: Maybe<EntityAggregationResults>
    isInscribed: Maybe<EntityAggregationResults>
    isSigned: Maybe<EntityAggregationResults>
    isStamped: Maybe<EntityAggregationResults>
    lotTitle: Maybe<EntityAggregationResults>
    material: Maybe<EntityAggregationResults>
    media: Maybe<EntityAggregationResults>
    medium: Maybe<EntityAggregationResults>
    mediumRaw: Maybe<EntityAggregationResults>
    movement: Maybe<EntityAggregationResults>
    objectType: Maybe<EntityAggregationResults>
    period: Maybe<EntityAggregationResults>
    price: Maybe<VariableWidthAggregationResults>
    region: Maybe<EntityAggregationResults>
    sale: Maybe<EntityAggregationResults>
    saleCountry: Maybe<EntityAggregationResults>
    saleDate: Maybe<VariableWidthDateAggregationResults>
    searchSuggestion: Maybe<EntityAggregationResults>
    simplifiedTransactionStatus: Maybe<EntityAggregationResults>
    subjectMatter: Maybe<EntityAggregationResults>
    substrate: Maybe<EntityAggregationResults>
    transactionStatus: Maybe<EntityAggregationResults>
}

export type ArtListingCuratedList = {
    __typename: 'ArtListingCuratedList'
    count: Scalars['Int']
    dateCreated: Scalars['DateTime']
    dateModified: Scalars['DateTime']
    featuredImage: Maybe<Image>
    id: Scalars['ID']
    items: Array<ArtListingCuratedListItem>
    origin: ArtListingCuratedListOrigin
    status: ArtListingCuratedListStatus
    title: Scalars['String']
}

export type ArtListingCuratedListItem = {
    __typename: 'ArtListingCuratedListItem'
    artListing: Maybe<ArtListingSummary>
    dateAdded: Scalars['DateTime']
    id: Scalars['ID']
    position: Scalars['Int']
    title: Scalars['String']
}

export enum ArtListingCuratedListItemSortBy {
    CreationDate = 'CREATION_DATE',
    DateAdded = 'DATE_ADDED',
    Price = 'PRICE',
    SaleDate = 'SALE_DATE',
    Title = 'TITLE',
    Unspecified = 'UNSPECIFIED',
}

export type ArtListingCuratedListItemSortByClause_Input = {
    direction?: InputMaybe<SortDirection>
    fields?: InputMaybe<Array<ArtListingCuratedListItemSortBy>>
}

export enum ArtListingCuratedListOrigin {
    CreatedByCustomer = 'CREATED_BY_CUSTOMER',
    Legacy = 'LEGACY',
    Unspecified = 'UNSPECIFIED',
}

export enum ArtListingCuratedListSortBy {
    DateAdded = 'DATE_ADDED',
    DateModified = 'DATE_MODIFIED',
    Title = 'TITLE',
    Unspecified = 'UNSPECIFIED',
}

export type ArtListingCuratedListSortByClause_Input = {
    direction?: InputMaybe<SortDirection>
    fields?: InputMaybe<Array<ArtListingCuratedListSortBy>>
}

export enum ArtListingCuratedListStatus {
    Active = 'ACTIVE',
    ScheduledForDeletion = 'SCHEDULED_FOR_DELETION',
    Unspecified = 'UNSPECIFIED',
}

export type ArtListingCuratedListSummary = {
    __typename: 'ArtListingCuratedListSummary'
    count: Scalars['Int']
    dateCreated: Scalars['DateTime']
    dateModified: Scalars['DateTime']
    featuredImage: Maybe<Image>
    id: Scalars['ID']
    origin: ArtListingCuratedListOrigin
    status: ArtListingCuratedListStatus
    title: Scalars['String']
}

export type ArtListingDetailsArtwork = {
    __typename: 'ArtListingDetailsArtwork'
    artworkUri: Maybe<Scalars['String']>
    castYear: Maybe<WorkYear>
    catalogueNote: Maybe<Scalars['String']>
    category: Array<Category>
    classification: Array<Specialty>
    creationEraFrom: Era
    creationEraTo: Era
    creationYearFrom: Maybe<Scalars['Int']>
    creationYearQualifier: Maybe<Scalars['String']>
    creationYearTo: Maybe<Scalars['Int']>
    creator: Maybe<Creator>
    dimensions: Array<Dimensions>
    edition: Maybe<Scalars['String']>
    exhibition: Maybe<Scalars['String']>
    isEdition: Maybe<Scalars['Boolean']>
    isSigned: Maybe<Scalars['Boolean']>
    literature: Maybe<Scalars['String']>
    markings: Maybe<Markings>
    materials: Array<Specialty>
    mediumRaw: Maybe<Scalars['String']>
    monographUrl: Maybe<Scalars['String']>
    movement: Array<Specialty>
    objectType: Array<Specialty>
    period: Array<Specialty>
    provenance: Maybe<Scalars['String']>
    publisher: Maybe<Scalars['String']>
    raisonneAuthor: Maybe<Scalars['String']>
    raisonneNumber: Maybe<Scalars['String']>
    region: Array<Specialty>
    subjectMatter: Array<Specialty>
}

export type ArtListingDetailsRequest_Input = {
    id: Scalars['ID']
}

export type ArtListingDetailsResults = {
    __typename: 'ArtListingDetailsResults'
    auctionHouse: Maybe<AuctionHouse>
    featuredImage: Maybe<Image>
    id: Scalars['ID']
    images: Array<Image>
    lot: Maybe<Lot>
    prices: Array<ArtListingSalePrice>
    relatedSales: Array<RelatedSaleOverview>
    saleCode: Maybe<Scalars['String']>
    saleDates: Array<CustomDate>
    saleDescription: Maybe<Scalars['String']>
    saleId: Maybe<Scalars['ID']>
    saleLocation: Maybe<Location>
    saleName: Maybe<Scalars['String']>
    saleOriginalUrl: Maybe<Scalars['String']>
    transactionStatusClarification: Maybe<Scalars['String']>
}

export type ArtListingFilters_Input = {
    artListingId?: InputMaybe<EntityFilter_Input>
    attribute?: InputMaybe<EntityFilter_Input>
    auctionHouse?: InputMaybe<EntityFilter_Input>
    birthYear?: InputMaybe<YearFilter_Input>
    category?: InputMaybe<EntityFilter_Input>
    classification?: InputMaybe<EntityFilter_Input>
    creationYear?: InputMaybe<YearFilter_Input>
    creator?: InputMaybe<EntityFilter_Input>
    creatorNationality?: InputMaybe<EntityFilter_Input>
    currency?: InputMaybe<EntityFilter_Input>
    deathYear?: InputMaybe<YearFilter_Input>
    dimension?: InputMaybe<Array<DimensionFilter_Input>>
    hasPhoto?: InputMaybe<BoolFilter_Input>
    includeArtistModifiers?: InputMaybe<BoolFilter_Input>
    isEdition?: InputMaybe<BoolFilter_Input>
    isInscribed?: InputMaybe<BoolFilter_Input>
    isSigned?: InputMaybe<BoolFilter_Input>
    isStamped?: InputMaybe<BoolFilter_Input>
    lastUpdated?: InputMaybe<Array<DateTimeFilter_Input>>
    listedDate?: InputMaybe<Array<DateTimeFilter_Input>>
    lotTitle?: InputMaybe<EntityFilter_Input>
    material?: InputMaybe<EntityFilter_Input>
    media?: InputMaybe<EntityFilter_Input>
    medium?: InputMaybe<EntityFilter_Input>
    mediumRaw?: InputMaybe<EntityFilter_Input>
    movement?: InputMaybe<EntityFilter_Input>
    objectType?: InputMaybe<EntityFilter_Input>
    period?: InputMaybe<EntityFilter_Input>
    price?: InputMaybe<PriceFilter_Input>
    region?: InputMaybe<EntityFilter_Input>
    sale?: InputMaybe<EntityFilter_Input>
    saleDate?: InputMaybe<Array<DateFilter_Input>>
    simplifiedTransactionStatus?: InputMaybe<EntityFilter_Input>
    subjectMatter?: InputMaybe<EntityFilter_Input>
    substrate?: InputMaybe<EntityFilter_Input>
    transactionStatus?: InputMaybe<EntityFilter_Input>
}

export type ArtListingFinalPrice = {
    __typename: 'ArtListingFinalPrice'
    differenceFromClosestEstimate: Maybe<Scalars['Float']>
    price: Scalars['Float']
    priceType: Maybe<SalePriceType>
}

export type ArtListingPreferences_Input = {
    preferredCurrencies?: InputMaybe<Array<CurrencyCode_Input>>
}

export type ArtListingSalePrice = {
    __typename: 'ArtListingSalePrice'
    currencyCode: Entity
    customPrecision: Maybe<Scalars['Int']>
    estimatedPriceHigh: Maybe<Scalars['Float']>
    estimatedPriceLow: Maybe<Scalars['Float']>
    finalPrices: Array<ArtListingFinalPrice>
    isOriginalCurrency: Maybe<Scalars['Boolean']>
}

export type ArtListingSummary = {
    __typename: 'ArtListingSummary'
    artListingDetailId: Scalars['ID']
    auctionHouse: Maybe<AuctionHouseSummary>
    featuredImage: Maybe<Image>
    id: Scalars['ID']
    lastUpdated: Scalars['DateTime']
    listedDate: Scalars['DateTime']
    lot: Maybe<LotSummary>
    prices: Array<ArtListingSalePrice>
    saleCountries: Array<Scalars['String']>
    saleDates: Array<CustomDate>
    saleId: Maybe<Scalars['ID']>
    saleName: Maybe<Scalars['String']>
    transactionStatusClarification: Maybe<Scalars['String']>
}

export type ArtPriceIndex = {
    __typename: 'ArtPriceIndex'
    name: Scalars['String']
    results: Array<ArtPriceIndexPoint>
}

export type ArtPriceIndexAutoRange_Input = {
    fullRangeYears: Scalars['Int']
    initialRangeEnd?: InputMaybe<Scalars['DateTime']>
    initialRangeStart?: InputMaybe<Scalars['DateTime']>
    interval: ArtPriceIndexInterval
}

export type ArtPriceIndexCustomRange_Input = {
    end?: InputMaybe<Scalars['DateTime']>
    interval: ArtPriceIndexInterval
    start?: InputMaybe<Scalars['DateTime']>
}

export enum ArtPriceIndexInterval {
    Biannual = 'BIANNUAL',
    Unspecified = 'UNSPECIFIED',
}

export type ArtPriceIndexPoint = {
    __typename: 'ArtPriceIndexPoint'
    date: Scalars['DateTime']
    distanceFromBaseline: Scalars['Float']
    value: Scalars['Float']
}

export type ArtPriceIndexRequest_Input = {
    creatorId?: InputMaybe<Scalars['ID']>
    name?: InputMaybe<Scalars['String']>
}

export type ArtPriceIndices = {
    __typename: 'ArtPriceIndices'
    baseline: Scalars['Float']
    primaryIndex: ArtPriceIndex
    remainingIndices: Array<ArtPriceIndex>
}

export type ArtPriceIndicesTimeframe = {
    __typename: 'ArtPriceIndicesTimeframe'
    end: Scalars['DateTime']
    start: Scalars['DateTime']
}

export type Artist = {
    __typename: 'Artist'
    aliases: Array<Scalars['String']>
    birthLocation: Maybe<Location>
    birthYear: Maybe<Scalars['Int']>
    birthYearEra: Maybe<Era>
    birthYearQualifier: Maybe<Scalars['String']>
    causeOfDeath: Maybe<Scalars['String']>
    culturalIdentification: Maybe<Scalars['String']>
    deathLocation: Maybe<Location>
    deathYear: Maybe<Scalars['Int']>
    deathYearEra: Maybe<Era>
    deathYearQualifier: Maybe<Scalars['String']>
    enabled: Maybe<Scalars['Boolean']>
    firstName: Maybe<Scalars['String']>
    gender: Maybe<Scalars['String']>
    homeLocation: Maybe<Location>
    id: Scalars['ID']
    isSchoolOf: Maybe<Scalars['Boolean']>
    jobTitle: Maybe<Scalars['String']>
    lastName: Maybe<Scalars['String']>
    nationalities: Array<Scalars['String']>
    notes: Maybe<Scalars['String']>
    sortName: Maybe<Scalars['String']>
    uri: Maybe<Scalars['String']>
}

export type ArtistContribution = {
    __typename: 'ArtistContribution'
    content: Scalars['String']
    dateSubmitted: Scalars['DateTime']
    entityId: Scalars['ID']
    id: Scalars['ID']
    sources: Array<Scalars['String']>
    type: ArtistContributionType
}

export type ArtistContributionRequest_Input = {
    artistId: ArtistIdentifier_Input
    contributions?: InputMaybe<Array<ArtistContribution_Input>>
}

export type ArtistContributionResponse = {
    __typename: 'ArtistContributionResponse'
    contributionIds: Array<Scalars['ID']>
    errors: Array<Scalars['String']>
    successful: Scalars['Boolean']
}

export enum ArtistContributionType {
    Biography = 'BIOGRAPHY',
    Unspecified = 'UNSPECIFIED',
}

export type ArtistContribution_Input = {
    content: Scalars['String']
    dateSubmitted: Scalars['DateTime']
    entityId: Scalars['ID']
    id: Scalars['ID']
    sources?: InputMaybe<Array<Scalars['String']>>
    type: ArtistContributionType
}

export type ArtistIdentifier_Input = {
    artistId: Scalars['ID']
}

export type ArtistSummary = {
    __typename: 'ArtistSummary'
    aliases: Array<Scalars['String']>
    birthYear: Maybe<Scalars['Int']>
    birthYearEra: Maybe<Era>
    birthYearQualifier: Maybe<Scalars['String']>
    deathYear: Maybe<Scalars['Int']>
    deathYearEra: Maybe<Era>
    deathYearQualifier: Maybe<Scalars['String']>
    id: Scalars['ID']
    name: Scalars['String']
    nationalities: Array<Entity>
    uri: Maybe<Scalars['String']>
}

export type Artwork = {
    __typename: 'Artwork'
    creatorId: Maybe<Scalars['ID']>
    creatorMovement: Array<Entity>
    creatorName: Maybe<Scalars['String']>
    creatorUri: Maybe<Scalars['String']>
    dimensions: Maybe<Dimensions>
    edition: Maybe<Scalars['String']>
    id: Scalars['ID']
    medium: Array<Scalars['String']>
    objectType: Array<Entity>
    timelineEvents: Array<ArtworkTimelineEvent>
    title: Scalars['String']
    uri: Maybe<Scalars['String']>
}

export type ArtworkFilters_Input = {
    artworkCreationYear?: InputMaybe<YearFilter_Input>
    artworkTitle?: InputMaybe<Scalars['String']>
    creator?: InputMaybe<EntityFilter_Input>
    creatorBirthYear?: InputMaybe<YearFilter_Input>
    creatorDeathYear?: InputMaybe<YearFilter_Input>
    creatorMovement?: InputMaybe<EntityFilter_Input>
    creatorNationality?: InputMaybe<EntityFilter_Input>
    dimension?: InputMaybe<DimensionFilter_Input>
    includeArtistModifiers?: InputMaybe<BoolFilter_Input>
    listings?: InputMaybe<ArtworkListingGroupFilter_Input>
    mostRecentEvent?: InputMaybe<DateTimeFilter_Input>
    priceGrowth?: InputMaybe<NumericRange_Input>
}

export enum ArtworkListedBy {
    AuctionHouse = 'AUCTION_HOUSE',
    Gallery = 'GALLERY',
    Unspecified = 'UNSPECIFIED',
}

export type ArtworkListingGroupFilter_Input = {
    auctionHouse?: InputMaybe<EntityFilter_Input>
    count?: InputMaybe<NumericRange_Input>
    gallery?: InputMaybe<EntityFilter_Input>
    listedBy?: InputMaybe<Array<ArtworkListedBy>>
}

export enum ArtworkSortBy {
    ArtworkCreationYear = 'ARTWORK_CREATION_YEAR',
    ArtworkTitle = 'ARTWORK_TITLE',
    EventCount = 'EVENT_COUNT',
    HighestListedPrice = 'HIGHEST_LISTED_PRICE',
    ListingCount = 'LISTING_COUNT',
    MostRecentEventDate = 'MOST_RECENT_EVENT_DATE',
    PriceGrowth = 'PRICE_GROWTH',
    Relevancy = 'RELEVANCY',
    Unspecified = 'UNSPECIFIED',
}

export type ArtworkSortByClause_Input = {
    direction?: InputMaybe<SortDirection>
    field?: InputMaybe<ArtworkSortBy>
}

export type ArtworkSummary = {
    __typename: 'ArtworkSummary'
    category: Array<Entity>
    classification: Array<Entity>
    creationEraFrom: Maybe<Era>
    creationEraTo: Maybe<Era>
    creationYearFrom: Maybe<Scalars['Int']>
    creationYearFromQualifier: Maybe<Scalars['String']>
    creationYearTo: Maybe<Scalars['Int']>
    creator: Maybe<CreatorSummary>
    dimensions: Array<Dimensions>
    edition: Maybe<Scalars['String']>
    isEdition: Maybe<Scalars['Boolean']>
    isInscribed: Maybe<Scalars['Boolean']>
    isSigned: Maybe<Scalars['Boolean']>
    isStamped: Maybe<Scalars['Boolean']>
    materials: Array<Entity>
    mediumRaw: Maybe<Scalars['String']>
    movement: Array<Entity>
    objectType: Array<Entity>
    period: Array<Entity>
    region: Array<Entity>
    subjectMatter: Array<Entity>
    title: Maybe<Scalars['String']>
}

export type ArtworkTimelineEvent = {
    __typename: 'ArtworkTimelineEvent'
    date: CustomDateTime
    metadata: Maybe<ArtworkTimelineEventMetadata>
    type: ArtworkTimelineEventType
}

export type ArtworkTimelineEventMetadata = {
    __typename: 'ArtworkTimelineEventMetadata'
    price: Maybe<ArtListingSalePrice>
    publicListingDomain: Maybe<PublicArtListingDomain>
    publicListingId: Maybe<Scalars['ID']>
    uri: Maybe<Scalars['String']>
}

export enum ArtworkTimelineEventType {
    Created = 'CREATED',
    Destroyed = 'DESTROYED',
    Listed = 'LISTED',
    Stolen = 'STOLEN',
    Unspecified = 'UNSPECIFIED',
}

export type AuctionHouse = {
    __typename: 'AuctionHouse'
    homepageUrl: Maybe<Scalars['String']>
    id: Scalars['ID']
    key: Maybe<Scalars['String']>
    location: Location
    name: Maybe<Scalars['String']>
}

export type AuctionHouseSummary = {
    __typename: 'AuctionHouseSummary'
    homepageUrl: Maybe<Scalars['String']>
    id: Scalars['ID']
    key: Maybe<Scalars['String']>
    location: Array<Location>
    name: Scalars['String']
}

export type BoolFilter_Input = {
    includeUnknown?: InputMaybe<Scalars['Boolean']>
    values?: InputMaybe<Array<Scalars['Boolean']>>
}

export type Category = {
    __typename: 'Category'
    id: Scalars['Int']
    name: Maybe<Scalars['String']>
    uri: Maybe<Scalars['String']>
}

export type CloneArtListingCuratedListRequest_Input = {
    existingListId: Scalars['ID']
}

export type CloneArtListingCuratedListResponse = {
    __typename: 'CloneArtListingCuratedListResponse'
    result: ArtListingCuratedList
}

export enum ConnectivityState {
    Connecting = 'CONNECTING',
    Idle = 'IDLE',
    Ready = 'READY',
    Shutdown = 'SHUTDOWN',
    TransientFailure = 'TRANSIENT_FAILURE',
}

export enum ContributionSortBy {
    LastSubmitted = 'LAST_SUBMITTED',
    Size = 'SIZE',
    Unspecified = 'UNSPECIFIED',
}

export type ContributionSortByClause_Input = {
    direction?: InputMaybe<SortDirection>
    fields?: InputMaybe<Array<ContributionSortBy>>
}

export type Country = {
    __typename: 'Country'
    id: Scalars['ID']
    name: Scalars['String']
}

export type CreateArtListingCuratedListRequest_Input = {
    itemIds?: InputMaybe<Array<Scalars['ID']>>
    title: Scalars['String']
}

export type CreateArtListingCuratedListResponse = {
    __typename: 'CreateArtListingCuratedListResponse'
    result: ArtListingCuratedList
}

export type Creator = {
    __typename: 'Creator'
    artists: Array<Artist>
    creatorType: CreatorType
    id: Maybe<Scalars['Int']>
    modifier: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    relatedArtists: Array<RelatedArtistOverview>
    uri: Maybe<Scalars['String']>
}

export type CreatorSummary = {
    __typename: 'CreatorSummary'
    aliases: Array<Scalars['String']>
    artists: Array<ArtistSummary>
    creatorType: Maybe<CreatorType>
    id: Scalars['ID']
    modifier: Maybe<Scalars['String']>
    name: Scalars['String']
    uri: Maybe<Scalars['String']>
}

export enum CreatorType {
    Artist = 'ARTIST',
    Collaboration = 'COLLABORATION',
    Manufacturer = 'MANUFACTURER',
    Unspecified = 'UNSPECIFIED',
}

export enum CurrencyCodeStandard {
    Crypto = 'CRYPTO',
    Iso_4217 = 'ISO_4217',
}

export type CurrencyCode_Input = {
    code: Scalars['String']
    standard: CurrencyCodeStandard
}

export type CurrentUserResult = {
    __typename: 'CurrentUserResult'
    isLoggedIn: Scalars['Boolean']
    isReauthenticationRequired: Scalars['Boolean']
    user: Maybe<User>
}

export type CustomDate = {
    __typename: 'CustomDate'
    day: Maybe<Scalars['Int']>
    month: Maybe<Scalars['Int']>
    year: Scalars['Int']
}

export type CustomDateTime = {
    __typename: 'CustomDateTime'
    day: Maybe<Scalars['Int']>
    hour: Maybe<Scalars['Int']>
    minute: Maybe<Scalars['Int']>
    month: Maybe<Scalars['Int']>
    second: Maybe<Scalars['Int']>
    timeZone: Maybe<Scalars['String']>
    year: Maybe<Scalars['Int']>
}

export type CustomDate_Input = {
    day?: InputMaybe<Int32Value_Input>
    month?: InputMaybe<Int32Value_Input>
    year: Scalars['Int']
}

export type DateFilter_Input = {
    includeUnknown?: InputMaybe<Scalars['Boolean']>
    lowerBound?: InputMaybe<CustomDate_Input>
    upperBound?: InputMaybe<CustomDate_Input>
}

export enum DateInterval {
    Day = 'DAY',
    Month = 'MONTH',
    Year = 'YEAR',
}

export type DateTimeFilter_Input = {
    lowerBound?: InputMaybe<Scalars['DateTime']>
    upperBound?: InputMaybe<Scalars['DateTime']>
}

export type DimensionAggregationResults = {
    __typename: 'DimensionAggregationResults'
    count: Scalars['BigInt']
    countUnknown: Scalars['BigInt']
    results: Array<DimensionBucketAggregationResult>
}

export type DimensionBucketAggregationResult = {
    __typename: 'DimensionBucketAggregationResult'
    bucketName: Scalars['String']
    count: Scalars['BigInt']
    countUnknown: Scalars['BigInt']
    depth: Maybe<DimensionRangeResult>
    height: Maybe<DimensionRangeResult>
    longestEdge: Maybe<DimensionRangeResult>
    unit: DimensionUnit
    width: Maybe<DimensionRangeResult>
}

export type DimensionBucketAggregation_Input = {
    bucketName?: InputMaybe<Scalars['String']>
    depth?: InputMaybe<NumericRange_Input>
    height?: InputMaybe<NumericRange_Input>
    includeUnknown?: InputMaybe<Scalars['Boolean']>
    longestEdge?: InputMaybe<NumericRange_Input>
    unit: DimensionUnit
    width?: InputMaybe<NumericRange_Input>
}

export type DimensionFilter_Input = {
    depth?: InputMaybe<NumericRange_Input>
    height?: InputMaybe<NumericRange_Input>
    longestEdge?: InputMaybe<NumericRange_Input>
    unit: DimensionUnit
    width?: InputMaybe<NumericRange_Input>
}

export type DimensionRangeResult = {
    __typename: 'DimensionRangeResult'
    countUnknown: Scalars['BigInt']
    range: Maybe<NumericRange>
}

export enum DimensionUnit {
    Cm = 'CM',
    In = 'IN',
    Mm = 'MM',
    Unspecified = 'UNSPECIFIED',
}

export type Dimensions = {
    __typename: 'Dimensions'
    depth: Maybe<Scalars['Float']>
    height: Maybe<Scalars['Float']>
    unit: DimensionUnit
    width: Maybe<Scalars['Float']>
}

export type DoubleValue_Input = {
    value: Scalars['Float']
}

export type EchoInputRequest = {
    __typename: 'EchoInputRequest'
    price: PlayGroundPriceFilter
}

export type EchoInputRequest_Input = {
    price: PlayGroundPriceFilter_Input
}

export type EchoUserInfoResponse = {
    __typename: 'EchoUserInfoResponse'
    userInfo: Scalars['String']
}

export type Entity = {
    __typename: 'Entity'
    id: Scalars['ID']
    key: Maybe<Scalars['String']>
    value: Maybe<Scalars['String']>
}

export type EntityAggregationResults = {
    __typename: 'EntityAggregationResults'
    count: Maybe<Scalars['BigInt']>
    countUnknown: Maybe<Scalars['BigInt']>
    results: Array<AggregationResult>
}

export type EntityFilter_Input = {
    excludeKeys?: InputMaybe<Array<Scalars['String']>>
    includeUnknown?: InputMaybe<Scalars['Boolean']>
    keys?: InputMaybe<Array<Scalars['String']>>
    searchTerms?: InputMaybe<Array<Scalars['String']>>
}

export enum EntityType {
    AuctionHouse = 'AUCTION_HOUSE',
    Creator = 'CREATOR',
    Movement = 'MOVEMENT',
    Unspecified = 'UNSPECIFIED',
}

export enum Era {
    Bce = 'BCE',
    Ce = 'CE',
    Unspecified = 'UNSPECIFIED',
}

export type FlagForMisinformationRequest_Input = {
    feedback: Scalars['String']
    lotId: Scalars['ID']
    userId: Scalars['ID']
}

export type FlagForMisinformationResponse = {
    __typename: 'FlagForMisinformationResponse'
    isReceived: Scalars['Boolean']
}

export type FloatValue_Input = {
    value: Scalars['Float']
}

export type GetArtListingByPublicIdRequest_Input = {
    id: Scalars['ID']
    publicListingDomain: PublicArtListingDomain
    subscriptionId: Scalars['ID']
    subscriptionScope: SubscriptionScope
}

export type GetArtListingCuratedListByIdRequest_Input = {
    freeformTextQuery?: InputMaybe<Scalars['String']>
    id: Scalars['ID']
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sortBy?: InputMaybe<Array<ArtListingCuratedListItemSortByClause_Input>>
}

export type GetArtListingCuratedListByIdResponse = {
    __typename: 'GetArtListingCuratedListByIdResponse'
    result: Maybe<ArtListingCuratedList>
}

export type GetArtListingCuratedListsRequest_Input = {
    freeformTextQuery?: InputMaybe<Scalars['String']>
    origins?: InputMaybe<Array<ArtListingCuratedListOrigin>>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sortBy?: InputMaybe<Array<ArtListingCuratedListSortByClause_Input>>
}

export type GetArtListingCuratedListsResponse = {
    __typename: 'GetArtListingCuratedListsResponse'
    count: Scalars['BigInt']
    results: Array<ArtListingCuratedListSummary>
}

export type GetArtPriceIndicesRequest_Input = {
    autoRange?: InputMaybe<ArtPriceIndexAutoRange_Input>
    customRange?: InputMaybe<ArtPriceIndexCustomRange_Input>
    request?: InputMaybe<Array<ArtPriceIndexRequest_Input>>
}

export type GetArtPriceIndicesResponse = {
    __typename: 'GetArtPriceIndicesResponse'
    result: Maybe<ArtPriceIndices>
    resultTimeframe: Maybe<ArtPriceIndicesTimeframe>
}

export type GetArtworksRequest_Input = {
    filters?: InputMaybe<Array<ArtworkFilters_Input>>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sortBy?: InputMaybe<Array<ArtworkSortByClause_Input>>
    subscriptionId?: InputMaybe<Scalars['ID']>
    subscriptionScope?: InputMaybe<SubscriptionScope>
}

export type GetArtworksResponse = {
    __typename: 'GetArtworksResponse'
    results: Array<Artwork>
}

export type GetContributionsRequestFilter_Input = {
    artistId: ArtistIdentifier_Input
    typeFilter?: InputMaybe<Array<ArtistContributionType>>
}

export type GetContributionsRequest_Input = {
    filters?: InputMaybe<Array<GetContributionsRequestFilter_Input>>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sortBy?: InputMaybe<Array<ContributionSortByClause_Input>>
}

export type GetContributionsResponse = {
    __typename: 'GetContributionsResponse'
    results: Array<ArtistContribution>
}

export type GetCountriesWithFinancingResponse = {
    __typename: 'GetCountriesWithFinancingResponse'
    results: Array<Country>
}

export type GetNewsArticlesRequest_Filters = {
    auctionHouse?: InputMaybe<EntityFilter_Input>
    creator?: InputMaybe<EntityFilter_Input>
    gallery?: InputMaybe<EntityFilter_Input>
}

export type GetNewsArticlesRequest_Input = {
    filters?: InputMaybe<GetNewsArticlesRequest_Filters>
    page: Scalars['Int']
    pageSize: Scalars['Int']
}

export type GetNewsArticlesResponse = {
    __typename: 'GetNewsArticlesResponse'
    results: Array<NewsArticle>
}

export type GetNotableArtListingsRequest_Input = {
    filters?: InputMaybe<NotableArtListingFilters_Input>
    limit?: InputMaybe<Scalars['Int']>
    minimumTotalListings?: InputMaybe<Int32Value_Input>
    notableFor: NotableFor
}

export type GetNotableArtListingsResponse = {
    __typename: 'GetNotableArtListingsResponse'
    count: Maybe<Scalars['BigInt']>
    results: Array<ArtListingSummary>
}

export type GetSearchFiltersForComparableWorksRequest_Input = {
    artListingDetailsId: Scalars['ID']
}

export type GetSearchFiltersForComparableWorksResponse = {
    __typename: 'GetSearchFiltersForComparableWorksResponse'
    filters: Array<PreparedSearchFilter>
}

export type GetSearchFiltersForPublicArtListingRequest_Input = {
    publicArtListingId?: InputMaybe<Scalars['ID']>
}

export type GetSearchFiltersForPublicArtListingResponse = {
    __typename: 'GetSearchFiltersForPublicArtListingResponse'
    filters: Array<PreparedSearchFilter>
}

export type GetSearchInsightsRequest_Input = {
    autoRange?: InputMaybe<SearchInsightsAutoRange_Input>
    calendarInterval: InsightsInterval
    configuration?: InputMaybe<SearchInsightsConfiguration_Input>
    customRange?: InputMaybe<SearchInsightsCustomRange_Input>
    filters?: InputMaybe<ArtListingFilters_Input>
    freeformTextQuery?: InputMaybe<Scalars['String']>
    insightTypes?: InputMaybe<Array<SearchInsightsType>>
}

export type GetSearchInsightsResponse = {
    __typename: 'GetSearchInsightsResponse'
    isApplicable: Scalars['Boolean']
    offeredVsSold: Maybe<OfferedVsSoldResults>
    priceDistribution: Maybe<PriceDistributionResults>
    resultTimeframe: Maybe<SearchInsightsResultTimeframe>
    statistics: Maybe<SearchStatistics>
    totalSales: Maybe<TotalSalesResults>
}

export type GetSubscriptionByIdRequest_Input = {
    id: Scalars['ID']
    scope: SubscriptionScope
}

export type GetSuggestionsRequest_Input = {
    filters?: InputMaybe<ArtListingFilters_Input>
    freeformTextQuery: Scalars['String']
    limit?: InputMaybe<Scalars['Int']>
}

export type GetSuggestionsResponse = {
    __typename: 'GetSuggestionsResponse'
    count: Maybe<Scalars['BigInt']>
    results: Array<AggregationResult>
}

export type GetUserSubscriptionsRequest_Input = {
    scopes?: InputMaybe<Array<SubscriptionScope>>
    statusFilter?: InputMaybe<Array<SubscriptionStatus>>
}

export type GlobalSearchHint = SearchHint & {
    __typename: 'GlobalSearchHint'
    description: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    rank: Scalars['Int']
    url: Scalars['AbsoluteUrl']
}

export type GroupByAggregation_Input = {
    limit?: InputMaybe<Scalars['Int']>
}

export type Image = {
    __typename: 'Image'
    alt: Maybe<Scalars['String']>
    baseImageUrl: Scalars['String']
    title: Maybe<Scalars['String']>
}

export type InquireAboutFinancingRequest_Input = {
    artworkCountryId: Scalars['ID']
    customerCountryId?: InputMaybe<Scalars['ID']>
    customerEmail: Scalars['String']
    customerName: Scalars['String']
    customerPhone: Scalars['String']
    customerPhoneExt: Scalars['String']
    messageBody: Scalars['String']
    multipleArtworks?: InputMaybe<Scalars['Boolean']>
    requestedLoanAmount: Scalars['String']
}

export type InquireAboutFinancingResponse = {
    __typename: 'InquireAboutFinancingResponse'
    errors: Array<MutationError>
    id: Scalars['ID']
    message: Scalars['String']
}

export enum InsightsInterval {
    Month = 'MONTH',
    Quarter = 'QUARTER',
    Unspecified = 'UNSPECIFIED',
    Year = 'YEAR',
}

export type Int32Value_Input = {
    value: Scalars['Int']
}

export type Int64Value_Input = {
    value: Scalars['BigInt']
}

export type Location = {
    __typename: 'Location'
    addressLine1: Maybe<Scalars['String']>
    addressLine2: Maybe<Scalars['String']>
    city: Maybe<Scalars['String']>
    country: Maybe<Scalars['String']>
    latitude: Maybe<Scalars['Float']>
    longitude: Maybe<Scalars['Float']>
    state: Maybe<Scalars['String']>
}

export type Lot = {
    __typename: 'Lot'
    artnetLotId: Maybe<Scalars['Int']>
    artworks: Array<ArtListingDetailsArtwork>
    catalogLotNumber: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    externalUrl: Maybe<Scalars['String']>
    lotNumber: Maybe<Scalars['Int']>
    title: Maybe<Scalars['String']>
}

export type LotSummary = {
    __typename: 'LotSummary'
    artnetLotId: Maybe<Scalars['Int']>
    artworks: Array<ArtworkSummary>
    catalogLotNumber: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    uri: Maybe<Scalars['String']>
}

export type Markings = {
    __typename: 'Markings'
    isInscribed: Maybe<Scalars['Boolean']>
    isPlateSigned: Maybe<Scalars['Boolean']>
    isSigned: Maybe<Scalars['Boolean']>
    isStamped: Maybe<Scalars['Boolean']>
}

export type Mutation = {
    __typename: 'Mutation'
    addArtListingCuratedListItems: AddArtListingCuratedListItemsResponse
    cloneArtListingCuratedList: CloneArtListingCuratedListResponse
    contribute: ArtistContributionResponse
    createArtListingCuratedList: CreateArtListingCuratedListResponse
    flagForMisinformation: FlagForMisinformationResponse
    inquireAboutFinancing: InquireAboutFinancingResponse
    removeArtListingCuratedListItems: RemoveArtListingCuratedListItemsResponse
    repositionArtListingCuratedListItems: RepositionArtListingCuratedListItemsResponse
    updateArtListingCuratedList: UpdateArtListingCuratedListResponse
    updateArtListingCuratedListStatus: UpdateArtListingCuratedListStatusResponse
}

export type MutationAddArtListingCuratedListItemsArgs = {
    input: InputMaybe<AddArtListingCuratedListItemsRequest_Input>
}

export type MutationCloneArtListingCuratedListArgs = {
    input: InputMaybe<CloneArtListingCuratedListRequest_Input>
}

export type MutationContributeArgs = {
    input: InputMaybe<ArtistContributionRequest_Input>
}

export type MutationCreateArtListingCuratedListArgs = {
    input: InputMaybe<CreateArtListingCuratedListRequest_Input>
}

export type MutationFlagForMisinformationArgs = {
    input: InputMaybe<FlagForMisinformationRequest_Input>
}

export type MutationInquireAboutFinancingArgs = {
    input: InputMaybe<InquireAboutFinancingRequest_Input>
}

export type MutationRemoveArtListingCuratedListItemsArgs = {
    input: InputMaybe<RemoveArtListingCuratedListItemsRequest_Input>
}

export type MutationRepositionArtListingCuratedListItemsArgs = {
    input: InputMaybe<RepositionArtListingCuratedListItemsRequest_Input>
}

export type MutationUpdateArtListingCuratedListArgs = {
    input: InputMaybe<UpdateArtListingCuratedListRequest_Input>
}

export type MutationUpdateArtListingCuratedListStatusArgs = {
    input: InputMaybe<UpdateArtListingCuratedListStatusRequest_Input>
}

export type MutationError = {
    __typename: 'MutationError'
    code: Scalars['String']
    message: Scalars['String']
}

export type NewsArticle = {
    __typename: 'NewsArticle'
    author: Scalars['String']
    authorUrl: Scalars['AbsoluteUrl']
    categoryName: Scalars['String']
    categoryUrl: Scalars['String']
    date: Scalars['Date']
    featuredImage: Array<Maybe<NewsImage>>
    id: Scalars['ID']
    title: Scalars['String']
    url: Scalars['AbsoluteUrl']
}

export type NewsImage = {
    __typename: 'NewsImage'
    aspectRatio: Scalars['String']
    url: Scalars['AbsoluteUrl']
}

export type NewsSearchHint = SearchHint & {
    __typename: 'NewsSearchHint'
    author: Scalars['String']
    date: Scalars['DateTime']
    description: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    url: Scalars['AbsoluteUrl']
}

export type NotableArtListingFilters_Input = {
    creator?: InputMaybe<EntityFilter_Input>
}

export enum NotableFor {
    HighestPrice = 'HIGHEST_PRICE',
    Unspecified = 'UNSPECIFIED',
}

export type NumericRange = {
    __typename: 'NumericRange'
    includeUnknown: Maybe<Scalars['Boolean']>
    lowerBound: Maybe<Scalars['Float']>
    upperBound: Maybe<Scalars['Float']>
}

export type NumericRange_Input = {
    includeUnknown?: InputMaybe<Scalars['Boolean']>
    lowerBound?: InputMaybe<DoubleValue_Input>
    upperBound?: InputMaybe<DoubleValue_Input>
}

export type OfferedVsSoldPoint = {
    __typename: 'OfferedVsSoldPoint'
    inRequestedRange: Scalars['Boolean']
    lotsOffered: Scalars['BigInt']
    lotsSold: Scalars['BigInt']
    startDate: Scalars['DateTime']
}

export type OfferedVsSoldResults = {
    __typename: 'OfferedVsSoldResults'
    results: Array<OfferedVsSoldPoint>
}

export enum PdbAccessResult {
    NoAccess = 'NO_ACCESS',
    PayPerSearch = 'PAY_PER_SEARCH',
    Unlimited = 'UNLIMITED',
    Unspecified = 'UNSPECIFIED',
}

export type PdbProductPermissions = {
    __typename: 'PdbProductPermissions'
    decArt: PdbAccessResult
    fineArt: PdbAccessResult
}

export type PdbSubscription = {
    __typename: 'PdbSubscription'
    expiry: Maybe<Scalars['DateTime']>
    id: Scalars['ID']
    invalidityReasons: Array<PdbSubscriptionInvalidityReason>
    isValid: Scalars['Boolean']
    quota: PdbSubscriptionQuota
    title: Scalars['String']
    type: PdbSubscriptionType
    warnings: Array<PdbSubscriptionWarning>
}

export enum PdbSubscriptionInvalidityReason {
    Expired = 'EXPIRED',
    Inactive = 'INACTIVE',
    OutOfCredits = 'OUT_OF_CREDITS',
    Suspended = 'SUSPENDED',
    Unknown = 'UNKNOWN',
}

export type PdbSubscriptionQuota = {
    __typename: 'PdbSubscriptionQuota'
    numSearchesRemaining: Maybe<Scalars['Int']>
    quotaType: PdbSubscriptionQuotaType
}

export enum PdbSubscriptionQuotaType {
    PayPerSearch = 'PAY_PER_SEARCH',
    PayPerSearchWithoutVisiblity = 'PAY_PER_SEARCH_WITHOUT_VISIBLITY',
    Unspecified = 'UNSPECIFIED',
}

export enum PdbSubscriptionType {
    DecArt = 'DEC_ART',
    FineArt = 'FINE_ART',
    Unspecified = 'UNSPECIFIED',
}

export enum PdbSubscriptionWarning {
    CloseToExpiry = 'CLOSE_TO_EXPIRY',
    None = 'NONE',
    NumberOfSearchesLow = 'NUMBER_OF_SEARCHES_LOW',
}

export type PlayGroundPriceFilter = {
    __typename: 'PlayGroundPriceFilter'
    range: PlayGroundPriceRange
}

export type PlayGroundPriceFilter_Input = {
    range: PlayGroundPriceRange_Input
}

export type PlayGroundPriceRange = {
    __typename: 'PlayGroundPriceRange'
    valueDouble: Scalars['Float']
    valueFloat: Scalars['Float']
    valueInt: Scalars['BigInt']
    valueLong: Scalars['BigInt']
    valueLongNotnull: Scalars['BigInt']
}

export type PlayGroundPriceRange_Input = {
    valueDouble: DoubleValue_Input
    valueFloat: FloatValue_Input
    valueInt: Int64Value_Input
    valueLong: Int64Value_Input
    valueLongNotnull: Scalars['BigInt']
}

export type PreparedSearchChildFilter = {
    __typename: 'PreparedSearchChildFilter'
    criteria: Array<PreparedSearchFilterCriteria>
    id: Scalars['ID']
}

export type PreparedSearchFilter = {
    __typename: 'PreparedSearchFilter'
    childFilters: Array<PreparedSearchChildFilter>
    criteria: Array<PreparedSearchFilterCriteria>
    id: Scalars['ID']
    metadata: Array<PreparedSearchMetadata>
}

export type PreparedSearchFilterCriteria = {
    __typename: 'PreparedSearchFilterCriteria'
    displayLabels: Array<PreparedSearchMetadata>
    displayName: Maybe<Scalars['String']>
    key: Scalars['String']
    value: Scalars['String']
}

export type PreparedSearchMetadata = {
    __typename: 'PreparedSearchMetadata'
    key: Scalars['String']
    value: Scalars['String']
}

export type PriceAggregation_Input = {
    includeEstimates?: InputMaybe<Scalars['Boolean']>
    includeSold?: InputMaybe<Scalars['Boolean']>
    numberOfBuckets?: InputMaybe<Scalars['Int']>
}

export type PriceDistributionPoint = {
    __typename: 'PriceDistributionPoint'
    count: Scalars['BigInt']
    countLog: Scalars['Float']
    lowerBound: Scalars['Float']
    lowerBoundLog: Scalars['Float']
    upperBound: Scalars['Float']
    upperBoundLog: Scalars['Float']
}

export type PriceDistributionResults = {
    __typename: 'PriceDistributionResults'
    results: Array<PriceDistributionPoint>
}

export type PriceFilter_Input = {
    includeEstimates: Scalars['Boolean']
    includeUnknown: Scalars['Boolean']
    range: PriceRange_Input
}

export type PriceRange_Input = {
    lowerBound?: InputMaybe<DoubleValue_Input>
    upperBound?: InputMaybe<DoubleValue_Input>
}

export type ProductPermissions = {
    __typename: 'ProductPermissions'
    pdb: PdbProductPermissions
}

export type ProductSubscription = {
    __typename: 'ProductSubscription'
    pdb: Maybe<PdbSubscription>
}

export type ProductSubscriptions = {
    __typename: 'ProductSubscriptions'
    overview: SubscriptionsOverview
    pdb: Array<PdbSubscription>
}

export enum PublicArtListingDomain {
    Auctions = 'AUCTIONS',
    Gallery = 'GALLERY',
    Pdb = 'PDB',
    Unspecified = 'UNSPECIFIED',
}

export type Query = {
    __typename: 'Query'
    artnetCoredataArtistContributionArtistContributionServiceConnectivityState: Maybe<ConnectivityState>
    artnetCoredataArtlistingCuratedListsArtListingCuratedListsConnectivityState: Maybe<ConnectivityState>
    artnetCoredataArtlistingCustomerFeedbackCustomerFeedbackConnectivityState: Maybe<ConnectivityState>
    artnetCoredataArtlistingDetailsArtListingDetailsQueryConnectivityState: Maybe<ConnectivityState>
    artnetCoredataArtlistingInsightsArtListingInsightsConnectivityState: Maybe<ConnectivityState>
    artnetCoredataArtlistingSearchArtListingConnectivityState: Maybe<ConnectivityState>
    artnetCoredataArtworkArtworkQueryServiceConnectivityState: Maybe<ConnectivityState>
    artnetCoredataPlaygroundPlaygroundConnectivityState: Maybe<ConnectivityState>
    artnetProductSalesLoansArtworkLoanInquiryArtworkLoanInquiryConnectivityState: Maybe<ConnectivityState>
    artnetProductUserPermissionsUserPermissionsConnectivityState: Maybe<ConnectivityState>
    artnetProductUserSubscriptionsUserSubscriptionsConnectivityState: Maybe<ConnectivityState>
    getArtListingAggregations: AggregationResults
    getArtListingById: ArtListingDetailsResults
    getArtListingByPublicId: ArtListingDetailsResults
    getArtListingCuratedListById: GetArtListingCuratedListByIdResponse
    getArtListingCuratedLists: GetArtListingCuratedListsResponse
    getArtListingSuggestions: GetSuggestionsResponse
    getArtListings: SearchResults
    getArtListingsWithAggregations: SearchWithAggregationsResponse
    getArtPriceIndices: GetArtPriceIndicesResponse
    getArtworks: GetArtworksResponse
    getContributions: GetContributionsResponse
    getCountriesWithFinancing: GetCountriesWithFinancingResponse
    getCurrentUser: CurrentUserResult
    getInput: EchoInputRequest
    getNewsArticles: GetNewsArticlesResponse
    getNotableArtListings: GetNotableArtListingsResponse
    getSearchFiltersForComparableWorks: GetSearchFiltersForComparableWorksResponse
    getSearchFiltersForPublicArtListing: GetSearchFiltersForPublicArtListingResponse
    getSearchInsights: GetSearchInsightsResponse
    getSnailMail: SnailMailResponse
    getSubscriptionById: SubscriptionByIdResponse
    getUserInfo: EchoUserInfoResponse
    getUserPermissions: UserPermissionsResult
    getUserSubscriptions: UserSubscriptionsResponse
    search: SiteSearchResults
}

export type QueryArtnetCoredataArtistContributionArtistContributionServiceConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataArtlistingCuratedListsArtListingCuratedListsConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataArtlistingCustomerFeedbackCustomerFeedbackConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataArtlistingDetailsArtListingDetailsQueryConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataArtlistingInsightsArtListingInsightsConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataArtlistingSearchArtListingConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataArtworkArtworkQueryServiceConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetCoredataPlaygroundPlaygroundConnectivityStateArgs = {
    tryToConnect: InputMaybe<Scalars['Boolean']>
}

export type QueryArtnetProductSalesLoansArtworkLoanInquiryArtworkLoanInquiryConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetProductUserPermissionsUserPermissionsConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryArtnetProductUserSubscriptionsUserSubscriptionsConnectivityStateArgs =
    {
        tryToConnect: InputMaybe<Scalars['Boolean']>
    }

export type QueryGetArtListingAggregationsArgs = {
    input: InputMaybe<AggregationRequest_Input>
}

export type QueryGetArtListingByIdArgs = {
    input: InputMaybe<ArtListingDetailsRequest_Input>
}

export type QueryGetArtListingByPublicIdArgs = {
    input: InputMaybe<GetArtListingByPublicIdRequest_Input>
}

export type QueryGetArtListingCuratedListByIdArgs = {
    input: InputMaybe<GetArtListingCuratedListByIdRequest_Input>
}

export type QueryGetArtListingCuratedListsArgs = {
    input: InputMaybe<GetArtListingCuratedListsRequest_Input>
}

export type QueryGetArtListingSuggestionsArgs = {
    input: InputMaybe<GetSuggestionsRequest_Input>
}

export type QueryGetArtListingsArgs = {
    input: InputMaybe<SearchRequest_Input>
}

export type QueryGetArtListingsWithAggregationsArgs = {
    input: InputMaybe<SearchWithAggregationsRequest_Input>
}

export type QueryGetArtPriceIndicesArgs = {
    input: InputMaybe<GetArtPriceIndicesRequest_Input>
}

export type QueryGetArtworksArgs = {
    input: InputMaybe<GetArtworksRequest_Input>
}

export type QueryGetContributionsArgs = {
    input: InputMaybe<GetContributionsRequest_Input>
}

export type QueryGetCountriesWithFinancingArgs = {
    input: InputMaybe<Scalars['GetCountriesWithFinancingRequest_Input']>
}

export type QueryGetInputArgs = {
    input: InputMaybe<EchoInputRequest_Input>
}

export type QueryGetNewsArticlesArgs = {
    input: GetNewsArticlesRequest_Input
}

export type QueryGetNotableArtListingsArgs = {
    input: InputMaybe<GetNotableArtListingsRequest_Input>
}

export type QueryGetSearchFiltersForComparableWorksArgs = {
    input: InputMaybe<GetSearchFiltersForComparableWorksRequest_Input>
}

export type QueryGetSearchFiltersForPublicArtListingArgs = {
    input: InputMaybe<GetSearchFiltersForPublicArtListingRequest_Input>
}

export type QueryGetSearchInsightsArgs = {
    input: InputMaybe<GetSearchInsightsRequest_Input>
}

export type QueryGetSnailMailArgs = {
    input: InputMaybe<SnailMailRequest_Input>
}

export type QueryGetSubscriptionByIdArgs = {
    input: InputMaybe<GetSubscriptionByIdRequest_Input>
}

export type QueryGetUserInfoArgs = {
    input: InputMaybe<Scalars['EchoUserInfoRequest_Input']>
}

export type QueryGetUserPermissionsArgs = {
    input: InputMaybe<UserPermissionsRequest_Input>
}

export type QueryGetUserSubscriptionsArgs = {
    input: InputMaybe<GetUserSubscriptionsRequest_Input>
}

export type QuerySearchArgs = {
    pageSize?: InputMaybe<Scalars['Int']>
    searchTerm: Scalars['String']
    searchType?: SearchType
}

export type QueryError = {
    __typename: 'QueryError'
    code: Scalars['String']
    message: Scalars['String']
}

export type RelatedArtistOverview = {
    __typename: 'RelatedArtistOverview'
    artistId: Scalars['Int']
    firstName: Maybe<Scalars['String']>
    lastName: Maybe<Scalars['String']>
    relatedTo: Scalars['Int']
    uri: Maybe<Scalars['String']>
}

export type RelatedSaleOverview = {
    __typename: 'RelatedSaleOverview'
    name: Maybe<Scalars['String']>
    saleId: Scalars['Int']
    uri: Maybe<Scalars['String']>
}

export type RemoveArtListingCuratedListItemsRequest_Input = {
    itemIds?: InputMaybe<Array<Scalars['ID']>>
    listId: Scalars['ID']
}

export type RemoveArtListingCuratedListItemsResponse = {
    __typename: 'RemoveArtListingCuratedListItemsResponse'
    results: Array<RemoveCuratedListItemsResult>
}

export type RemoveCuratedListItemsResult = {
    __typename: 'RemoveCuratedListItemsResult'
    list: ArtListingCuratedListSummary
    numRemoved: Scalars['Int']
}

export type RepositionArtListingCuratedListItemsRequest_Input = {
    listId: Scalars['ID']
    updateItemPosition?: InputMaybe<
        Array<UpdateArtListingCuratedListItemPosition_Input>
    >
}

export type RepositionArtListingCuratedListItemsResponse = {
    __typename: 'RepositionArtListingCuratedListItemsResponse'
    results: Array<ArtListingCuratedListItem>
}

export enum SalePriceType {
    ComingSoon = 'COMING_SOON',
    Hammer = 'HAMMER',
    Premium = 'PREMIUM',
    Unspecified = 'UNSPECIFIED',
}

export type SearchHint = {
    description: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    url: Scalars['AbsoluteUrl']
}

export type SearchInsightsAutoRange_Input = {
    years?: InputMaybe<Scalars['Int']>
}

export type SearchInsightsConfiguration_Input = {
    priceDistributionBucketCount?: InputMaybe<Scalars['Int']>
}

export type SearchInsightsCustomRange_Input = {
    end?: InputMaybe<Scalars['DateTime']>
    start?: InputMaybe<Scalars['DateTime']>
}

export type SearchInsightsResultTimeframe = {
    __typename: 'SearchInsightsResultTimeframe'
    end: Scalars['DateTime']
    start: Scalars['DateTime']
}

export enum SearchInsightsType {
    OfferedVsSold = 'OFFERED_VS_SOLD',
    PriceDistribution = 'PRICE_DISTRIBUTION',
    Statistics = 'STATISTICS',
    TotalSales = 'TOTAL_SALES',
    Unspecified = 'UNSPECIFIED',
}

export type SearchRequest_Input = {
    filters?: InputMaybe<ArtListingFilters_Input>
    freeformTextQuery?: InputMaybe<Scalars['String']>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    preferences?: InputMaybe<ArtListingPreferences_Input>
    sortBy?: InputMaybe<Array<SortByClause_Input>>
    subscriptionId: Scalars['ID']
    subscriptionScope?: InputMaybe<SubscriptionScope>
}

export type SearchResults = {
    __typename: 'SearchResults'
    count: Maybe<Scalars['BigInt']>
    results: Array<ArtListingSummary>
}

export type SearchStatistics = {
    __typename: 'SearchStatistics'
    aboveHighEstimateRate: Maybe<Scalars['Float']>
    aboveHighEstimateRateLastYear: Maybe<Scalars['Float']>
    belowLowEstimateRate: Maybe<Scalars['Float']>
    belowLowEstimateRateLastYear: Maybe<Scalars['Float']>
    recentlyUpdated: Scalars['BigInt']
    sellThroughRate: Maybe<Scalars['Float']>
    sellThroughRateLastYear: Maybe<Scalars['Float']>
    totalListingsCount: Scalars['BigInt']
    totalUpcomingListings: Maybe<Scalars['BigInt']>
}

export enum SearchType {
    Auctions = 'AUCTIONS',
    Global = 'GLOBAL',
    News = 'NEWS',
}

export type SearchWithAggregationsRequest_Input = {
    aggregationCriteria?: InputMaybe<Array<AggregationCriteria_Input>>
    searchRequest: SearchRequest_Input
}

export type SearchWithAggregationsResponse = {
    __typename: 'SearchWithAggregationsResponse'
    aggregationResults: Maybe<AggregationResults>
    searchResults: Maybe<SearchResults>
}

export type SiteSearchResults = {
    __typename: 'SiteSearchResults'
    results: Array<SearchHint>
}

export type SnailMailRequest_Input = {
    allowCancellation: Scalars['Boolean']
    fakeDataLength: Scalars['Int']
    waitSeconds: Scalars['Int']
}

export type SnailMailResponse = {
    __typename: 'SnailMailResponse'
    fakeData: Scalars['String']
}

export enum SortBy {
    CatalogLotNumber = 'CATALOG_LOT_NUMBER',
    CreationDate = 'CREATION_DATE',
    LastUpdatedDate = 'LAST_UPDATED_DATE',
    LotTitle = 'LOT_TITLE',
    Price = 'PRICE',
    Relevancy = 'RELEVANCY',
    SaleDate = 'SALE_DATE',
    SaleId = 'SALE_ID',
}

export type SortByClause_Input = {
    direction?: InputMaybe<SortDirection>
    fields?: InputMaybe<Array<SortBy>>
}

export enum SortDirection {
    Ascending = 'ASCENDING',
    Descending = 'DESCENDING',
}

export type Specialty = {
    __typename: 'Specialty'
    name: Maybe<Scalars['String']>
    uri: Maybe<Scalars['String']>
}

export enum SubEntityType {
    Artist = 'ARTIST',
    Manufacturer = 'MANUFACTURER',
    Unspecified = 'UNSPECIFIED',
}

export type SubscriptionByIdResponse = {
    __typename: 'SubscriptionByIdResponse'
    result: ProductSubscription
}

export enum SubscriptionScope {
    LegacyPdb = 'LEGACY_PDB',
    Unspecified = 'UNSPECIFIED',
}

export enum SubscriptionStatus {
    Any = 'ANY',
    Invalid = 'INVALID',
    Valid = 'VALID',
}

export type SubscriptionsOverview = {
    __typename: 'SubscriptionsOverview'
    hasLegacyPdb: Scalars['Boolean']
}

export type TotalSalesPoint = {
    __typename: 'TotalSalesPoint'
    quantitySold: Scalars['BigInt']
    startDate: Scalars['DateTime']
    valueUsd: Scalars['Float']
}

export type TotalSalesResults = {
    __typename: 'TotalSalesResults'
    results: Array<TotalSalesPoint>
}

export type UpdateArtListingCuratedListItemPosition_Input = {
    id: Scalars['ID']
    newPosition: Scalars['Int']
}

export type UpdateArtListingCuratedListRequest_Input = {
    featuredItemId?: InputMaybe<Scalars['ID']>
    id: Scalars['ID']
    title?: InputMaybe<Scalars['String']>
}

export type UpdateArtListingCuratedListResponse = {
    __typename: 'UpdateArtListingCuratedListResponse'
    result: ArtListingCuratedListSummary
}

export type UpdateArtListingCuratedListStatusRequest_Input = {
    listIds?: InputMaybe<Array<Scalars['ID']>>
    status: ArtListingCuratedListStatus
}

export type UpdateArtListingCuratedListStatusResponse = {
    __typename: 'UpdateArtListingCuratedListStatusResponse'
    results: Array<ArtListingCuratedListSummary>
}

export type User = {
    __typename: 'User'
    displayName: Scalars['String']
    email: Scalars['String']
    id: Scalars['ID']
    phone: Maybe<Scalars['String']>
}

export enum UserPermissionScope {
    Pdb = 'Pdb',
    Unspecified = 'Unspecified',
}

export type UserPermissionsRequest_Input = {
    scope?: InputMaybe<UserPermissionScope>
}

export type UserPermissionsResult = {
    __typename: 'UserPermissionsResult'
    productPermissions: ProductPermissions
}

export type UserSubscriptionsResponse = {
    __typename: 'UserSubscriptionsResponse'
    results: ProductSubscriptions
}

export type VariableWidthAggregationResult = {
    __typename: 'VariableWidthAggregationResult'
    count: Maybe<Scalars['BigInt']>
    displayName: Maybe<Scalars['String']>
    lowerBound: Maybe<Scalars['Float']>
    upperBound: Maybe<Scalars['Float']>
}

export type VariableWidthAggregationResults = {
    __typename: 'VariableWidthAggregationResults'
    countUnknown: Maybe<Scalars['BigInt']>
    results: Array<VariableWidthAggregationResult>
}

export type VariableWidthAggregation_Input = {
    numberOfBuckets?: InputMaybe<Scalars['Int']>
}

export type VariableWidthDateAggregationResult = {
    __typename: 'VariableWidthDateAggregationResult'
    count: Maybe<Scalars['BigInt']>
    lowerBound: Maybe<CustomDate>
    upperBound: Maybe<CustomDate>
}

export type VariableWidthDateAggregationResults = {
    __typename: 'VariableWidthDateAggregationResults'
    countUnknown: Maybe<Scalars['BigInt']>
    results: Array<VariableWidthDateAggregationResult>
    scale: Maybe<DateInterval>
    scaleFactor: Maybe<Scalars['Int']>
}

export type WorkYear = {
    __typename: 'WorkYear'
    eraFrom: Era
    eraTo: Era
    modifier: Maybe<Scalars['String']>
    yearFrom: Maybe<Scalars['Int']>
    yearTo: Maybe<Scalars['Int']>
}

export type YearFilter_Input = {
    includeUnknown?: InputMaybe<Scalars['Boolean']>
    lowerBound?: InputMaybe<Int32Value_Input>
    upperBound?: InputMaybe<Int32Value_Input>
}

export type LenderFormMutationMutationVariables = Exact<{
    input: InquireAboutFinancingRequest_Input
}>

export type LenderFormMutationMutationResponse = {
    __typename: 'Mutation'
    inquireAboutFinancing: {
        __typename: 'InquireAboutFinancingResponse'
        message: string
        errors: Array<{
            __typename: 'MutationError'
            message: string
            code: string
        }>
    }
}

export type LenderFormQueryVariables = Exact<{ [key: string]: never }>

export type LenderFormQueryResponse = {
    __typename: 'Query'
    getCountriesWithFinancing: {
        __typename: 'GetCountriesWithFinancingResponse'
        results: Array<{ __typename: 'Country'; id: string; name: string }>
    }
}

export type ArtListingSummaryFragment = {
    __typename: 'ArtListingSummary'
    id: string
    artListingDetailId: string
    saleName: string | null
    transactionStatusClarification: string | null
    auctionHouse: {
        __typename: 'AuctionHouseSummary'
        id: string
        name: string
        homepageUrl: string | null
    } | null
    saleDates: Array<{
        __typename: 'CustomDate'
        year: number
        month: number | null
        day: number | null
    }>
    featuredImage: {
        __typename: 'Image'
        baseImageUrl: string
        alt: string | null
    } | null
    prices: Array<{
        __typename: 'ArtListingSalePrice'
        estimatedPriceLow: number | null
        estimatedPriceHigh: number | null
        currencyCode: { __typename: 'Entity'; value: string | null }
        finalPrices: Array<{
            __typename: 'ArtListingFinalPrice'
            priceType: SalePriceType | null
            price: number
            differenceFromClosestEstimate: number | null
        }>
    }>
    lot: {
        __typename: 'LotSummary'
        catalogLotNumber: string | null
        title: string | null
        artworks: Array<{
            __typename: 'ArtworkSummary'
            mediumRaw: string | null
            creationYearFrom: number | null
            creationEraFrom: Era | null
            creationYearTo: number | null
            creationEraTo: Era | null
            edition: string | null
            creator: {
                __typename: 'CreatorSummary'
                name: string
                modifier: string | null
            } | null
            dimensions: Array<{
                __typename: 'Dimensions'
                unit: DimensionUnit
                width: number | null
                height: number | null
                depth: number | null
            }>
            classification: Array<{ __typename: 'Entity'; id: string }>
        }>
    } | null
}

export type CustomDateFragment = {
    __typename: 'CustomDate'
    year: number
    month: number | null
    day: number | null
}

export type DimensionsFragment = {
    __typename: 'Dimensions'
    unit: DimensionUnit
    width: number | null
    height: number | null
    depth: number | null
}

export type PdbSubscriptionFragment = {
    __typename: 'PdbSubscription'
    id: string
    title: string
    type: PdbSubscriptionType
    expiry: any | null
    isValid: boolean
    invalidityReasons: Array<PdbSubscriptionInvalidityReason>
    warnings: Array<PdbSubscriptionWarning>
    quota: {
        __typename: 'PdbSubscriptionQuota'
        quotaType: PdbSubscriptionQuotaType
        numSearchesRemaining: number | null
    }
}

export type AddItemsToListMutationVariables = Exact<{
    listIds: Array<Scalars['ID']> | Scalars['ID']
    itemIds: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type AddItemsToListMutationResponse = {
    __typename: 'Mutation'
    addArtListingCuratedListItems: {
        __typename: 'AddArtListingCuratedListItemsResponse'
        results: Array<{
            __typename: 'AddCuratedListItemsResult'
            numAdded: number
        }>
    }
}

export type CreateListMutationVariables = Exact<{
    title: Scalars['String']
    itemIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type CreateListMutationResponse = {
    __typename: 'Mutation'
    createArtListingCuratedList: {
        __typename: 'CreateArtListingCuratedListResponse'
        result: { __typename: 'ArtListingCuratedList'; id: string }
    }
}

export type RemoveItemsFromListMutationVariables = Exact<{
    listId: Scalars['ID']
    itemIds: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type RemoveItemsFromListMutationResponse = {
    __typename: 'Mutation'
    removeArtListingCuratedListItems: {
        __typename: 'RemoveArtListingCuratedListItemsResponse'
        results: Array<{
            __typename: 'RemoveCuratedListItemsResult'
            numRemoved: number
        }>
    }
}

export type UpdateListMutationVariables = Exact<{
    id: Scalars['ID']
    title: InputMaybe<Scalars['String']>
    featuredItemId: InputMaybe<Scalars['ID']>
}>

export type UpdateListMutationResponse = {
    __typename: 'Mutation'
    updateArtListingCuratedList: {
        __typename: 'UpdateArtListingCuratedListResponse'
        result: {
            __typename: 'ArtListingCuratedListSummary'
            id: string
            dateModified: any
            status: ArtListingCuratedListStatus
        }
    }
}

export type UpdateListStatusMutationVariables = Exact<{
    listIds: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
    status: ArtListingCuratedListStatus
}>

export type UpdateListStatusMutationResponse = {
    __typename: 'Mutation'
    updateArtListingCuratedListStatus: {
        __typename: 'UpdateArtListingCuratedListStatusResponse'
        results: Array<{
            __typename: 'ArtListingCuratedListSummary'
            id: string
            title: string
            count: number
            status: ArtListingCuratedListStatus
        }>
    }
}

export type ArtListingDetailsQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type ArtListingDetailsQueryResponse = {
    __typename: 'Query'
    getArtListingById: {
        __typename: 'ArtListingDetailsResults'
        id: string
        transactionStatusClarification: string | null
        saleName: string | null
        saleDescription: string | null
        auctionHouse: {
            __typename: 'AuctionHouse'
            id: string
            name: string | null
            homepageUrl: string | null
            location: {
                __typename: 'Location'
                city: string | null
                state: string | null
                country: string | null
            }
        } | null
        saleDates: Array<{
            __typename: 'CustomDate'
            year: number
            month: number | null
            day: number | null
        }>
        featuredImage: {
            __typename: 'Image'
            baseImageUrl: string
            alt: string | null
        } | null
        images: Array<{
            __typename: 'Image'
            baseImageUrl: string
            alt: string | null
        }>
        prices: Array<{
            __typename: 'ArtListingSalePrice'
            estimatedPriceLow: number | null
            estimatedPriceHigh: number | null
            currencyCode: { __typename: 'Entity'; value: string | null }
            finalPrices: Array<{
                __typename: 'ArtListingFinalPrice'
                priceType: SalePriceType | null
                price: number
                differenceFromClosestEstimate: number | null
            }>
        }>
        lot: {
            __typename: 'Lot'
            artnetLotId: number | null
            catalogLotNumber: string | null
            title: string | null
            description: string | null
            externalUrl: string | null
            artworks: Array<{
                __typename: 'ArtListingDetailsArtwork'
                mediumRaw: string | null
                creationYearQualifier: string | null
                creationYearFrom: number | null
                creationEraFrom: Era
                creationYearTo: number | null
                creationEraTo: Era
                edition: string | null
                provenance: string | null
                literature: string | null
                exhibition: string | null
                catalogueNote: string | null
                publisher: string | null
                raisonneAuthor: string | null
                raisonneNumber: string | null
                classification: Array<{
                    __typename: 'Specialty'
                    uri: string | null
                }>
                creator: {
                    __typename: 'Creator'
                    creatorType: CreatorType
                    uri: string | null
                    modifier: string | null
                    name: string | null
                    artists: Array<{
                        __typename: 'Artist'
                        firstName: string | null
                        lastName: string | null
                        nationalities: Array<string>
                        birthYear: number | null
                        birthYearEra: Era | null
                        birthYearQualifier: string | null
                        deathYear: number | null
                        deathYearEra: Era | null
                        deathYearQualifier: string | null
                        gender: string | null
                        uri: string | null
                        birthLocation: {
                            __typename: 'Location'
                            country: string | null
                        } | null
                    }>
                } | null
                dimensions: Array<{
                    __typename: 'Dimensions'
                    unit: DimensionUnit
                    width: number | null
                    height: number | null
                    depth: number | null
                }>
                markings: {
                    __typename: 'Markings'
                    isInscribed: boolean | null
                    isSigned: boolean | null
                    isStamped: boolean | null
                    isPlateSigned: boolean | null
                } | null
                objectType: Array<{
                    __typename: 'Specialty'
                    name: string | null
                }>
                category: Array<{ __typename: 'Category'; name: string | null }>
                movement: Array<{
                    __typename: 'Specialty'
                    name: string | null
                }>
                materials: Array<{
                    __typename: 'Specialty'
                    name: string | null
                }>
                period: Array<{ __typename: 'Specialty'; name: string | null }>
                region: Array<{ __typename: 'Specialty'; name: string | null }>
                subjectMatter: Array<{
                    __typename: 'Specialty'
                    name: string | null
                }>
                castYear: {
                    __typename: 'WorkYear'
                    modifier: string | null
                    yearFrom: number | null
                    eraFrom: Era
                    yearTo: number | null
                    eraTo: Era
                } | null
            }>
        } | null
    }
}

export type ArtListingSuggestionsQueryVariables = Exact<{
    filters: InputMaybe<ArtListingFilters_Input>
    freeformTextQuery: Scalars['String']
    limit?: InputMaybe<Scalars['Int']>
}>

export type ArtListingSuggestionsQueryResponse = {
    __typename: 'Query'
    getArtListingSuggestions: {
        __typename: 'GetSuggestionsResponse'
        count: any | null
        results: Array<{
            __typename: 'AggregationResult'
            id: string
            entityType: EntityType | null
            subEntityType: SubEntityType | null
            displayName: string | null
            description: string | null
            count: any | null
        }>
    }
}

export type ArtistPriceIndexQueryVariables = Exact<{
    creators: InputMaybe<
        Array<ArtPriceIndexRequest_Input> | ArtPriceIndexRequest_Input
    >
    autoRange: InputMaybe<ArtPriceIndexAutoRange_Input>
}>

export type ArtistPriceIndexQueryResponse = {
    __typename: 'Query'
    getArtPriceIndices: {
        __typename: 'GetArtPriceIndicesResponse'
        result: {
            __typename: 'ArtPriceIndices'
            baseline: number
            primaryIndex: {
                __typename: 'ArtPriceIndex'
                name: string
                results: Array<{
                    __typename: 'ArtPriceIndexPoint'
                    date: any
                    distanceFromBaseline: number
                    value: number
                }>
            }
            remainingIndices: Array<{
                __typename: 'ArtPriceIndex'
                name: string
                results: Array<{
                    __typename: 'ArtPriceIndexPoint'
                    date: any
                    distanceFromBaseline: number
                    value: number
                }>
            }>
        } | null
        resultTimeframe: {
            __typename: 'ArtPriceIndicesTimeframe'
            start: any
            end: any
        } | null
    }
}

export type CurrentUserBasicInfoQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserBasicInfoQueryResponse = {
    __typename: 'Query'
    getCurrentUser: {
        __typename: 'CurrentUserResult'
        isLoggedIn: boolean
        isReauthenticationRequired: boolean
        user: {
            __typename: 'User'
            id: string
            displayName: string
            email: string
        } | null
    }
}

export type NewsArticlesQueryVariables = Exact<{
    filters: EntityFilter_Input
    pageSize: Scalars['Int']
    page: Scalars['Int']
}>

export type NewsArticlesQueryResponse = {
    __typename: 'Query'
    getNewsArticles: {
        __typename: 'GetNewsArticlesResponse'
        results: Array<{
            __typename: 'NewsArticle'
            id: string
            author: string
            authorUrl: any
            categoryName: string
            categoryUrl: string
            date: any
            title: string
            url: any
            featuredImage: Array<{
                __typename: 'NewsImage'
                aspectRatio: string
                url: any
            } | null>
        }>
    }
}

export type OldResultCountQueryQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type OldResultCountQueryQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        classification: {
            __typename: 'EntityAggregationResults'
            count: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type OldUserPermissionsQueryVariables = Exact<{ [key: string]: never }>

export type OldUserPermissionsQueryResponse = {
    __typename: 'Query'
    getUserPermissions: {
        __typename: 'UserPermissionsResult'
        productPermissions: {
            __typename: 'ProductPermissions'
            pdb: {
                __typename: 'PdbProductPermissions'
                fineArt: PdbAccessResult
                decArt: PdbAccessResult
            }
        }
    }
}

export type ResultCountAndRemainingSearchesQueryVariables = Exact<{
    subscriptionId: Scalars['ID']
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type ResultCountAndRemainingSearchesQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        classification: {
            __typename: 'EntityAggregationResults'
            count: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
    getSubscriptionById: {
        __typename: 'SubscriptionByIdResponse'
        result: {
            __typename: 'ProductSubscription'
            pdb: {
                __typename: 'PdbSubscription'
                id: string
                title: string
                type: PdbSubscriptionType
                expiry: any | null
                isValid: boolean
                invalidityReasons: Array<PdbSubscriptionInvalidityReason>
                warnings: Array<PdbSubscriptionWarning>
                quota: {
                    __typename: 'PdbSubscriptionQuota'
                    quotaType: PdbSubscriptionQuotaType
                    numSearchesRemaining: number | null
                }
            } | null
        }
    }
}

export type SearchArtListingsQueryVariables = Exact<{
    subscriptionId: Scalars['ID']
    subscriptionScope?: SubscriptionScope
    filters: InputMaybe<ArtListingFilters_Input>
    freeText: InputMaybe<Scalars['String']>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sort: InputMaybe<Array<SortByClause_Input> | SortByClause_Input>
}>

export type SearchArtListingsQueryResponse = {
    __typename: 'Query'
    getArtListings: {
        __typename: 'SearchResults'
        count: any | null
        results: Array<{
            __typename: 'ArtListingSummary'
            id: string
            artListingDetailId: string
            saleName: string | null
            transactionStatusClarification: string | null
            auctionHouse: {
                __typename: 'AuctionHouseSummary'
                id: string
                name: string
                homepageUrl: string | null
            } | null
            saleDates: Array<{
                __typename: 'CustomDate'
                year: number
                month: number | null
                day: number | null
            }>
            featuredImage: {
                __typename: 'Image'
                baseImageUrl: string
                alt: string | null
            } | null
            prices: Array<{
                __typename: 'ArtListingSalePrice'
                estimatedPriceLow: number | null
                estimatedPriceHigh: number | null
                currencyCode: { __typename: 'Entity'; value: string | null }
                finalPrices: Array<{
                    __typename: 'ArtListingFinalPrice'
                    priceType: SalePriceType | null
                    price: number
                    differenceFromClosestEstimate: number | null
                }>
            }>
            lot: {
                __typename: 'LotSummary'
                catalogLotNumber: string | null
                title: string | null
                artworks: Array<{
                    __typename: 'ArtworkSummary'
                    mediumRaw: string | null
                    creationYearFrom: number | null
                    creationEraFrom: Era | null
                    creationYearTo: number | null
                    creationEraTo: Era | null
                    edition: string | null
                    creator: {
                        __typename: 'CreatorSummary'
                        name: string
                        modifier: string | null
                    } | null
                    dimensions: Array<{
                        __typename: 'Dimensions'
                        unit: DimensionUnit
                        width: number | null
                        height: number | null
                        depth: number | null
                    }>
                    classification: Array<{ __typename: 'Entity'; id: string }>
                }>
            } | null
        }>
    }
}

export type SearchInsightsQueryVariables = Exact<{
    calendarInterval: InsightsInterval
    insightTypes: Array<SearchInsightsType> | SearchInsightsType
    filters: InputMaybe<ArtListingFilters_Input>
    freeFormTextQuery: InputMaybe<Scalars['String']>
}>

export type SearchInsightsQueryResponse = {
    __typename: 'Query'
    getSearchInsights: {
        __typename: 'GetSearchInsightsResponse'
        isApplicable: boolean
        offeredVsSold: {
            __typename: 'OfferedVsSoldResults'
            results: Array<{
                __typename: 'OfferedVsSoldPoint'
                lotsOffered: any
                lotsSold: any
                startDate: any
            }>
        } | null
        statistics: {
            __typename: 'SearchStatistics'
            sellThroughRate: number | null
            sellThroughRateLastYear: number | null
            aboveHighEstimateRate: number | null
            aboveHighEstimateRateLastYear: number | null
            belowLowEstimateRate: number | null
            belowLowEstimateRateLastYear: number | null
            totalUpcomingListings: any | null
            recentlyUpdated: any
            totalListingsCount: any
        } | null
        priceDistribution: {
            __typename: 'PriceDistributionResults'
            results: Array<{
                __typename: 'PriceDistributionPoint'
                lowerBound: number
                lowerBoundLog: number
                upperBound: number
                upperBoundLog: number
                count: any
                countLog: number
            }>
        } | null
        totalSales: {
            __typename: 'TotalSalesResults'
            results: Array<{
                __typename: 'TotalSalesPoint'
                startDate: any
                valueUsd: number
                quantitySold: any
            }>
        } | null
        resultTimeframe: {
            __typename: 'SearchInsightsResultTimeframe'
            start: any
            end: any
        } | null
    }
}

export type SearchSiteQueryVariables = Exact<{
    searchTerm: Scalars['String']
    pageSize?: Scalars['Int']
    searchType?: SearchType
}>

export type SearchSiteQueryResponse = {
    __typename: 'Query'
    search: {
        __typename: 'SiteSearchResults'
        results: Array<
            | {
                  __typename: 'GlobalSearchHint'
                  description: string
                  id: string
                  name: string
                  url: any
              }
            | {
                  __typename: 'NewsSearchHint'
                  author: string
                  date: any
                  description: string
                  id: string
                  name: string
                  url: any
              }
        >
    }
}

export type Aggregation_AuctionHouseQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_AuctionHouseQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        auctionHouse: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_BirthYearQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    numberOfBuckets?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_BirthYearQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        birthYear: {
            __typename: 'VariableWidthAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'VariableWidthAggregationResult'
                count: any | null
                lowerBound: number | null
                upperBound: number | null
            }>
        } | null
    }
}

export type Aggregation_CategoryQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_CategoryQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        category: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_CreationYearQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    numberOfBuckets?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_CreationYearQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        creationYear: {
            __typename: 'VariableWidthAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'VariableWidthAggregationResult'
                count: any | null
                lowerBound: number | null
                upperBound: number | null
            }>
        } | null
    }
}

export type Aggregation_CreatorQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_CreatorQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        creator: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_CreatorNationalityQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_CreatorNationalityQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        creatorNationality: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_CurrencyQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_CurrencyQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        currency: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_DeathYearQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    numberOfBuckets?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_DeathYearQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        deathYear: {
            __typename: 'VariableWidthAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'VariableWidthAggregationResult'
                count: any | null
                lowerBound: number | null
                upperBound: number | null
            }>
        } | null
    }
}

export type Aggregation_DimensionQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    aggregations:
        | Array<DimensionBucketAggregation_Input>
        | DimensionBucketAggregation_Input
}>

export type Aggregation_DimensionQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        dimensions: {
            __typename: 'DimensionAggregationResults'
            countUnknown: any
            results: Array<{
                __typename: 'DimensionBucketAggregationResult'
                count: any
                countUnknown: any
                bucketName: string
                longestEdge: {
                    __typename: 'DimensionRangeResult'
                    countUnknown: any
                    range: {
                        __typename: 'NumericRange'
                        lowerBound: number | null
                        upperBound: number | null
                    } | null
                } | null
                height: {
                    __typename: 'DimensionRangeResult'
                    countUnknown: any
                    range: {
                        __typename: 'NumericRange'
                        lowerBound: number | null
                        upperBound: number | null
                    } | null
                } | null
                width: {
                    __typename: 'DimensionRangeResult'
                    countUnknown: any
                    range: {
                        __typename: 'NumericRange'
                        lowerBound: number | null
                        upperBound: number | null
                    } | null
                } | null
                depth: {
                    __typename: 'DimensionRangeResult'
                    countUnknown: any
                    range: {
                        __typename: 'NumericRange'
                        lowerBound: number | null
                        upperBound: number | null
                    } | null
                } | null
            }>
        } | null
    }
}

export type Aggregation_LotTitleQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_LotTitleQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        lotTitle: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_MaterialQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_MaterialQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        material: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_MediumQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_MediumQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        mediumRaw: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_MovementQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_MovementQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        movement: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_ObjectTypeQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_ObjectTypeQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        objectType: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_PeriodQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_PeriodQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        period: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_PriceQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    numberOfBuckets?: InputMaybe<Scalars['Int']>
    includeSold?: InputMaybe<Scalars['Boolean']>
    includeEstimates?: InputMaybe<Scalars['Boolean']>
}>

export type Aggregation_PriceQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        price: {
            __typename: 'VariableWidthAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'VariableWidthAggregationResult'
                count: any | null
                lowerBound: number | null
                upperBound: number | null
            }>
        } | null
    }
}

export type Aggregation_RegionQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_RegionQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        region: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_SaleQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_SaleQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        sale: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_SaleDateQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    numberOfBuckets?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_SaleDateQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        saleDate: {
            __typename: 'VariableWidthDateAggregationResults'
            scale: DateInterval | null
            scaleFactor: number | null
            countUnknown: any | null
            results: Array<{
                __typename: 'VariableWidthDateAggregationResult'
                count: any | null
                lowerBound: {
                    __typename: 'CustomDate'
                    year: number
                    month: number | null
                    day: number | null
                } | null
                upperBound: {
                    __typename: 'CustomDate'
                    year: number
                    month: number | null
                    day: number | null
                } | null
            }>
        } | null
    }
}

export type Aggregation_SimplifiedTransactionStatusQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_SimplifiedTransactionStatusQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        simplifiedTransactionStatus: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type Aggregation_SubjectMatterQueryVariables = Exact<{
    filters: ArtListingFilters_Input
    freeText: InputMaybe<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
}>

export type Aggregation_SubjectMatterQueryResponse = {
    __typename: 'Query'
    getArtListingAggregations: {
        __typename: 'AggregationResults'
        subjectMatter: {
            __typename: 'EntityAggregationResults'
            countUnknown: any | null
            results: Array<{
                __typename: 'AggregationResult'
                id: string
                displayName: string | null
                description: string | null
                count: any | null
            }>
        } | null
    }
}

export type GetListByIdQueryVariables = Exact<{
    id: Scalars['ID']
    freeformTextQuery: InputMaybe<Scalars['String']>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sortBy: InputMaybe<
        | Array<ArtListingCuratedListItemSortByClause_Input>
        | ArtListingCuratedListItemSortByClause_Input
    >
}>

export type GetListByIdQueryResponse = {
    __typename: 'Query'
    getArtListingCuratedListById: {
        __typename: 'GetArtListingCuratedListByIdResponse'
        result: {
            __typename: 'ArtListingCuratedList'
            id: string
            title: string
            origin: ArtListingCuratedListOrigin
            status: ArtListingCuratedListStatus
            count: number
            dateCreated: any
            dateModified: any
            featuredImage: {
                __typename: 'Image'
                baseImageUrl: string
                alt: string | null
            } | null
            items: Array<{
                __typename: 'ArtListingCuratedListItem'
                id: string
                title: string
                position: number
                artListing: {
                    __typename: 'ArtListingSummary'
                    id: string
                    artListingDetailId: string
                    saleName: string | null
                    transactionStatusClarification: string | null
                    auctionHouse: {
                        __typename: 'AuctionHouseSummary'
                        id: string
                        name: string
                        homepageUrl: string | null
                    } | null
                    saleDates: Array<{
                        __typename: 'CustomDate'
                        year: number
                        month: number | null
                        day: number | null
                    }>
                    featuredImage: {
                        __typename: 'Image'
                        baseImageUrl: string
                        alt: string | null
                    } | null
                    prices: Array<{
                        __typename: 'ArtListingSalePrice'
                        estimatedPriceLow: number | null
                        estimatedPriceHigh: number | null
                        currencyCode: {
                            __typename: 'Entity'
                            value: string | null
                        }
                        finalPrices: Array<{
                            __typename: 'ArtListingFinalPrice'
                            priceType: SalePriceType | null
                            price: number
                            differenceFromClosestEstimate: number | null
                        }>
                    }>
                    lot: {
                        __typename: 'LotSummary'
                        catalogLotNumber: string | null
                        title: string | null
                        artworks: Array<{
                            __typename: 'ArtworkSummary'
                            mediumRaw: string | null
                            creationYearFrom: number | null
                            creationEraFrom: Era | null
                            creationYearTo: number | null
                            creationEraTo: Era | null
                            edition: string | null
                            creator: {
                                __typename: 'CreatorSummary'
                                name: string
                                modifier: string | null
                            } | null
                            dimensions: Array<{
                                __typename: 'Dimensions'
                                unit: DimensionUnit
                                width: number | null
                                height: number | null
                                depth: number | null
                            }>
                            classification: Array<{
                                __typename: 'Entity'
                                id: string
                            }>
                        }>
                    } | null
                } | null
            }>
        } | null
    }
}

export type GetListsQueryVariables = Exact<{
    freeformTextQuery: InputMaybe<Scalars['String']>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    sortBy: InputMaybe<
        | Array<ArtListingCuratedListSortByClause_Input>
        | ArtListingCuratedListSortByClause_Input
    >
    origins: InputMaybe<
        Array<ArtListingCuratedListOrigin> | ArtListingCuratedListOrigin
    >
}>

export type GetListsQueryResponse = {
    __typename: 'Query'
    getArtListingCuratedLists: {
        __typename: 'GetArtListingCuratedListsResponse'
        results: Array<{
            __typename: 'ArtListingCuratedListSummary'
            id: string
            title: string
            dateCreated: any
            dateModified: any
            count: number
            featuredImage: {
                __typename: 'Image'
                baseImageUrl: string
                alt: string | null
            } | null
        }>
    }
}

export type PdbSubscriptionByIdQueryVariables = Exact<{
    id: Scalars['ID']
}>

export type PdbSubscriptionByIdQueryResponse = {
    __typename: 'Query'
    getSubscriptionById: {
        __typename: 'SubscriptionByIdResponse'
        result: {
            __typename: 'ProductSubscription'
            pdb: {
                __typename: 'PdbSubscription'
                id: string
                title: string
                type: PdbSubscriptionType
                expiry: any | null
                isValid: boolean
                invalidityReasons: Array<PdbSubscriptionInvalidityReason>
                warnings: Array<PdbSubscriptionWarning>
                quota: {
                    __typename: 'PdbSubscriptionQuota'
                    quotaType: PdbSubscriptionQuotaType
                    numSearchesRemaining: number | null
                }
            } | null
        }
    }
}

export type UserPdbSubscriptionsQueryVariables = Exact<{ [key: string]: never }>

export type UserPdbSubscriptionsQueryResponse = {
    __typename: 'Query'
    getUserSubscriptions: {
        __typename: 'UserSubscriptionsResponse'
        results: {
            __typename: 'ProductSubscriptions'
            overview: {
                __typename: 'SubscriptionsOverview'
                hasLegacyPdb: boolean
            }
            pdb: Array<{
                __typename: 'PdbSubscription'
                id: string
                title: string
                type: PdbSubscriptionType
                expiry: any | null
                isValid: boolean
                invalidityReasons: Array<PdbSubscriptionInvalidityReason>
                warnings: Array<PdbSubscriptionWarning>
                quota: {
                    __typename: 'PdbSubscriptionQuota'
                    quotaType: PdbSubscriptionQuotaType
                    numSearchesRemaining: number | null
                }
            }>
        }
    }
}

export const CustomDateFragmentDoc = gql`
    fragment CustomDate on CustomDate {
        year
        month
        day
    }
`
export const DimensionsFragmentDoc = gql`
    fragment Dimensions on Dimensions {
        unit
        width
        height
        depth
    }
`
export const ArtListingSummaryFragmentDoc = gql`
    fragment ArtListingSummary on ArtListingSummary {
        id
        artListingDetailId
        auctionHouse {
            id
            name
            homepageUrl
        }
        saleName
        saleDates {
            ...CustomDate
        }
        transactionStatusClarification
        featuredImage {
            baseImageUrl
            alt
        }
        prices {
            currencyCode {
                value
            }
            estimatedPriceLow
            estimatedPriceHigh
            finalPrices {
                priceType
                price
                differenceFromClosestEstimate
            }
        }
        lot {
            catalogLotNumber
            title
            artworks {
                creator {
                    name
                    modifier
                }
                mediumRaw
                creationYearFrom
                creationEraFrom
                creationYearTo
                creationEraTo
                edition
                dimensions {
                    ...Dimensions
                }
                classification {
                    id
                }
            }
        }
    }
    ${CustomDateFragmentDoc}
    ${DimensionsFragmentDoc}
`
export const PdbSubscriptionFragmentDoc = gql`
    fragment PdbSubscription on PdbSubscription {
        id
        title
        type
        expiry
        isValid
        invalidityReasons
        quota {
            quotaType
            numSearchesRemaining
        }
        warnings
    }
`
export const LenderFormMutationDocument = gql`
    mutation LenderFormMutation($input: InquireAboutFinancingRequest_Input!) {
        inquireAboutFinancing(input: $input) {
            message
            errors {
                message
                code
            }
        }
    }
`
export const LenderFormDocument = gql`
    query LenderForm {
        getCountriesWithFinancing {
            results {
                id
                name
            }
        }
    }
`
export const AddItemsToListDocument = gql`
    mutation AddItemsToList($listIds: [ID!]!, $itemIds: [ID!]) {
        addArtListingCuratedListItems(
            input: { listIds: $listIds, itemIds: $itemIds }
        ) {
            results {
                numAdded
            }
        }
    }
`
export const CreateListDocument = gql`
    mutation CreateList($title: String!, $itemIds: [ID!]!) {
        createArtListingCuratedList(
            input: { title: $title, itemIds: $itemIds }
        ) {
            result {
                id
            }
        }
    }
`
export const RemoveItemsFromListDocument = gql`
    mutation RemoveItemsFromList($listId: ID!, $itemIds: [ID!]) {
        removeArtListingCuratedListItems(
            input: { listId: $listId, itemIds: $itemIds }
        ) {
            results {
                numRemoved
            }
        }
    }
`
export const UpdateListDocument = gql`
    mutation UpdateList($id: ID!, $title: String, $featuredItemId: ID) {
        updateArtListingCuratedList(
            input: { id: $id, title: $title, featuredItemId: $featuredItemId }
        ) {
            result {
                id
                dateModified
                status
            }
        }
    }
`
export const UpdateListStatusDocument = gql`
    mutation UpdateListStatus(
        $listIds: [ID!]
        $status: ArtListingCuratedListStatus!
    ) {
        updateArtListingCuratedListStatus(
            input: { listIds: $listIds, status: $status }
        ) {
            results {
                id
                title
                count
                status
            }
        }
    }
`
export const ArtListingDetailsDocument = gql`
    query ArtListingDetails($id: ID!) {
        getArtListingById(input: { id: $id }) {
            id
            transactionStatusClarification
            auctionHouse {
                id
                name
                homepageUrl
                location {
                    city
                    state
                    country
                }
            }
            saleName
            saleDescription
            saleDates {
                ...CustomDate
            }
            featuredImage {
                baseImageUrl
                alt
            }
            images {
                baseImageUrl
                alt
            }
            prices {
                currencyCode {
                    value
                }
                estimatedPriceLow
                estimatedPriceHigh
                finalPrices {
                    priceType
                    price
                    differenceFromClosestEstimate
                }
            }
            lot {
                artnetLotId
                catalogLotNumber
                title
                description
                externalUrl
                artworks {
                    classification {
                        uri
                    }
                    creator {
                        creatorType
                        uri
                        modifier
                        name
                        artists {
                            firstName
                            lastName
                            nationalities
                            birthLocation {
                                country
                            }
                            birthYear
                            birthYearEra
                            birthYearQualifier
                            deathYear
                            deathYearEra
                            deathYearQualifier
                            gender
                            uri
                        }
                    }
                    mediumRaw
                    dimensions {
                        ...Dimensions
                    }
                    creationYearQualifier
                    creationYearFrom
                    creationEraFrom
                    creationYearTo
                    creationEraTo
                    edition
                    provenance
                    literature
                    exhibition
                    catalogueNote
                    markings {
                        isInscribed
                        isSigned
                        isStamped
                        isPlateSigned
                    }
                    objectType {
                        name
                    }
                    category {
                        name
                    }
                    movement {
                        name
                    }
                    materials {
                        name
                    }
                    period {
                        name
                    }
                    region {
                        name
                    }
                    subjectMatter {
                        name
                    }
                    publisher
                    raisonneAuthor
                    raisonneNumber
                    castYear {
                        modifier
                        yearFrom
                        eraFrom
                        yearTo
                        eraTo
                    }
                }
            }
        }
    }
    ${CustomDateFragmentDoc}
    ${DimensionsFragmentDoc}
`
export const ArtListingSuggestionsDocument = gql`
    query ArtListingSuggestions(
        $filters: ArtListingFilters_Input
        $freeformTextQuery: String!
        $limit: Int = 50
    ) {
        getArtListingSuggestions(
            input: {
                filters: $filters
                freeformTextQuery: $freeformTextQuery
                limit: $limit
            }
        ) {
            count
            results {
                id
                entityType
                subEntityType
                displayName
                description
                count
            }
        }
    }
`
export const ArtistPriceIndexDocument = gql`
    query ArtistPriceIndex(
        $creators: [ArtPriceIndexRequest_Input!]
        $autoRange: ArtPriceIndexAutoRange_Input
    ) {
        getArtPriceIndices(
            input: { autoRange: $autoRange, request: $creators }
        ) {
            result {
                baseline
                primaryIndex {
                    results {
                        date
                        distanceFromBaseline
                        value
                    }
                    name
                }
                remainingIndices {
                    name
                    results {
                        date
                        distanceFromBaseline
                        value
                    }
                }
            }
            resultTimeframe {
                start
                end
            }
        }
    }
`
export const CurrentUserBasicInfoDocument = gql`
    query CurrentUserBasicInfo {
        getCurrentUser {
            isLoggedIn
            isReauthenticationRequired
            user {
                id
                displayName
                email
            }
        }
    }
`
export const NewsArticlesDocument = gql`
    query NewsArticles(
        $filters: EntityFilter_Input!
        $pageSize: Int!
        $page: Int!
    ) {
        getNewsArticles(
            input: {
                filters: { creator: $filters }
                page: $page
                pageSize: $pageSize
            }
        ) {
            results {
                id
                author
                authorUrl
                categoryName
                categoryUrl
                date
                featuredImage {
                    aspectRatio
                    url
                }
                title
                url
            }
        }
    }
`
export const OldResultCountQueryDocument = gql`
    query OldResultCountQuery(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { classification: { limit: $limit } }
            }
        ) {
            classification {
                count
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const OldUserPermissionsDocument = gql`
    query OldUserPermissions {
        getUserPermissions(input: { scope: Pdb }) {
            productPermissions {
                pdb {
                    fineArt
                    decArt
                }
            }
        }
    }
`
export const ResultCountAndRemainingSearchesDocument = gql`
    query ResultCountAndRemainingSearches(
        $subscriptionId: ID!
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { classification: { limit: $limit } }
            }
        ) {
            classification {
                count
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
        getSubscriptionById(input: { id: $subscriptionId, scope: LEGACY_PDB }) {
            result {
                pdb {
                    ...PdbSubscription
                }
            }
        }
    }
    ${PdbSubscriptionFragmentDoc}
`
export const SearchArtListingsDocument = gql`
    query SearchArtListings(
        $subscriptionId: ID!
        $subscriptionScope: SubscriptionScope! = LEGACY_PDB
        $filters: ArtListingFilters_Input
        $freeText: String
        $page: Int!
        $pageSize: Int!
        $sort: [SortByClause_Input!]
    ) {
        getArtListings(
            input: {
                subscriptionId: $subscriptionId
                subscriptionScope: $subscriptionScope
                filters: $filters
                freeformTextQuery: $freeText
                page: $page
                pageSize: $pageSize
                sortBy: $sort
            }
        ) {
            count
            results {
                ...ArtListingSummary
            }
        }
    }
    ${ArtListingSummaryFragmentDoc}
`
export const SearchInsightsDocument = gql`
    query SearchInsights(
        $calendarInterval: InsightsInterval!
        $insightTypes: [SearchInsightsType!]!
        $filters: ArtListingFilters_Input
        $freeFormTextQuery: String
    ) {
        getSearchInsights(
            input: {
                calendarInterval: $calendarInterval
                insightTypes: $insightTypes
                autoRange: { years: 5 }
                configuration: { priceDistributionBucketCount: 20 }
                filters: $filters
                freeformTextQuery: $freeFormTextQuery
            }
        ) {
            isApplicable
            offeredVsSold {
                results {
                    lotsOffered
                    lotsSold
                    startDate
                }
            }
            statistics {
                sellThroughRate
                sellThroughRateLastYear
                aboveHighEstimateRate
                aboveHighEstimateRateLastYear
                belowLowEstimateRate
                belowLowEstimateRateLastYear
                totalUpcomingListings
                recentlyUpdated
                totalListingsCount
            }
            priceDistribution {
                results {
                    lowerBound
                    lowerBoundLog
                    upperBound
                    upperBoundLog
                    count
                    countLog
                }
            }
            totalSales {
                results {
                    startDate
                    valueUsd
                    quantitySold
                }
            }
            resultTimeframe {
                start
                end
            }
        }
    }
`
export const SearchSiteDocument = gql`
    query SearchSite(
        $searchTerm: String!
        $pageSize: Int! = 10
        $searchType: SearchType! = GLOBAL
    ) {
        search(
            searchTerm: $searchTerm
            pageSize: $pageSize
            searchType: $searchType
        ) {
            results {
                description
                id
                name
                url
                ... on NewsSearchHint {
                    author
                    date
                }
            }
        }
    }
`
export const Aggregation_AuctionHouseDocument = gql`
    query aggregation_AuctionHouse(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { auctionHouse: { limit: $limit } }
            }
        ) {
            auctionHouse {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_BirthYearDocument = gql`
    query aggregation_BirthYear(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $numberOfBuckets: Int = 84
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: {
                    birthYear: { numberOfBuckets: $numberOfBuckets }
                }
            }
        ) {
            birthYear {
                countUnknown
                results {
                    count
                    lowerBound
                    upperBound
                }
            }
        }
    }
`
export const Aggregation_CategoryDocument = gql`
    query aggregation_Category(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { category: { limit: $limit } }
            }
        ) {
            category {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_CreationYearDocument = gql`
    query aggregation_CreationYear(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $numberOfBuckets: Int = 84
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: {
                    creationYear: { numberOfBuckets: $numberOfBuckets }
                }
            }
        ) {
            creationYear {
                countUnknown
                results {
                    count
                    lowerBound
                    upperBound
                }
            }
        }
    }
`
export const Aggregation_CreatorDocument = gql`
    query aggregation_Creator(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { creator: { limit: $limit } }
            }
        ) {
            creator {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_CreatorNationalityDocument = gql`
    query aggregation_CreatorNationality(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { creatorNationality: { limit: $limit } }
            }
        ) {
            creatorNationality {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_CurrencyDocument = gql`
    query aggregation_Currency(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { currency: { limit: $limit } }
            }
        ) {
            currency {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_DeathYearDocument = gql`
    query aggregation_DeathYear(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $numberOfBuckets: Int = 84
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: {
                    deathYear: { numberOfBuckets: $numberOfBuckets }
                }
            }
        ) {
            deathYear {
                countUnknown
                results {
                    count
                    lowerBound
                    upperBound
                }
            }
        }
    }
`
export const Aggregation_DimensionDocument = gql`
    query aggregation_Dimension(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $aggregations: [DimensionBucketAggregation_Input!]!
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { dimension: $aggregations }
            }
        ) {
            dimensions {
                countUnknown
                results {
                    count
                    countUnknown
                    bucketName
                    longestEdge {
                        countUnknown
                        range {
                            lowerBound
                            upperBound
                        }
                    }
                    height {
                        countUnknown
                        range {
                            lowerBound
                            upperBound
                        }
                    }
                    width {
                        countUnknown
                        range {
                            lowerBound
                            upperBound
                        }
                    }
                    depth {
                        countUnknown
                        range {
                            lowerBound
                            upperBound
                        }
                    }
                }
            }
        }
    }
`
export const Aggregation_LotTitleDocument = gql`
    query aggregation_LotTitle(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { lotTitle: { limit: $limit } }
            }
        ) {
            lotTitle {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_MaterialDocument = gql`
    query aggregation_Material(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { material: { limit: $limit } }
            }
        ) {
            material {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_MediumDocument = gql`
    query aggregation_Medium(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { mediumRaw: { limit: $limit } }
            }
        ) {
            mediumRaw {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_MovementDocument = gql`
    query aggregation_Movement(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { movement: { limit: $limit } }
            }
        ) {
            movement {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_ObjectTypeDocument = gql`
    query aggregation_ObjectType(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { objectType: { limit: $limit } }
            }
        ) {
            objectType {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_PeriodDocument = gql`
    query aggregation_Period(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { period: { limit: $limit } }
            }
        ) {
            period {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_PriceDocument = gql`
    query aggregation_Price(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $numberOfBuckets: Int = 84
        $includeSold: Boolean = true
        $includeEstimates: Boolean = false
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: {
                    price: {
                        numberOfBuckets: $numberOfBuckets
                        includeSold: $includeSold
                        includeEstimates: $includeEstimates
                    }
                }
            }
        ) {
            price {
                countUnknown
                results {
                    count
                    lowerBound
                    upperBound
                }
            }
        }
    }
`
export const Aggregation_RegionDocument = gql`
    query aggregation_Region(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { region: { limit: $limit } }
            }
        ) {
            region {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_SaleDocument = gql`
    query aggregation_Sale(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { sale: { limit: $limit } }
            }
        ) {
            sale {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_SaleDateDocument = gql`
    query aggregation_SaleDate(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $numberOfBuckets: Int = 84
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: {
                    saleDate: { numberOfBuckets: $numberOfBuckets }
                }
            }
        ) {
            saleDate {
                scale
                scaleFactor
                results {
                    lowerBound {
                        year
                        month
                        day
                    }
                    upperBound {
                        year
                        month
                        day
                    }
                    count
                }
                countUnknown
            }
        }
    }
`
export const Aggregation_SimplifiedTransactionStatusDocument = gql`
    query aggregation_SimplifiedTransactionStatus(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: {
                    simplifiedTransactionStatus: { limit: $limit }
                }
            }
        ) {
            simplifiedTransactionStatus {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const Aggregation_SubjectMatterDocument = gql`
    query aggregation_SubjectMatter(
        $filters: ArtListingFilters_Input!
        $freeText: String
        $limit: Int = 100
    ) {
        getArtListingAggregations(
            input: {
                filters: $filters
                freeformTextQuery: $freeText
                aggregationCriteria: { subjectMatter: { limit: $limit } }
            }
        ) {
            subjectMatter {
                countUnknown
                results {
                    id
                    displayName
                    description
                    count
                }
            }
        }
    }
`
export const GetListByIdDocument = gql`
    query GetListById(
        $id: ID!
        $freeformTextQuery: String
        $page: Int!
        $pageSize: Int!
        $sortBy: [ArtListingCuratedListItemSortByClause_Input!]
    ) {
        getArtListingCuratedListById(
            input: {
                id: $id
                freeformTextQuery: $freeformTextQuery
                page: $page
                pageSize: $pageSize
                sortBy: $sortBy
            }
        ) {
            result {
                id
                title
                origin
                status
                count
                featuredImage {
                    baseImageUrl
                    alt
                }
                items {
                    id
                    title
                    position
                    artListing {
                        ...ArtListingSummary
                    }
                }
                dateCreated
                dateModified
            }
        }
    }
    ${ArtListingSummaryFragmentDoc}
`
export const GetListsDocument = gql`
    query GetLists(
        $freeformTextQuery: String
        $page: Int!
        $pageSize: Int!
        $sortBy: [ArtListingCuratedListSortByClause_Input!]
        $origins: [ArtListingCuratedListOrigin!]
    ) {
        getArtListingCuratedLists(
            input: {
                freeformTextQuery: $freeformTextQuery
                page: $page
                pageSize: $pageSize
                sortBy: $sortBy
                origins: $origins
            }
        ) {
            results {
                id
                title
                featuredImage {
                    baseImageUrl
                    alt
                }
                dateCreated
                dateModified
                count
            }
        }
    }
`
export const PdbSubscriptionByIdDocument = gql`
    query PdbSubscriptionById($id: ID!) {
        getSubscriptionById(input: { id: $id, scope: LEGACY_PDB }) {
            result {
                pdb {
                    ...PdbSubscription
                }
            }
        }
    }
    ${PdbSubscriptionFragmentDoc}
`
export const UserPdbSubscriptionsDocument = gql`
    query UserPdbSubscriptions {
        getUserSubscriptions(input: { scopes: [LEGACY_PDB] }) {
            results {
                overview {
                    hasLegacyPdb
                }
                pdb {
                    ...PdbSubscription
                }
            }
        }
    }
    ${PdbSubscriptionFragmentDoc}
`

export type SdkFunctionWrapper = <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
    action,
    _operationName,
    _operationType
) => action()

export function getSdk(
    client: GraphQLClient,
    withWrapper: SdkFunctionWrapper = defaultWrapper
) {
    return {
        LenderFormMutation(
            variables: LenderFormMutationMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<LenderFormMutationMutationResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<LenderFormMutationMutationResponse>(
                        LenderFormMutationDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'LenderFormMutation',
                'mutation'
            )
        },
        LenderForm(
            variables?: LenderFormQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<LenderFormQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<LenderFormQueryResponse>(
                        LenderFormDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'LenderForm',
                'query'
            )
        },
        AddItemsToList(
            variables: AddItemsToListMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<AddItemsToListMutationResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<AddItemsToListMutationResponse>(
                        AddItemsToListDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'AddItemsToList',
                'mutation'
            )
        },
        CreateList(
            variables: CreateListMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateListMutationResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateListMutationResponse>(
                        CreateListDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'CreateList',
                'mutation'
            )
        },
        RemoveItemsFromList(
            variables: RemoveItemsFromListMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RemoveItemsFromListMutationResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RemoveItemsFromListMutationResponse>(
                        RemoveItemsFromListDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'RemoveItemsFromList',
                'mutation'
            )
        },
        UpdateList(
            variables: UpdateListMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateListMutationResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateListMutationResponse>(
                        UpdateListDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'UpdateList',
                'mutation'
            )
        },
        UpdateListStatus(
            variables: UpdateListStatusMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateListStatusMutationResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateListStatusMutationResponse>(
                        UpdateListStatusDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'UpdateListStatus',
                'mutation'
            )
        },
        ArtListingDetails(
            variables: ArtListingDetailsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ArtListingDetailsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ArtListingDetailsQueryResponse>(
                        ArtListingDetailsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'ArtListingDetails',
                'query'
            )
        },
        ArtListingSuggestions(
            variables: ArtListingSuggestionsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ArtListingSuggestionsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ArtListingSuggestionsQueryResponse>(
                        ArtListingSuggestionsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'ArtListingSuggestions',
                'query'
            )
        },
        ArtistPriceIndex(
            variables?: ArtistPriceIndexQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ArtistPriceIndexQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ArtistPriceIndexQueryResponse>(
                        ArtistPriceIndexDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'ArtistPriceIndex',
                'query'
            )
        },
        CurrentUserBasicInfo(
            variables?: CurrentUserBasicInfoQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CurrentUserBasicInfoQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CurrentUserBasicInfoQueryResponse>(
                        CurrentUserBasicInfoDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'CurrentUserBasicInfo',
                'query'
            )
        },
        NewsArticles(
            variables: NewsArticlesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<NewsArticlesQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<NewsArticlesQueryResponse>(
                        NewsArticlesDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'NewsArticles',
                'query'
            )
        },
        OldResultCountQuery(
            variables: OldResultCountQueryQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<OldResultCountQueryQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<OldResultCountQueryQueryResponse>(
                        OldResultCountQueryDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'OldResultCountQuery',
                'query'
            )
        },
        OldUserPermissions(
            variables?: OldUserPermissionsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<OldUserPermissionsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<OldUserPermissionsQueryResponse>(
                        OldUserPermissionsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'OldUserPermissions',
                'query'
            )
        },
        ResultCountAndRemainingSearches(
            variables: ResultCountAndRemainingSearchesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ResultCountAndRemainingSearchesQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ResultCountAndRemainingSearchesQueryResponse>(
                        ResultCountAndRemainingSearchesDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'ResultCountAndRemainingSearches',
                'query'
            )
        },
        SearchArtListings(
            variables: SearchArtListingsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SearchArtListingsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SearchArtListingsQueryResponse>(
                        SearchArtListingsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'SearchArtListings',
                'query'
            )
        },
        SearchInsights(
            variables: SearchInsightsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SearchInsightsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SearchInsightsQueryResponse>(
                        SearchInsightsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'SearchInsights',
                'query'
            )
        },
        SearchSite(
            variables: SearchSiteQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SearchSiteQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SearchSiteQueryResponse>(
                        SearchSiteDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'SearchSite',
                'query'
            )
        },
        aggregation_AuctionHouse(
            variables: Aggregation_AuctionHouseQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_AuctionHouseQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_AuctionHouseQueryResponse>(
                        Aggregation_AuctionHouseDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_AuctionHouse',
                'query'
            )
        },
        aggregation_BirthYear(
            variables: Aggregation_BirthYearQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_BirthYearQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_BirthYearQueryResponse>(
                        Aggregation_BirthYearDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_BirthYear',
                'query'
            )
        },
        aggregation_Category(
            variables: Aggregation_CategoryQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_CategoryQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_CategoryQueryResponse>(
                        Aggregation_CategoryDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Category',
                'query'
            )
        },
        aggregation_CreationYear(
            variables: Aggregation_CreationYearQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_CreationYearQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_CreationYearQueryResponse>(
                        Aggregation_CreationYearDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_CreationYear',
                'query'
            )
        },
        aggregation_Creator(
            variables: Aggregation_CreatorQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_CreatorQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_CreatorQueryResponse>(
                        Aggregation_CreatorDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Creator',
                'query'
            )
        },
        aggregation_CreatorNationality(
            variables: Aggregation_CreatorNationalityQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_CreatorNationalityQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_CreatorNationalityQueryResponse>(
                        Aggregation_CreatorNationalityDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_CreatorNationality',
                'query'
            )
        },
        aggregation_Currency(
            variables: Aggregation_CurrencyQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_CurrencyQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_CurrencyQueryResponse>(
                        Aggregation_CurrencyDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Currency',
                'query'
            )
        },
        aggregation_DeathYear(
            variables: Aggregation_DeathYearQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_DeathYearQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_DeathYearQueryResponse>(
                        Aggregation_DeathYearDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_DeathYear',
                'query'
            )
        },
        aggregation_Dimension(
            variables: Aggregation_DimensionQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_DimensionQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_DimensionQueryResponse>(
                        Aggregation_DimensionDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Dimension',
                'query'
            )
        },
        aggregation_LotTitle(
            variables: Aggregation_LotTitleQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_LotTitleQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_LotTitleQueryResponse>(
                        Aggregation_LotTitleDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_LotTitle',
                'query'
            )
        },
        aggregation_Material(
            variables: Aggregation_MaterialQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_MaterialQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_MaterialQueryResponse>(
                        Aggregation_MaterialDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Material',
                'query'
            )
        },
        aggregation_Medium(
            variables: Aggregation_MediumQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_MediumQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_MediumQueryResponse>(
                        Aggregation_MediumDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Medium',
                'query'
            )
        },
        aggregation_Movement(
            variables: Aggregation_MovementQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_MovementQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_MovementQueryResponse>(
                        Aggregation_MovementDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Movement',
                'query'
            )
        },
        aggregation_ObjectType(
            variables: Aggregation_ObjectTypeQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_ObjectTypeQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_ObjectTypeQueryResponse>(
                        Aggregation_ObjectTypeDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_ObjectType',
                'query'
            )
        },
        aggregation_Period(
            variables: Aggregation_PeriodQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_PeriodQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_PeriodQueryResponse>(
                        Aggregation_PeriodDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Period',
                'query'
            )
        },
        aggregation_Price(
            variables: Aggregation_PriceQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_PriceQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_PriceQueryResponse>(
                        Aggregation_PriceDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Price',
                'query'
            )
        },
        aggregation_Region(
            variables: Aggregation_RegionQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_RegionQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_RegionQueryResponse>(
                        Aggregation_RegionDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Region',
                'query'
            )
        },
        aggregation_Sale(
            variables: Aggregation_SaleQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_SaleQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_SaleQueryResponse>(
                        Aggregation_SaleDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_Sale',
                'query'
            )
        },
        aggregation_SaleDate(
            variables: Aggregation_SaleDateQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_SaleDateQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_SaleDateQueryResponse>(
                        Aggregation_SaleDateDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_SaleDate',
                'query'
            )
        },
        aggregation_SimplifiedTransactionStatus(
            variables: Aggregation_SimplifiedTransactionStatusQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_SimplifiedTransactionStatusQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_SimplifiedTransactionStatusQueryResponse>(
                        Aggregation_SimplifiedTransactionStatusDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_SimplifiedTransactionStatus',
                'query'
            )
        },
        aggregation_SubjectMatter(
            variables: Aggregation_SubjectMatterQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<Aggregation_SubjectMatterQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<Aggregation_SubjectMatterQueryResponse>(
                        Aggregation_SubjectMatterDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'aggregation_SubjectMatter',
                'query'
            )
        },
        GetListById(
            variables: GetListByIdQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetListByIdQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetListByIdQueryResponse>(
                        GetListByIdDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'GetListById',
                'query'
            )
        },
        GetLists(
            variables: GetListsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetListsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetListsQueryResponse>(
                        GetListsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'GetLists',
                'query'
            )
        },
        PdbSubscriptionById(
            variables: PdbSubscriptionByIdQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<PdbSubscriptionByIdQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<PdbSubscriptionByIdQueryResponse>(
                        PdbSubscriptionByIdDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'PdbSubscriptionById',
                'query'
            )
        },
        UserPdbSubscriptions(
            variables?: UserPdbSubscriptionsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UserPdbSubscriptionsQueryResponse> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UserPdbSubscriptionsQueryResponse>(
                        UserPdbSubscriptionsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'UserPdbSubscriptions',
                'query'
            )
        },
    }
}
export type Sdk = ReturnType<typeof getSdk>
