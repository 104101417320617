import { CurrentUserBasicInfoQueryResponse } from '~/services/graphql/generated'
import { useRemixLoader } from '~/syndicated-components/hooks/remix-loader'

const ROUTE_PATH = '/api/frontend/get-current-user'

export type CurrentUserResult =
    CurrentUserBasicInfoQueryResponse['getCurrentUser']

export type CurrentUserLoaderData = {
    data?: CurrentUserResult
    error?: string
}

export function useGetCurrentUserLoader({
    readyToFetch = true,
}: { readyToFetch?: boolean } = {}) {
    return useRemixLoader<CurrentUserLoaderData>(ROUTE_PATH, {
        readyToFetch,
        clientOnly: true,
        fetchOptions: {
            credentials: 'include',
        },
    })
}
