import type { CustomDate } from '~/services/graphql/generated'
import { utcToZonedTime } from 'date-fns-tz'

export function convertToDateObjIfPossible(
    dateFromGraphql: CustomDate,
    useLocalTimeZone: boolean = false
): Date | CustomDate {
    if (dateFromGraphql.day && dateFromGraphql.month && dateFromGraphql.year) {
        if (useLocalTimeZone && dateFromGraphql.timeZone) {
            // month is zero-indexed in JS date constructor
            const date = new Date(
                Date.UTC(
                    dateFromGraphql.year,
                    dateFromGraphql.month - 1,
                    dateFromGraphql.day,
                    0,
                    0,
                    0,
                    0
                )
            )
            return utcToZonedTime(date, dateFromGraphql.timeZone)
        }

        return new Date(
            Date.UTC(
                dateFromGraphql.year,
                dateFromGraphql.month - 1,
                dateFromGraphql.day,
                0,
                0,
                0,
                0
            )
        )
    }

    return dateFromGraphql as CustomDate
}
