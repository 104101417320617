import React from 'react'
import { useFetcher } from '@remix-run/react'

type MaybeFilters = Record<string, any> | null

// for use with the AutoSuggest component
export function useSearchHints<THint = any>(): [
    hints: THint[],
    fetchHints: (freeformTextQuery: string, filters?: MaybeFilters) => void
] {
    const fetcher = useFetcher<{
        count: number
        results: THint[]
    }>()
    // we're using a `hints` state variable as an optimization to avoid calling
    // fetcher.submit() in the case where `freeformTextQuery` is empty
    const [hints, setHints] = React.useState<THint[]>([])

    React.useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data != null) {
            setHints(fetcher.data.results as THint[])
        }
    }, [fetcher])

    // TODO
    // check if this would be more efficient if wrapped in useCallback()
    // (it might not be, so leaving it as-is for now)
    function fetchHints(
        freeformTextQuery: string,
        filters: MaybeFilters = null
    ) {
        if (!freeformTextQuery) {
            setHints([])
        } else {
            fetcher.submit({
                freeformTextQuery,
                filters: JSON.stringify(filters),
            })
        }
    }

    return [hints, fetchHints]
}
