/* eslint-disable jsx-a11y/anchor-has-content */
import { ChevronLeftIcon, Modal } from '@artnetworldwide/ui-library'
import { NavLinkType } from '~/components/headers/global/types'
import { lit } from '@artnetworldwide/ui-library/utils'
import { NavLink } from '~/components/NavLink'
import clientConfig from '~/config/config.client'
import React from 'react'

interface NavbarMobileSubmenuProps {
    links: Record<string, NavLinkType>
    menuTitle: string
    menu: string
    isOpen: boolean
    resetSubmenuStatus: () => void
    setOpen: (openStatus: boolean) => void
    theme: string
}

export function NavbarMobileSubmenu(props: NavbarMobileSubmenuProps) {
    const { links, menu, resetSubmenuStatus, setOpen, isOpen, theme } = props
    const nestedLinks = links[menu].links ?? []

    function renderItemWithSubItems(item: NavLinkType) {
        return (
            <React.Fragment key={item.id}>
                <li
                    className={lit`flex items-center justify-between
                    ${item?.uiSpec?.mobile}`}
                    tabIndex={-1}
                >
                    {item.label}
                </li>
                {item.links &&
                    Object.values(item.links).map((subitem) => (
                        <li
                            key={subitem.id}
                            className={lit`flex items-center justify-between pl-4
                ${subitem?.uiSpec?.mobile}`}
                            tabIndex={0}
                        >
                            <NavLink
                                link={subitem}
                                className="focus:text-neutral-400 dark:text-white"
                            />
                        </li>
                    ))}
            </React.Fragment>
        )
    }
    return (
        <Modal
            show={isOpen}
            wrapperClassName={`${clientConfig.SYNDICATED_COMPONENT_CONTAINER_CLASS}`}
            leftToRight={true}
            onClose={() => {
                resetSubmenuStatus()
            }}
            desktopModeBreakpoint="lg"
            className={lit`w-screen px-0 pt-4 dark:bg-black`}
            innerWrapperClassName={`${theme}`}
        >
            <Modal.Header className="ml-3 pb-3 pt-0" showCloseButton={false}>
                <button
                    type="button"
                    className="flex items-center rounded-md bg-white py-2 pr-1 text-xs text-neutral-400 underline dark:bg-black dark:text-white"
                    onClick={() => {
                        setOpen(true)
                        resetSubmenuStatus()
                    }}
                >
                    <ChevronLeftIcon size="small" className="pr-1" />
                    Back to categories
                </button>
            </Modal.Header>
            <Modal.Body className="pb-3">
                <div className="relative flex-1 px-4 sm:px-6">
                    <ul className="text-base font-normal leading-[2rem]">
                        {Object.values(nestedLinks).map((item) =>
                            item.url ? (
                                <li
                                    key={item.id}
                                    className={lit`flex items-center justify-between
                                                ${item?.uiSpec?.mobile}`}
                                >
                                    {
                                        <NavLink
                                            link={item}
                                            className="focus:text-neutral-400 dark:text-white"
                                        />
                                    }
                                </li>
                            ) : item.links && item.links.length > 0 ? (
                                renderItemWithSubItems(item)
                            ) : (
                                <li
                                    key={item.id}
                                    className={lit`flex items-center justify-between
                                                ${item?.uiSpec?.mobile}`}
                                    tabIndex={-1}
                                >
                                    {item.label}
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    )
}
