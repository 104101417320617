import type {
    CurrentFilters,
    ExecutableFilter,
    FilterDimensionRangeDisplayValue,
    FilterEntityDisplayValue,
    FilterLiteralDisplayValue,
    FilterRangeDisplayValue,
    FilterSearchTermsDisplayValue,
    NumericRange,
} from '~/types/filters'
import { getFilterType } from '~/config/constants/core-data'
import invariant from 'tiny-invariant'
import {
    DimensionFilter_Input,
    EntityFilter_Input,
    PriceFilter_Input,
    YearFilter_Input,
} from '~/services/graphql/generated'
import { DateFilter_Input } from '~/syndicated-components/graphql'
import { fromUnixTime } from 'date-fns'

/**
 * Takes filter UI state and returns a filters object that can be used as
 * input to GraphQL
 */
export function getExecutableFilters(filters: CurrentFilters) {
    const executableFilters: {
        [filterName: string]: ExecutableFilter
    } = {}

    for (const [filterName, filter] of Object.entries(filters)) {
        const filterType = getFilterType(filterName)

        switch (filterType) {
            case 'Entity':
                const filterVal: EntityFilter_Input = {
                    keys: [],
                    searchTerms: [],
                    includeUnknown: filter.attributes?.includeUnknown,
                }

                for (const value of filter.values) {
                    if (value.hasOwnProperty('id')) {
                        filterVal.keys!.push(
                            (value as FilterEntityDisplayValue).id
                        )
                    } else if (value.hasOwnProperty('searchTerms')) {
                        filterVal.searchTerms!.push(
                            (value as FilterSearchTermsDisplayValue).searchTerms
                        )
                    }
                }

                if (filterName === 'creator') {
                    executableFilters.includeArtistModifiers = {
                        values: [
                            filter.attributes?.includeArtistModifiers ?? false,
                        ],
                    }
                }

                executableFilters[filterName] = filterVal
                break
            case 'PriceRange': {
                const val = filter.values[0] as FilterRangeDisplayValue
                const executableVal: PriceFilter_Input = {
                    range: {
                        lowerBound: val.lowerBound
                            ? {
                                  value: val.lowerBound,
                              }
                            : null,
                        upperBound: val.upperBound
                            ? {
                                  value: val.upperBound,
                              }
                            : null,
                    },
                    ...(filter.attributes ?? {}),
                } as PriceFilter_Input
                executableFilters[filterName] = executableVal
                break
            }
            case 'YearRange':
                const yearVal = filter.values[0] as FilterRangeDisplayValue
                // includeBce is currently only being used on the frontend, but it may be added to the API in the future
                const { includeBce, ...attributes } = filter.attributes ?? {}
                if (yearVal) {
                    const executableYearVal: YearFilter_Input = {
                        lowerBound:
                            yearVal.lowerBound !== undefined
                                ? {
                                      value: yearVal.lowerBound,
                                  }
                                : null,
                        upperBound:
                            yearVal.upperBound !== undefined
                                ? {
                                      value: yearVal.upperBound,
                                  }
                                : null,
                        ...attributes,
                    }
                    executableFilters[filterName] = executableYearVal
                }
                break

            case 'DimensionRange':
                // console.log('DIMENSIONS_FILTER_INPUT', filter.values)
                const dimensions: DimensionFilter_Input[] = (
                    filter.values as FilterDimensionRangeDisplayValue[]
                ).map((v) => {
                    const executableDimensionsVal: DimensionFilter_Input = {
                        unit: v.unit,
                    }
                    const dimensionFields = [
                        'longestEdge',
                        'height',
                        'width',
                        'depth',
                    ] as const
                    for (const fieldName of dimensionFields) {
                        const range = v[
                            fieldName as keyof FilterDimensionRangeDisplayValue
                        ] as NumericRange
                        if (
                            !range ||
                            (range.lowerBound === undefined &&
                                range.upperBound === undefined)
                        ) {
                            continue
                        }
                        const { lowerBound, upperBound, includeUnknown } = range

                        executableDimensionsVal[
                            fieldName as (typeof dimensionFields)[number]
                        ] = {
                            lowerBound:
                                lowerBound !== undefined
                                    ? {
                                          value: lowerBound,
                                      }
                                    : null,
                            upperBound:
                                upperBound !== undefined
                                    ? {
                                          value: upperBound,
                                      }
                                    : null,
                            includeUnknown,
                        }
                    }
                    return executableDimensionsVal
                })

                executableFilters[filterName] = dimensions
                break

            case 'FreeText':
                executableFilters[filterName] = (
                    filter.values[0] as FilterLiteralDisplayValue
                ).literalValue
                break
            case 'DateRange': {
                const dateVal = filter.values[0] as FilterRangeDisplayValue
                const { lowerBound, upperBound } = dateVal

                // NOTE: the API already supports passing only a lowerBound or only an upperBound,
                // but this code currently assumes that both are provided
                if (!lowerBound && !upperBound) {
                    throw Error(
                        'Partial ranges are not yet supported. Please supply both lower and upper bound'
                    )
                }

                // TODO remove `!` from lowerBound and upperBound if/when we support ranges with
                // only a lowerBound or only an upperBound
                const lowerBoundDate = fromUnixTime(lowerBound!)
                const upperBoundDate = fromUnixTime(upperBound!)

                const lowerBoundYear = lowerBoundDate.getUTCFullYear()
                const upperBoundYear = upperBoundDate.getUTCFullYear()

                const lowerBoundMonth = lowerBoundDate.getUTCMonth() + 1
                const upperBoundMonth = upperBoundDate.getUTCMonth() + 1

                const lowerBoundDay = lowerBoundDate.getUTCDate()
                const upperBoundDay = upperBoundDate.getUTCDate()

                const executableVal: DateFilter_Input = {
                    lowerBound: {
                        year: lowerBoundYear,
                        month: {
                            value: lowerBoundMonth,
                        },
                        day: {
                            value: lowerBoundDay,
                        },
                    },
                    upperBound: {
                        year: upperBoundYear,
                        month: {
                            value: upperBoundMonth,
                        },
                        day: {
                            value: upperBoundDay,
                        },
                    },
                    ...(filter.attributes ?? {}),
                }

                executableFilters[filterName] = executableVal
                break
            }
            default:
                invariant(false, 'Unrecognized filter type ' + filterType)
        }
    }

    return executableFilters
}
