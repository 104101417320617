import { config } from '~/syndicated-components/utils'
import {
    getAuthUrl,
    isLoggedInNonAuthoritative,
    isLoggedIntoSSONonAuthoritative,
} from '~/utils/auth-shared-syndicated'

export function checkLoginAndRedirectIfNeeded() {
    if (!isLoggedInNonAuthoritative() && isLoggedIntoSSONonAuthoritative()) {
        const currentUrl = location.href
        window.location.href = getAuthUrl(
            'login',
            config.API_GATEWAY_BASE_URL,
            currentUrl,
            currentUrl
        )
    }
}
