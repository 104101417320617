import serverConfig from './config.server'
import clientConfig from './config.client'

// NOTE:
// This file is intended to be used from components used in the remix app, and
// does not currently work correctly for server-side config for syndicated components
// (need to investigate if it's possible to make it work the same way for that).

const config =
    typeof document === 'undefined' || process.env.NODE_ENV === 'test'
        ? serverConfig
        : clientConfig

export default config
